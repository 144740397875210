import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DragItem from '../DragItem';
import ExplorerContextMenu from '../../ExplorerContextMenu';

import styles from './explorer-list-item.module.css';

const ExplorerListItem = ({
  item, updated, selected, hideOptions, onToggleMenu, onHoverSubmenuItem, parent,
  editing, actions, navigation, optionsState, onDrop, openedFolders
}) => {
  const { t } = useTranslation();
  const isFolder = item.type === 'folder';
  const [ isOpen, setIsOpen ] = useState((isFolder && openedFolders?.includes(item.translatedName)) || false);
  const [ isHover, setIsHover ] = useState(false);
  const [ isOptionsOpen, setOptionsOpen ] = useState(false);

  const handleClick = (e) => {
    if (isFolder)
      setIsOpen(prev => !prev);
    else if (navigation.onOpenTemplate)
      navigation.onOpenTemplate(item, parent && { id: parent.id, name: parent.name, translatedName: parent.translatedName });
  };

  let icon = null;
  let hoverIcon = null;

  if (isFolder) {
    if (!item.children?.length && !item.templates?.length) icon = '/icons/explorer/folder_empty_small.svg';
    else if (item.isCreated) icon = '/icons/explorer/folder_small.svg';
    else if (item.isAccessAdd) icon = '/icons/explorer/folder_locked_small.svg';
    else if (item.groups && item.groups.length) {
      icon = '/icons/explorer/folder_locked_small.svg';
      hoverIcon = '/icons/explorer/folder_locked_small.svg';
    }
    else icon = '/icons/explorer/folder_small.svg';
  } else {
    if (item.isCreated) icon = '/icons/explorer/file_i_small.svg';
    else icon = '/icons/explorer/file_i_small.svg';
  }

  return (
    <>
      <DragItem
        id={item.id}
        parentId={parent?.id}
        name={item.name}
        type={item.type}
        isUpdated={updated === item.id}
        onDrop={onDrop}
      >
        <div
          id={`file_explorer_template_${item.id}`}
          className={classNames(
            styles.root,
            selected?.includes(item.id) && styles.selected,
            optionsState?.moveToItemId === item.id && styles.moveHover,
            isOpen && styles.open,
            `file-explorer-${item.type}`
          )}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {!!(isFolder && (item.children?.length || item.templates?.length)) && (
            <div
              className={styles.arrowButton}
              onClick={handleClick}
              onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
            >
              <div className={styles.count}>{(item.children?.length ?? 0) + (item.templates?.length ?? 0)}</div>
              <ReactSVG src="/icons/arrow-right-thin.svg" className={styles.arrowIcon} wrapper="span" />
            </div>
          )}

          <div
            className={styles.content}
            onClick={handleClick}
            onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
          >
            <img
              className={styles.icon}
              src={(isHover && hoverIcon) ? hoverIcon : icon}
              alt=""
            />
            <div className={styles.name}>
              {item.name}
              {(isFolder && !item.children?.length && !item.templates?.length) && (
                <span className={styles.nameEmpty}>{t('empty_folder')}</span>
              )}
            </div>
            {!isFolder && item.approved && (
              <div className={styles.approved}>
                {t('vars.approved')}
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            {/* {item.favorite && (
              actions?.onFavouritesToggle ? (
                <div className={styles.fave} onClick={(e) => {
                  e.stopPropagation();
                  if (actions?.onFavouritesToggle) actions.onFavouritesToggle({ id: item.id, type: item.type, checked: false });
                }}>
                  <Tooltip control={<img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />}>
                    {t('remove_from_favourites')}
                  </Tooltip>
                </div>
              ) : (
                <div className={styles.fave}>
                  <img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />
                </div>
              )
            )} */}
            {(!hideOptions && editing) &&
              <div className={styles.optionsButton}>
                <ExplorerContextMenu
                  open={isOptionsOpen}
                  position={['bottom right', 'top right']}
                  item={item}
                  items={item.children ?? []}
                  parent={parent}
                  onToggleMenu={(id) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                  onHoverSubmenuItem={onHoverSubmenuItem}
                  editing={{ ...editing }}
                  actions={actions}
                />
              </div>
            }
          </div>
        </div>
      </DragItem>

      {(isOpen && (item.children || item.templates)) && (
        <div className={styles.children}>
          {item.children?.map(subitem => (
            <ExplorerListItem
              item={subitem}
              parent={{
                id: item.id,
                name: item.name,
                translatedName: item.translatedName,
                parentId: parent?.id
              }}
              updated={updated}
              selected={selected}
              onDrop={onDrop}
              hideOptions={hideOptions}
              onToggleMenu={onToggleMenu}
              onHoverSubmenuItem={onHoverSubmenuItem}
              editing={editing}
              actions={actions}
              navigation={navigation}
              optionsState={optionsState}
              openedFolders={openedFolders}
              key={subitem.id}
            />
          ))}
          {item.templates?.map(subitem => (
            <ExplorerListItem
              item={subitem}
              parent={{
                id: item.id,
                name: item.name,
                translatedName: item.translatedName,
                parentId: parent?.id
              }}
              updated={updated}
              selected={selected}
              onDrop={onDrop}
              hideOptions={hideOptions}
              onToggleMenu={onToggleMenu}
              onHoverSubmenuItem={onHoverSubmenuItem}
              editing={editing}
              actions={actions}
              navigation={navigation}
              optionsState={optionsState}
              openedFolders={openedFolders}
              key={subitem.id}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ExplorerListItem;
