import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import DragLayer from '../DragLayer';
import DropItem from '../DropItem';

import styles from './drag-item.module.css';

const DragItem = ({ id, parentId, type, name, isUpdated, onDrop, children }) => {
  const wrapperRef = useRef(null);
  const [ size, setSize ] = useState({ width: 0, height: 0 });

  const [{ isDragging }, dragRef, dragPreview] = useDrag(() => ({
    type: 'BOX',
    item: { id, parentId, type, name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }), []);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line
  }, [dragRef]);

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperSizes = wrapperRef.current.getBoundingClientRect();
      setSize({ width: wrapperSizes.width, height: wrapperSizes.height });
    }
  }, [wrapperRef]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.border}></div>
      <DropItem
        id={id}
        name={name}
        type={type}
        className={styles.container}
        onDrop={(type === 'folder' && onDrop) ? onDrop : undefined}
      >
        <>
          {isDragging &&
            <DragLayer
              width={size.width}
              height={size.height}
              className={classNames(styles.children, styles.hover)}
            >
              {children}
            </DragLayer>
          }

          <div
            className={classNames(
              styles.children,
              isUpdated && styles.updated
            )}
            ref={dragRef}
          >
            {!isDragging && children}
          </div>
        </>
      </DropItem>
    </div>
  );
};

export default DragItem;
