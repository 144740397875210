import classNames from 'classnames';
import DashboardBlock from 'components/Dashboard/DashboardBlock';
import { formatPrice } from 'helpers/formatValues';
import { currencies } from 'data/mock';

import styles from './payments-month.module.css';

const PaymentsMonth = ({ count, amount }) => (
  <DashboardBlock
    title="Месяц"
  >
    <div className={styles.root}>
      <div className={styles.value}>{count}</div>
      <div className={styles.text}>
        оплат на <span className={classNames(styles.price)}>{formatPrice(amount, currencies.ru)}</span>  {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
      </div>
    </div>
  </DashboardBlock>
);

export default PaymentsMonth;
