import { Link } from 'react-router-dom';

import styles from './variable-templates.module.css';

const VariableTemplates = ({ items }) => {
  return (
    <div className={styles.root}>
      {items?.length ? items.map(({ id, name }, index) => (
        <div className={styles.item} key={id}>
          <span className={styles.number}>{index+1}.</span>
          <Link className={styles.link} to={`/editor/${id}`} target="_blank">
            {name}
          </Link>
        </div>
      )) : (
        <div>
          Переменная нигде не использована
        </div>
      )}
    </div>
  );
};

export default VariableTemplates;
