import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import FileDropzone from 'components/fields/FileDropzone';
import { Modal, Button, RemoveModal } from '@forma/forma-ui-kit';

import styles from './template-attachments.module.css';

const TemplateAttachments = ({ items, onAdd, onRemove }) => {
  const { t } = useTranslation();
  const [ files, setFiles ] = useState(null);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('attachments')}</div>
      <Swiper
        modules={[ Scrollbar ]}
        scrollbar={{ draggable: true }}
        spaceBetween={20}
        slidesPerView={2}
        breakpoints={{
          1025: { slidesPerView: 6 },
          801: { slidesPerView: 5 },
          576: { slidesPerView: 4 },
        }}
      >
        <SwiperSlide>
          <Modal
            title={t('upload_attachments')}
            control={
              <Button
                viewStyle="secondary"
                className={styles.add}
                iconClassName={styles.addIcon}
                icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                fullWidth
              />
            }
            buttons={[
              {
                children: t('upload'),
                disabled: !files?.length,
                onClick: () => {
                  onAdd(files);
                  setFiles(null);
                }
              }
            ]}
          >
            <input type="hidden" autoFocus />
            <FileDropzone
              className={styles.dropzone}
              maxFiles={10}
              onChange={setFiles}
            />
          </Modal>
        </SwiperSlide>
        {items?.map(({ id, templateid, url, name }) => (
          <SwiperSlide key={id}>
            <div className={styles.slide}>
              {name}
              <Button
                as="a"
                viewStyle="text"
                className={styles.download}
                iconClassName={styles.downloadIcon}
                icon={<ReactSVG src="/icons/download.svg" />}
                href={url}
                target="_blank"
              />
              <RemoveModal
                control={
                  <Button viewStyle="text" className={styles.remove} iconClassName={styles.removeIcon} icon={<ReactSVG src="/icons/trash.svg" />} />
                }
                title={t('delete_attachment')}
                onRemove={() => onRemove({ id: templateid, attachmentid: id })}
                itemName={name}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TemplateAttachments;