import { Trans } from 'react-i18next';
import { Tooltip } from '@forma/forma-ui-kit';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-dau.module.css';

const DashboardDAU = ({ value }) => (
  <DashboardBlock
    title="DAU"
    buttons={
      <Tooltip
        control={<img src="/icons/question-circle.svg" alt="" />}
      >
        <div style={{ width: '226px' }}>
          Количество пользователей, взаимодействующих с Формой (любое действие в рамках аккаунта) в течение 24-часового окна активности
        </div>
      </Tooltip>
    }
  >
    <div className={styles.root}>
      <div className={styles.value}>
        <Trans
          i18nKey="users_count"
          count={value}
        />
        {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardDAU;
