import { baseApi } from 'store/store';

export const variablesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getStrictVariables: builder.query({
      query: () => ({
        url: '/strictattrs/',
        method: 'GET',
      }),
      providesTags: ['Variables', 'Schemas'],
      // transformResponse: result => result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),
    getStrictVariableById: builder.query({
      query: id => ({
        url: `/strictattrs/${id}`,
        method: 'GET'
      }),
      providesTags: ['Variables', 'Schemas'],
    }),
    addStrictVariable: builder.mutation({
      query: data => ({
        url: '/strictattrs/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Variables', 'Schemas'],
    }),
    updateStrictVariable: builder.mutation({
      query: data => ({
        url: `/strictattrs/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Variables', 'Schemas'],
    }),
    removeStrictVariable: builder.mutation({
      query: id => ({
        url: `/strictattrs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Variables', 'Schemas'],
    }),
    getVariablesUsedTemplates: builder.query({
      query: () => ({
        url: `/strictattrs/usedtemplates`,
        method: 'GET',
      }),
      providesTags: ['VariablesUsed'],
    }),

    getStrictSections: builder.query({
      query: () => ({
        url: '/strictattrsections/',
        method: 'GET',
      }),
      providesTags: ['Sections', 'Schemas'],
      transformResponse: result => result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),
    addStrictSection: builder.mutation({
      query: data => ({
        url: '/strictattrsections/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Sections', 'Schemas'],
    }),
    updateStrictSection: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/strictattrsections/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Sections', 'Schemas'],
    }),
    removeStrictSection: builder.mutation({
      query: id => ({
        url: `/strictattrsections/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Sections', 'Schemas'],
    }),

    getStrictFolders: builder.query({
      query: () => ({
        url: '/strictattrfolders/',
        method: 'GET',
      }),
      providesTags: ['Folders', 'Schemas'],
      transformResponse: result => result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),
    addStrictFolder: builder.mutation({
      query: data => ({
        url: '/strictattrfolders/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Folders', 'Schemas'],
    }),
    updateStrictFolder: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/strictattrfolders/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Folders', 'Schemas'],
    }),
    removeStrictFolder: builder.mutation({
      query: id => ({
        url: `/strictattrfolders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Folders', 'Schemas'],
    }),
  })
});

export const {
  useGetStrictVariablesQuery,
  useGetStrictVariableByIdQuery,
  useAddStrictVariableMutation,
  useUpdateStrictVariableMutation,
  useRemoveStrictVariableMutation,
  useGetVariablesUsedTemplatesQuery,

  useGetStrictSectionsQuery,
  useAddStrictSectionMutation,
  useUpdateStrictSectionMutation,
  useRemoveStrictSectionMutation,
  useGetStrictFoldersQuery,
  useAddStrictFolderMutation,
  useUpdateStrictFolderMutation,
  useRemoveStrictFolderMutation,
} = variablesApi;
