import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format, addDays } from 'date-fns';
import { TableCell, TableRow } from 'components/Table';
import { Modal, Button, Tooltip, Input } from '@forma/forma-ui-kit';
import ContextMenu from 'components/ContextMenu';

import styles from './workspaces-list.module.css';

const WorkspacesListItem = ({
  id, user, currentUsersCount, templatesCount, rendersCount, tariff, dtFrom, dtTo, workspace,
  isPaid, name, price, accountingdetails, patternId, tcrequestsNotProcessedCount, featured,
  tariffs, onClickSignAs, onClickDownloadRequests, onSetPaid, onChangeTariff, onAddFavourite, onRemoveFavourite, onRemove, onProlongTariff
}) => {
  const { t } = useTranslation();
  const [ isModalRemoveShow, setModalRemoveShow ] = useState(false);
  const [ isModalPaidShow, setModalPaidShow ] = useState(false);
  const [ changeTariffTo, setChangeTariffTo ] = useState(null);
  const [ addUsers, setAddUsers ] = useState('');

  const options = [
    {
      text: t('create_bill'),
      disabled: true
    },
    {
      text: t('download_templates'),
      disabled: !tcrequestsNotProcessedCount,
      onClick: () => onClickDownloadRequests(workspace.id)
    },
    {
      text: t('change_tariff'),
      align: 'left',
      items: tariffs?.map(tariffItem => (
        {
          align: 'left',
          text: tariffItem.name,
          icon: tariff.id === tariffItem.id ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
          items: tariffItem.patterns.map(patternItem => ({
            text: patternItem.name,
            icon: patternId === patternItem.id ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
            disabled: !patternItem.canChoose,
            onClick: () => setChangeTariffTo({ tariff: tariffItem, pattern: patternItem })
          }))
        }
      ))
    },
  ];

  if (tariff?.id === 'tariff_trial') options.push(
    {
      text: t('prolong_tariff_for'),
      align: 'left',
      items: [
        {
          text: <span><Trans i18nKey="count_days" values={{ count: 5 }} /></span>,
          onClick: () => {
            const oldDate = new Date() > new Date(dtTo) ? new Date() : new Date(dtTo);
            const dateTo = addDays(oldDate, 5);
            onProlongTariff(id, dateTo.toISOString());
          },
        },
        {
          text: <span><Trans i18nKey="count_days" values={{ count: 14 }} /></span>,
          onClick: () => {
            const oldDate = new Date() > new Date(dtTo) ? new Date() : new Date(dtTo);
            const dateTo = addDays(oldDate, 14);
            onProlongTariff(id, dateTo.toISOString());
          },
        }
      ]
    }
  );

  options.push(
    {
      text: isPaid ? t('paid') : t('mark_paid'),
      icon: isPaid ? <ReactSVG src="/icons/checked.svg" className={styles.checkedIcon} wrapper="span" /> : '',
      onClick: () => !isPaid && setModalPaidShow(true)
    },
    {
      text: t('remove_workspace'),
      onClick: () => setModalRemoveShow(true)
    },
    {
      text: t('sign_as_user'),
      onClick: () => onClickSignAs(workspace.id)
    }
  );

  return (
    <TableRow>
      <TableCell className={styles.account} title={t('user_account')}>
        <div>{user.login}</div>
        {accountingdetails?.inn && (
          <div className={styles.accountInn}>{t('inn')}: {accountingdetails.inn}</div>
        )}
        <div><Link to={`/workspaces/${workspace.id}`}>{t('go_to_details_analytics')}</Link></div>
      </TableCell>
      <TableCell className={styles.count} title={t('count_users')}>{currentUsersCount}</TableCell>
      <TableCell className={styles.count} title={t('templates_created')}>{templatesCount}</TableCell>
      <TableCell className={styles.count} title={t('documents_created')}>{rendersCount}</TableCell>
      <TableCell className={styles.tariff} title={t('tariff')}>
        <div className={styles.tariffName}>{tariff.name}</div>
        <div className={styles.tariffInfo}>
          {isPaid ? (
            (dtFrom && dtTo) &&
              t('paid_from_to', { from: format(new Date(dtFrom), 'dd.MM.yyyy'), to: format(new Date(dtTo), 'dd.MM.yyyy') })
          ) : (
            t('not_paid')
          )}
        </div>
        <div className={styles.tariffInfo}>
          {new Date(dtTo) - new Date() > 0 ? (
            t('active_for_days', { count: Math.floor((new Date(dtTo) - new Date()) / (1000 * 60 * 60 * 24)) })
          ) : (
            t('not_active')
          )}
        </div>
      </TableCell>
      <TableCell className={styles.date} title={t('last_login')}>
        {user.lastValidDate && format(new Date(user.lastValidDate), 'dd.MM.yyyy')} <br />
        {user.lastValidDate && format(new Date(user.lastValidDate), 'HH:mm')}
      </TableCell>
      <TableCell className={styles.date} title={t('registration')}>
        {workspace.createdAt && format(new Date(workspace.createdAt), 'dd.MM.yyyy')} <br />
        {workspace.createdAt && format(new Date(workspace.createdAt), 'HH:mm')}
      </TableCell>
      <TableCell className={styles.buttons}>
        <div className={styles.buttonsContainer}>
          {!!tcrequestsNotProcessedCount && (
            <Tooltip
              control={<img src="/icons/attention.svg" alt="" />}
            >
              {t('templates_to_fill_count', { count: tcrequestsNotProcessedCount })}
            </Tooltip>
          )}
          <Button
            className={styles.button}
            viewStyle="text"
            icon={<ReactSVG src={featured ? '/icons/favorite.svg' : '/icons/favorite-empty.svg'} wrapper="span" />}
            onClick={() => featured ? onRemoveFavourite(workspace.id) : onAddFavourite(workspace.id)}
            title={t('favourites')}
          />
          <ContextMenu
            control={
              <Button
                className={styles.buttonSettings}
                viewStyle="text"
                iconClassName={styles.buttonIcon}
                icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
              />
            }
            offsetY={16}
            position={[ 'left top', 'left bottom', 'right top', 'right bottom' ]}
            items={options}
          />
          <Modal
            open={isModalPaidShow}
            onClose={() => setModalPaidShow(false)}
            title={t('mark_paid')}
            buttons={[
              {
                children: t('submit'),
                onClick: () => onSetPaid({ id, amount: price })
              },
              {
                children: t('cancel'),
                viewStyle: 'tertiary'
              },
            ]}
          >
            <div className={styles.modalContent}>
              <div className={styles.infoTitle}>{t('user_data')}</div>
              <div className={styles.infoRow}>{t('user')}: {user.login}</div>
              <div className={styles.infoRow}>{t('tariff')}: {tariff.name}</div>
              <div className={styles.infoRow}>{t('pattern')}: {name}</div>

              <div className={styles.infoTitle}>{t('price')}</div>
              {/* fixme: add currecies */}
              <div className={styles.infoRow}>{t('total')}: {price} рублей</div>
            </div>
          </Modal>

          <Modal
            open={!!changeTariffTo}
            onClose={() => { setChangeTariffTo(null); setAddUsers(''); }}
            title={t('change_tariff')}
            buttons={[
              {
                children: t('submit'),
                onClick: () => onChangeTariff(workspace.id, { id, ...changeTariffTo, addUsersCount: addUsers })
              },
              {
                children: t('cancel'),
                viewStyle: 'tertiary'
              },
            ]}
          >
            <div className={styles.modalContent}>
              <div className={styles.infoTitle}>{t('user_data')}</div>
              <div className={styles.infoRow}>{t('user')}: {user.login}</div>
              <div className={styles.infoRow}>{t('tariff')}: {tariff.name}</div>
              <div className={styles.infoRow}>{t('pattern')}: {name}</div>
              <div className={styles.infoRow}>
                {new Date(dtTo) - new Date() > 0 ? (
                  t('active_for_days', { count: Math.floor((new Date(dtTo) - new Date()) / (1000 * 60 * 60 * 24)) })
                ) : (
                  t('not_active')
                )}
              </div>

              <div className={styles.infoTitle}>{t('new_tariff')}</div>
              {changeTariffTo && (
                <>
                  <div className={styles.infoRow}>{t('tariff')}: {changeTariffTo.tariff.name}</div>
                  <div className={styles.infoRow}>{t('pattern')}: {changeTariffTo.pattern.name}</div>
                  {changeTariffTo.tariff.canAddUsers && (
                    <Input
                      type="number"
                      name="addUsersCount"
                      label={t('vars.canAddUsers')}
                      placeholder={t('users_count')}
                      className={styles.usersInput}
                      onChange={e => setAddUsers(e.target.value)}
                    />
                  )}
                </>
              )}
            </div>
          </Modal>

          <Modal
            open={isModalRemoveShow}
            onClose={() => setModalRemoveShow(false)}
            title={t('remove_workspace')}
            buttons={[
              {
                children: t('delete'),
                viewStyle: 'danger',
                onClick: () => onRemove(workspace.id)
              },
              {
                children: t('cancel'),
                viewStyle: 'tertiary'
              },
            ]}
          >
            <div className={styles.modalContent} style={{ textAlign: 'center' }}>
              <div>{t('are_you_sure_delete_workspace', { login: user.login })}</div>
              <div>{t('cancellation_notification')}</div>
            </div>
          </Modal>
        </div>
      </TableCell>
    </TableRow>
  )
};

export default WorkspacesListItem;
