import { useTranslation } from 'react-i18next';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import KanbanCard from '../KanbanCard';

import styles from './kanban-column.module.css';

const KanbanColumn = ({ admins, positions, position, tasks, filters, isOver, onChangeItem }) => {
  const { t } = useTranslation();
  const { setNodeRef } = useDroppable({ id: position });

  const filtered = tasks.filter(({ creator, manager }) => (
    (!filters.login || creator.login?.includes(filters.login)) && (!filters.managerlogin || manager.login === filters.managerlogin)
  ));

  return (
    <SortableContext id={position} items={tasks} strategy={verticalListSortingStrategy}>
      <div className={classNames(styles.column, isOver && styles.hover)} ref={setNodeRef}>
        <div className={styles.title}>
          {t(`task_types.${position.toLowerCase()}`)}
          {!!tasks.length && (
            <span className={styles.titleCount}>{(filters.login || filters.managerlogin) && `${filtered.length}/`}{tasks.length}</span>
          )}
        </div>
        <div className={styles.tasks}>
          {tasks.length ? (
            filtered.map(task => (
              <div className={styles.tasksItem} key={task.id}>
                <KanbanCard admins={admins} positions={positions} data={task} onChange={onChangeItem} />
              </div>
            ))
          ) : (
            <div className={styles.notFound}>
              {t(`tasks_empty.${position.toLowerCase()}`)}
            </div>
          )}
        </div>
      </div>
    </SortableContext>
  );
};

export default KanbanColumn;
