import { baseApi } from 'store/store';

export const adminsApi = baseApi.enhanceEndpoints({ addTagTypes: 'Admins' }).injectEndpoints({
  endpoints: builder => ({
    getAdmins: builder.query({
      query: () => ({
        url: '/adminusers',
        method: 'GET',
      }),
      providesTags: [{ type: 'Admins', id: 'All' }]
    }),
    getAdminById: builder.query({
      query: id => ({
        url: `/adminusers/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Admins', id }]
    }),
    addAdmin: builder.mutation({
      query: () => ({
        url: '/adminusers',
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Admins' }]
    }),
    removeAdmin: builder.mutation({
      query: id => ({
        url: `/adminusers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Admins' }]
    }),
  })
});

export const {
  useGetAdminsQuery,
  useGetAdminByIdQuery,
  useAddAdminMutation,
  useRemoveAdminMutation
} = adminsApi;
