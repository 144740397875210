import { useState } from 'react';
import classNames from 'classnames';
import { Button, Input } from '@forma/forma-ui-kit';
import { currencies } from 'data/mock';
import { formatPrice } from 'helpers/formatValues';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-bill.module.css';

const DashboardBill = ({ day, month, week }) => {
  const [ isTargetChange, setTargetChange ] = useState(false);
  const [ target, setTarget ] = useState(localStorage.getItem('d_target') ?? 10000);
  const [ tmpTarget, setTmpTarget ] = useState(localStorage.getItem('d_target') ?? 10000);

  const handleSaveTarget = () => {
    setTarget(tmpTarget);
    setTargetChange(false);
    localStorage.setItem('d_target', tmpTarget);
  }

  return (
    <DashboardBlock title='Средний чек'>
      <div className={styles.root}>
        <div className={styles.column}>
          <div className={styles.title}>Реальный:</div>
          <div className={styles.items}>
            <div className={styles.item}>
              <div className={styles.itemValue}>
                {formatPrice(day, currencies.ru)}{' '}
                {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
              </div>
              <div className={styles.itemLabel}>Сегодня</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemValue}>
                {formatPrice(month, currencies.ru)}{' '}
                {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
              </div>
              <div className={styles.itemLabel}>Неделя</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemValue}>
                {formatPrice(week, currencies.ru)}{' '}
                {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
              </div>
              <div className={styles.itemLabel}>Месяц</div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.column, styles.columnTarget)}>
          <div className={styles.title}>Целевой:</div>
          {isTargetChange ? (
            <div>
              <Input
                className={styles.targetInput}
                onChange={(e) => setTmpTarget(Number(e.target.value.replace(/[^\d]/gi, '')))}
                value={tmpTarget}
              />
              <Button
                onClick={handleSaveTarget}
                viewStyle='text'
                className={styles.targetSave}
              >
                Сохранить
              </Button>
            </div>
          ) : (
            <div className={styles.target}>
              {formatPrice(target, currencies.ru)}
              <Button
                viewStyle="text"
                icon={
                  <img
                    src='/icons/settings.svg'
                    alt=''
                    width={24}
                    height={24}
                    onClick={() => setTargetChange(true)}
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
    </DashboardBlock>
  );
};

export default DashboardBill;
