import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@forma/forma-ui-kit';
import FillDocument from 'components/FillDocument';

import {
  useGetSharedTemplateContentQuery,
  useGetSharedTemplateQuery,
  useRenderSharedTemplateMutation,
} from 'store/templates/templatesApi';
import { useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';

import styles from './Filling.module.css';

const Filling = ({ open, onClose }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: template, error: pageError } = useGetSharedTemplateQuery(id);
  const { data: templateContent } = useGetSharedTemplateContentQuery(template && template.id, { skip: !template });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  // if (template && sidesSucess && !templateSides.sides.length) navigate(`/editor/${slug}`, { replace: true });

  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderSharedTemplateMutation();

  const handleClickDownload = async (name, sideValues, format = 'pdf') => {
    renderTemplate({
      id: template.id,
      name,
      sideValues: sideValues.map(({ tattrValues, ...rest }) => ({
        tattrValues: Object.entries(tattrValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.isArray(value) ? value.join(', ') : value }), {}),
        ...rest
      })),
      format: format
    }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.${format}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={styles.fillingModal}>
        <FillDocument
          name={template?.name}
          sides={templateContent?.sides}
          onClickDownload={handleClickDownload}
          isDownloadLoading={isDownloadLoading}
          counteragentsQueryHook={useGetCounteragentsQuery}
          productsQueryHook={() => ({})}
          showWordButton={true}
        />
      </div>
    </Modal>
  );
};

export default Filling;
