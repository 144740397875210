import { useState, useMemo, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ThemeContext } from '@forma/forma-ui-kit';
import { add, max } from 'date-fns';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import { Modal } from '@forma/forma-ui-kit';
import TimezoneSelect from '../TimezoneSelect';
import TimeItems from '../TimeItems';

import styles from './date-time-modal.module.css';

const getUserTimezone = () => {
  return moment().format('Z');
};

const DateTimeModal = ({ open, onClose, onChange, dateValue, gmtValue, timeslots, readOnly }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ slot, setSlot ] = useState(timeslots?.find(({ id }) => id === dateValue?.slot));
  const [ value, setValue ] = useState(dateValue || moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'));
  const [ utc, setUtc ] = useState(gmtValue || { utc: getUserTimezone() });
  const [ step, setStep ] = useState(0);

  if (i18n.language === 'ru') registerLocale('ru', ru);

  const handleChangeDate = (date) => {
    setValue(date.toISOString().replace('Z', '+03:00'));
    // setValue(date.toISOString().replace('Z', utc.utc));
    setSlot(null);
  };

  const handleChangeTime = (id, date) => {
    setValue(date);
    setSlot(id);
  };

  const handleChangeUTC = (value) => {
    setUtc(value);
  };

  const handleClickSave = () => {
    onChange(value, utc, slot);
    setStep(0);
  };

  const handleClickNext = () => {
    setStep(prev => prev+1);
  };

  const dates = useMemo(() => timeslots?.reduce((acc, current) => {
    const date = current.date.slice(0, 10);
    const slot = { id: current.id, date: current.date, available: current.available };
    const next = {
      date: date,
      dateObj: new Date(date),
      available: current.available || acc[date]?.available || false,
      slots: acc[date] ? [ ...acc[date].slots, slot ] : [ slot ]
    };
    return ({ ...acc, [date]: next });
  }, {}), [timeslots]);

  const disabledDates = dates && Object.values(dates).filter(({ available }) => !available).map(({ dateObj }) => dateObj);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={viewport === 'phone' && (
        <div className={styles.modalTitle}>
          {step === 0 && t('date')}
          {step === 1 && (
            <><ReactSVG src="/icons/arrow-left.svg" className={styles.modalTitleIcon} wrapper="span" onClick={() => setStep(0)} /> {t('time')}</>
          )}
        </div>
      )}
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'primary',
          onClick: (viewport === 'phone' && step !== 1) ? handleClickNext : handleClickSave,
          children: (viewport === 'phone' && step !== 1) ? t('next') : t('save'),
          closing: (viewport !== 'phone' || step === 1),
          disabled: ((viewport !== 'phone' || step === 1) && !slot) || readOnly
        }
      ]}
    >
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {(viewport !== 'phone' || step === 0) && (
            <div className={styles.calendar}>
              <ReactDatePicker
                selected={new Date(value)}
                onChange={handleChangeDate}
                excludeDates={disabledDates}
                minDate={new Date()}
                maxDate={dates ? max(Object.values(dates).map(({ dateObj }) => dateObj)) : add(new Date(), { months: 1 })}
                previousMonthButtonLabel={
                  <ReactSVG src="/icons/arrow-left.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                }
                nextMonthButtonLabel={
                  <ReactSVG src="/icons/arrow-right.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                }
                locale={i18n.language}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                inline
              />
            </div>
          )}

          {(viewport !== 'phone' || step === 1) && (
            <div className={styles.time}>
              <div className={styles.timeHead}>
                {t('client_utc_offset')}
                <TimezoneSelect value={utc} onChange={handleChangeUTC} />
              </div>
              <div className={styles.timeHead}>
                {t('demo_time')}
              </div>
              <div className={styles.timeItems}>
                <TimeItems
                  utc={utc}
                  slots={(dates && dates[moment(value).format('yyyy-MM-DD')]) && dates[moment(value).format('yyyy-MM-DD')].slots}
                  value={slot}
                  onChange={handleChangeTime}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DateTimeModal;
