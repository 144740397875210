import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import KanbanBoard from 'components/KanbanBoard';

import { useGetTasksByPositionsQuery, useUpdateTaskMutation } from 'store/tasks/tasksApi';
import { useGetAdminsQuery } from 'store/admins/adminsApi';

const updateInterval = 3*60*1000;

const RequestsKanban = () => {
  const { t } = useTranslation();
  const [ params, setParams ] = useState({});

  const { data: tasks } = useGetTasksByPositionsQuery(params, { pollingInterval: updateInterval });
  const { data: admins } = useGetAdminsQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: data?.filter(({ role }) => role !== 'TEST_ADMIN') })
  });
  const [ updateTask ] = useUpdateTaskMutation();

  const handleChange = (data) => {
    return updateTask(data);
  };

  return (
    <AdminLayout title={t('tcrequests')}>
      <PageTitle>{t('site_name') + ' – ' + t('tcrequests')}</PageTitle>
      <KanbanBoard
        items={tasks}
        admins={admins}
        onChange={handleChange}
        onFilter={params => setParams(prev => ({ ...prev, ...params }))}
      />
    </AdminLayout>
  );
};

export default RequestsKanban;
