import DashboardBlock from 'components/Dashboard/DashboardBlock';
import { formatPrice } from 'helpers/formatValues';
import { currencies } from 'data/mock';

import styles from './payments-prolongation.module.css';

const PaymentsProlongation = ({ day, week, month }) => (
  <DashboardBlock>
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.value}>{day.count}</div>
        <div className={styles.text}>
          продлений за сегодня на сумму <span className={styles.success}>{formatPrice(day.amount, currencies.ru)}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.value}>{week.count}</div>
        <div className={styles.text}>
          продлений за неделю на сумму <span className={styles.success}>{formatPrice(week.amount, currencies.ru)}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.value}>{month.count}</div>
        <div className={styles.text}>
          продлений за месяц на сумму <span className={styles.success}>{formatPrice(month.amount, currencies.ru)}</span>
        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default PaymentsProlongation;
