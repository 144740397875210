import { format } from 'date-fns';
import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-crm.module.css';

const WorkspaceCRM = ({ notes, status }) => (
  <WorkspaceBlock title="CRM">
    <div className={styles.items}>
      {notes?.map(({ createdAt, params }) => (
        <div className={styles.row} key={createdAt}>
          <div className={styles.date}>{createdAt && format(new Date(createdAt), 'dd.MM.yyyy')}</div>
          <div className={styles.content}>{params?.text}</div>
        </div>
      ))}
    </div>
    <div className={styles.footer}>
      <span className={styles.footerLabel}>Текущий статус в воронке:</span>
      <span className={styles.footerValue}>{status}</span>
    </div>
  </WorkspaceBlock>
);

export default WorkspaceCRM;
