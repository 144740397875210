import classNames from 'classnames';
import { Tooltip } from '@forma/forma-ui-kit';

import styles from './user-icon.module.css';

const UserIcon = ({ name, color, className }) => {
  if (!name) return (
    <div className={classNames(styles.root, className)}>
      <img src="/icons/user.svg" alt="" />
    </div>
  );

  const parts = name.split(' ');

  return (
    <Tooltip
      control={
        <div className={classNames(styles.root, className)} style={{ background: color }}>
          {parts[0]?.slice(0, 1).toUpperCase()}{parts[1]?.slice(0, 1).toUpperCase()}
        </div>
      }
      position={['bottom center', 'top center']}
      offsetX={0}
      offsetY={4}
    >
      {name}
    </Tooltip>
  );
};

export default UserIcon;
