import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ContextMenu from 'components/ContextMenu';

import styles from './variables-group.module.css';

const VariablesItem = ({
  id, index, name, type, notUsed, folderId, sideId, sideName, onClick, onRemove, opacity, isDragging
}) => {
  const { t } = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, data: { index, folderId, type: 'VAR' } });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity
  };

  return (
    <div className={styles.itemWrap} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ContextMenu
        open={isOpen}
        on={['right-click']}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        items={[
          {
            onClick: () => onRemove && onRemove(id),
            text: t('delete')
          }
        ]}
        control={open => (
          <button
            type="button"
            className={classNames(styles.item, notUsed && styles.notUsed, open && styles.active, isDragging && styles.dragging)}
            onClick={() => (onClick && sideId && sideName) && onClick(sideId, sideName, id, name, type)}
          >
            <span className={styles.itemText}>
              {name}
            </span>

            <span className={styles.itemHandler}>
              <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
            </span>
          </button>
        )}
      />
    </div>
  );
};

export default VariablesItem;
