import { currencies } from 'data/mock';
import { formatValue } from 'helpers/formatValues';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-docs.module.css';

const DashboardDocs = ({ day, month, week, year }) => (
  <DashboardBlock>
    <div className={styles.root}>
      <div className={styles.title}>Создано документов:</div>
      <div className={styles.items}>
        <div className={styles.item}>
          <span className={styles.itemValue}>{formatValue(day, currencies.ru)}</span> Сегодня
        </div>
        <div className={styles.item}>
          <span className={styles.itemValue}>{formatValue(week, currencies.ru)}</span> Неделя
        </div>
        <div className={styles.item}>
          <span className={styles.itemValue}>{formatValue(month, currencies.ru)}</span> Месяц
        </div>
        <div className={styles.item}>
          <span className={styles.itemValue}>{formatValue(year, currencies.ru)}</span> Год
        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardDocs;
