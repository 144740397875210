import { differenceInDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Button } from '@forma/forma-ui-kit';
import WorkspaceBlock from '../WorkspaceBlock';
import copyToClipboard from 'helpers/copyToClipboard';

import { setNotification } from 'store/notifications/notificationsSlice';

import styles from './workspace-main-info.module.css';

const WorkspaceMainInfo = ({ workspace, user, users, accountingdetails, tariff }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickCopy = (value) => {
    const res = copyToClipboard(value);
    if (res === 'success') dispatch(setNotification(t('copied'), 'SUCCESS'));
    else dispatch(setNotification(t('error_copying'), 'ERROR'));
  };

  return (
    <WorkspaceBlock title={accountingdetails?.name ? accountingdetails.name : "Данные воркспейса" }>
      <div className={styles.row}>
        <div className={styles.label}>ИНН:</div>
        <div className={styles.value}>
          {accountingdetails?.inn ? (
            <Button
              className={styles.copyButton}
              viewStyle="text"
              onClick={() => handleClickCopy(accountingdetails.inn)}
              icon={<ReactSVG src="/icons/copy.svg" wrapper="span" />}
            >
              {accountingdetails.inn}
            </Button>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Номер телефона:</div>
        <div className={styles.value}>
          {user?.phone ? (
            <Button
              className={styles.copyButton}
              viewStyle="text"
              onClick={() => handleClickCopy('+' + user.phone)}
              icon={<ReactSVG src="/icons/copy.svg" wrapper="span" />}
            >
              +{user?.phone}
            </Button>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Дата создания аккаунта:</div>
        <div className={styles.value}><b>{workspace?.createdAt && format(new Date(workspace.createdAt), 'dd.MM.yyyy')}</b></div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>E-mail:</div>
        <div className={styles.value}>
          <span className={styles.gray}>{user?.emailConfirmed ? 'Подтверждён' : 'Не подтверждён'}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Количество пользователей:</div>
        <div className={styles.value}>
          <b>{users?.count}</b>
          <ReactSVG src="/icons/users-small.svg" className={styles.icon} wrapper="span" />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Контрагентов у пользователя:</div>
        <div className={styles.value}>
          <b>{workspace?.counteragents}</b>
          <ReactSVG src="/icons/countparties.svg" className={styles.icon} wrapper="span" />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Онбординг:</div>
        <div className={styles.value}>
          <span className={styles.gray}>
            {workspace?.onboarding ? 'Пройден' : 'Не пройден'}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Текущий тариф:</div>
        <div className={styles.value}>
          <div><b>{tariff?.name}</b></div>
          <div className={styles.gray}>
            {tariff?.isPaid ? (
              `Оплачен до ${format(new Date(tariff.dtTo), 'dd.MM.yyyy')}`
            ) : (
              t('not_paid')
            )}
          </div>
          {/* <div className={styles.gray}>
            {tariff?.isActive ? (
              t('active_for_days', { count: Math.floor((new Date(tariff.dtTo) - new Date()) / (1000 * 60 * 60 * 24)) })
            ) : (
              t('not_active')
            )}
          </div> */}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Последняя активность:</div>
        {user?.lastActivity && (
          <div className={styles.value}>
            {/* <div><b>{user.lastActivity.actionMethod} {user.lastActivity.actionUrl}</b></div> */}
            <div className={styles.gray}>
              {user?.lastActivity && (
                format(new Date(user.lastActivity.createdAt), 'dd.MM.yyyy HH:mm')
              )} ({(format(new Date(), 'dd.MM.yyyy') === format(new Date(user.lastActivity.createdAt), 'dd.MM.yyyy')) ? (
                t('today')
              ) : (
                t('count_days_ago', { count: differenceInDays(new Date(), new Date(user.lastActivity.createdAt))+1 })
              )})
            </div>
          </div>
        )}
      </div>
    </WorkspaceBlock>
  );
};

export default WorkspaceMainInfo;
