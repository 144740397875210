import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ExplorerListItem, ExplorerListItemSkelet } from './ExplorerListItem';
import NoItems from '../NoItems';

import styles from './explorer-list.module.css';

const preloadImages = (images = []) => {
  if (!images.length) return null;
  images.map(src => {
    const image = new Image();
    image.src = src;
    return image;
  });
};

const ExplorerList = ({
  foldersTree, updated, selected, isLoading, onToggleMenu, onHoverSubmenuItem, favourites,
  navigation, editing, actions, hideOptions, optionsState, onDrop
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    preloadImages([ '/icons/explorer/folder_lock_small.svg' ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updated) {
      // timeout delay for update opened folder content
      setTimeout(() => {
        const elem = document.getElementById(`file_explorer_template_${updated}`);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }

    // eslint-disable-next-line
  }, [foldersTree]);

  return (
    <div>
      {isLoading ? (
        [...Array(10)].map((item, index) => (
          <div className={styles.item} key={index}>
            <ExplorerListItemSkelet />
          </div>
        ))
      ) : (
        (foldersTree && foldersTree.length) ? (
          foldersTree.map(item => (
            <div className={styles.item} key={item.id}>
              <ExplorerListItem
                item={item}
                updated={updated}
                selected={selected}
                optionsState={optionsState}
                onDrop={onDrop}
                onToggleMenu={onToggleMenu}
                onHoverSubmenuItem={onHoverSubmenuItem}
                hideOptions={hideOptions}
                editing={editing}
                actions={actions}
                navigation={navigation}
                openedFolders={pathname.split('/')}
              />
            </div>
          ))
        ) : (
          <div className={styles.noItems}>
            <NoItems onCreate={editing?.onCreate} favourites={favourites} />
          </div>
        )
      )}
    </div>
  );
};

export default ExplorerList;
