import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import AdminsList from 'components/AdminsList';
import { useGetAdminsQuery } from 'store/admins/adminsApi';
import { useState } from 'react';

const Profile = () => {
  const { t } = useTranslation();
  const [ params, setParams ] = useState({});

  const { data: admins } = useGetAdminsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.filter(({ name, login, role }) => (
        (!params.query || name?.toLowerCase().includes(params.query.toLowerCase()) || login?.toLowerCase().includes(params.query.toLowerCase()))
        && (!params.roles || !params.roles.length || params.roles.includes(role))
      ))
    })
  });

  const handleChangeSearch = (value) => {
    setParams(prev => ({ ...prev, query: value }));
  };

  const handleChangeFilter = (params) => {
    setParams(prev => ({ ...prev, ...params }));
  };

  return (
    <AdminLayout title={t('administrators')}>
      <PageTitle>{t('site_name') + ' – ' + t('administrators')}</PageTitle>
      <AdminsList users={admins} onChangeSearch={handleChangeSearch} onChangeFilter={handleChangeFilter} />
    </AdminLayout>
  );
};

export default Profile;
