import { baseApi } from 'store/store';

export const promocodesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPromocodes: builder.query({
      query: () => ({
        url: '/promocodesadmin/',
        method: 'GET',
      }),
      providesTags: ['Promocodes']
    }),
    addPromocode: builder.mutation({
      query: data => ({
        url: '/promocodesadmin/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Promocodes']
    }),
    updatePromocode: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/promocodesadmin/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Promocodes']
    }),
    deletePromocode: builder.mutation({
      query: id => ({
        url: `/promocodesadmin/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Promocodes']
    }),
  })
});

export const {
  useGetPromocodesQuery,
  useAddPromocodeMutation,
  useUpdatePromocodeMutation,
  useDeletePromocodeMutation
} = promocodesApi;
