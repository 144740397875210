import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TemplateEdit from 'components/TemplateEdit';
import TemplatePreviews from 'components/TemplatePreviews';

import {
  useAddSharedTemplateAttachmentMutation,
  useAddSharedTemplatePreviewMutation,
  useGetSharedTemplateAttachmentsQuery,
  useGetSharedTemplatePreviewsQuery,
  useGetSharedTemplateAdminQuery,
  useGetSharedTemplatesCategoriesQuery,
  useRemoveSharedTemplateAttachmentMutation,
  useRemoveSharedTemplatePreviewMutation,
  useSaveSharedTemplateMutation,
  useSortSharedTemplatePreviewsMutation
} from 'store/templates/templatesApi';
import TemplateAttachments from 'components/TemplateAttachments';

const Template = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: template } = useGetSharedTemplateAdminQuery(id);
  const { data: templatePreviews, isFetching: isPreviewsLoading } = useGetSharedTemplatePreviewsQuery(id);
  const { data: templateAttachments } = useGetSharedTemplateAttachmentsQuery(id);
  const { data: templateCategories } = useGetSharedTemplatesCategoriesQuery();
  const [ saveTemplate, { isLoading: isSaveLoading } ] = useSaveSharedTemplateMutation();
  const [ addPreview ] = useAddSharedTemplatePreviewMutation();
  const [ removePreview ] = useRemoveSharedTemplatePreviewMutation();
  const [ sortPreview ] = useSortSharedTemplatePreviewsMutation();
  const [ addAttachments ] = useAddSharedTemplateAttachmentMutation();
  const [ removeAttachment ] = useRemoveSharedTemplateAttachmentMutation();

  const handleAddPreview = async (files) => {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    // formData.append('files', files[0]);
    addPreview({ id, body: formData });
  };

  const handleAddAttachments = async (files) => {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    // formData.append('files', files[0]);
    addAttachments({ id, body: formData });
  };

  const handleSortPreview = (items) => {
    sortPreview(items);
  }

  const handleSave = (values) => {
    saveTemplate({ id, ...values });
  };

  return (
    <AdminLayout
      title={t('shared_templates')}
      breadcrumbs={[{ name: t('shared_templates'), href: '/templates' }, { name: template?.name, href: `/editor/${id}` }, { name: t('description') }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('shared_templates')}</PageTitle>
      <TemplatePreviews
        items={templatePreviews && [...templatePreviews].sort((a, b) => a.position - b.position)}
        isLoading={isPreviewsLoading}
        onAdd={handleAddPreview}
        onRemove={removePreview}
        onSort={handleSortPreview}
      />
      <TemplateAttachments
        items={templateAttachments}
        onAdd={handleAddAttachments}
        onRemove={removeAttachment}
      />
      <TemplateEdit
        data={template}
        categoriesList={templateCategories}
        onSave={handleSave}
        isSaveLoading={isSaveLoading}
      />
    </AdminLayout>
  );
};

export default Template;