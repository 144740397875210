import { Link } from 'react-router-dom';
import { useDebounce } from '@forma/forma-ui-kit';
import { ReactSVG } from 'react-svg';

import styles from './breadcrumbs.module.css';

const Breadcrumbs = ({ items, placeholder, onChange }) => {
  const handleChangeName = useDebounce(e => onChange(e.target.value), 300);

  if (!items) return null;

  return (
    <ul className={styles.list}>
      {items.map(({ name, href }, index) => (
        <li className={styles.item} key={index}>
          {onChange && index === items.length-1 ? (
            <input type="text" className={styles.input} defaultValue={name} placeholder={placeholder} onChange={handleChangeName} />
          ) : (
            (href && index < items.length-1) ? (
              <Link className={styles.link} to={href}>{name}</Link>
            ) : (
              <span className={styles.text}>{name}</span>
            )
          )}
          {index < items.length-1 &&
            <ReactSVG className={styles.icon} src="/icons/arrow-right.svg" />
          }
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
