import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import RequestsGrid from 'components/RequestsGrid';
import LoadingIndicator from 'components/LoadingIndicator';
import { Button } from '@forma/forma-ui-kit';

import {
  useDownloadTcRequestsArchiveMutation,
  useDownloadTcRequestsFileMutation,
  useGetTcRequestsQuery,
  useProcessTcRequestMutation
} from 'store/tcRequests/tcRequestsApi';

import styles from './WorkspaceFillingRequests.module.css';

const WorkspaceFillingRequests = () => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { wid } = useParams();

  const { data: tcrequests, isLoading } = useGetTcRequestsQuery({ wid, sessionId });
  const [ downloadFile ] = useDownloadTcRequestsFileMutation();
  const [ downloadTcRequestsArchive ] = useDownloadTcRequestsArchiveMutation();
  const [ processTcrequest ] = useProcessTcRequestMutation();

  const handleDownloadTemplate = (id, fileName, workspaceId) => {
    downloadFile(id).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${workspaceId}-${fileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const handleDownloadRequests = (workspaceId) => {
    downloadTcRequestsArchive({ wid: workspaceId, notProcessed: true }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('tcrequest_file')} ${workspaceId}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  return (
    <AdminLayout
      title={t('tcrequests')}
      breadcrumbs={[
        { name: t('workspaces'), href: '/workspaces' },
        { name: !!(tcrequests && tcrequests.length) && tcrequests[0].user.login, href: `/workspaces/${wid}` },
        { name: t('tcrequests') }
      ]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('workspaces')}</PageTitle>
      <div className={styles.buttons}>
        <Button
          viewStyle="secondary"
          className={styles.button}
          onClick={() => handleDownloadRequests(wid)}
        >
          Скачать все
        </Button>
      </div>
      <RequestsGrid
        items={tcrequests}
        onDownload={handleDownloadTemplate}
        onCompleteItem={processTcrequest}
      />
      {isLoading && (
        <LoadingIndicator />
      )}
    </AdminLayout>
  )
};

export default WorkspaceFillingRequests;