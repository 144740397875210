import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import styles from './payments-chart.module.css';

const PaymentsChart = ({ data, total }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ color: 'var(--primary-color)' }}>{payload[0].value}</div>
      );
    }

    return null;
  };

  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 35,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" fontSize="14px" includeHidden minTickGap={1} />
            <YAxis width={20} fontSize="14px" />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="var(--primary-color)"
              animationDuration={500}
              dot={({ dataKey, ...props }) => {
                return <circle {...props} r={5} strokeWidth={2}></circle>;
              }}
              activeDot={({ dataKey, ...props }) => {
                return <circle {...props} r={5} strokeWidth={2}></circle>;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.footer}>
        <span className={styles.footerLabel}>Всего:</span>
        <span className={styles.footerValue}>{total}</span>
      </div>
    </div>
  );
};
  // <DashboardBlock
  //   title="Неделя"
  // >
  //   <div className={styles.root}>
  //     <div className={styles.value}>{count}</div>
  //     <div className={styles.text}>
  //       оплат на <span className={classNames(styles.price)}>{formatPrice(amount, currencies.ru)}</span> {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
  //     </div>
  //   </div>
  // </DashboardBlock>

export default PaymentsChart;
