import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/Table';
import { Tooltip } from '@forma/forma-ui-kit';
import Pagination from 'components/Pagination';
import LoadingIndicator from 'components/LoadingIndicator';
import WorkspacesListHeader from './WorkspacesListHeader';
import WorkspacesListItem from './WorkspacesListItem';

import styles from './workspaces-list.module.css';

const WorkspacesList = ({
  tariffs, items, featured, onSearch, onFilter, onClickSignAs, onClickDownloadRequests, onSetPaid, onChangeTariff, pagination,
  workspacesCount, usersCount, isLoading, onAddFavourite, onRemoveFavourite, onRemove, onProlongTariff
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <div className={styles.headerItem} title={t('count_workspaces')}>
            <ReactSVG src="/icons/company.svg" className={styles.headerIcon} wrapper="span" /> {workspacesCount}
          </div>
          <div className={styles.headerItem} title={t('count_users')}>
            <ReactSVG src="/icons/user.svg" className={styles.headerIcon} wrapper="span" /> {usersCount}
          </div>
        </div>
        <WorkspacesListHeader
          tariffs={tariffs}
          featured={featured}
          onChangeSearch={onSearch}
          onChangeFilter={onFilter}
        />
      </div>
      <div className={styles.tableWrap}>
        <Table className={styles.table} columns={[
          { children: t('user_account') },
          { children: <Tooltip control={<div><ReactSVG src="/icons/user.svg" /></div>}>{t('count_users')}</Tooltip> },
          { children: <Tooltip control={<div><ReactSVG src="/icons/document-one.svg" /></div>}>{t('templates_created')}</Tooltip> },
          { children: <Tooltip control={<div><ReactSVG src="/icons/document-many.svg" /></div>}>{t('documents_created')}</Tooltip> },
          { children: t('tariff') },
          { children: t('last_login') },
          { children: t('registration') },
          { children: '' },
        ]}>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <LoadingIndicator />
              </td>
            </tr>
          ) : (
            items?.map(item => (
              <WorkspacesListItem
                {...item}
                tariffs={tariffs}
                onClickSignAs={onClickSignAs}
                onClickDownloadRequests={onClickDownloadRequests}
                onSetPaid={onSetPaid}
                onChangeTariff={onChangeTariff}
                onAddFavourite={onAddFavourite}
                onRemoveFavourite={onRemoveFavourite}
                onRemove={onRemove}
                onProlongTariff={onProlongTariff}
                key={item.id}
              />
            ))
          )}
        </Table>
      </div>
      <Pagination {...pagination} />
    </div>
  );
};

export default WorkspacesList;
