import { baseApi } from 'store/store';
import { setToken } from './authSlice';

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation({
      query: data => ({
        url: '/auth/login',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
          localStorage.setItem('token', data.token);
        } catch (error) {}
      }
    }),
  })
});

export const {
  useLoginUserMutation,
} = authApi;
