import { baseApi } from 'store/store';

export const tcRequestsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTcRequests: builder.query({
      query: data => ({
        url: '/tcrequestsadmin/',
        method: 'GET',
        params: data
      }),
      providesTags: ['Requests'],
    }),
    processTcRequest: builder.mutation({
      query: id => ({
        url: `/tcrequestsadmin/${id}/process`,
        method: 'PUT',
      }),
      invalidatesTags: ['Requests'],
    }),
    downloadTcRequestsFile: builder.mutation({
      query: id => ({
        url: `/tcrequestsadmin/${id}/download`,
        method: 'GET',
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
    }),
    downloadTcRequestsArchive: builder.mutation({
      query: data => ({
        url: '/tcrequestsadmin/downloadArchive',
        method: 'GET',
        params: data,
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
    })
  })
});

export const {
  useGetTcRequestsQuery,
  useProcessTcRequestMutation,
  useDownloadTcRequestsFileMutation,
  useDownloadTcRequestsArchiveMutation
} = tcRequestsApi;
