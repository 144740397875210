import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchInput from 'components/SearchInput';
import { Button } from '@forma/forma-ui-kit';

import CreateFolder from '../CreateFolder';
import ExplorerList from '../ExplorerList';

import { selectAdminData } from 'store/user/userSlice';

import styles from './explorer-head.module.css';

const ExplorerHead = ({
  items, search, favourites, editing, navigation,
  hideOptions, onToggleMenu, onHoverSubmenuItem, actions, selected
}) => {
  const { t } = useTranslation();
  const adminData = useSelector(selectAdminData);
  // const userPermissions = useSelector(selectUserPermissions);
  // const canChangeFolders = userPermissions?.includes('folders_templates');

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <SearchInput
          onSearch={search.onChange}
          className={classNames(styles.search, search.results && styles.active)}
          placeholder={t('search')}
        />
        {(editing?.onCreate && adminData?.role === 'COMMON_ADMIN') && (
        // {(canChangeFolders && editing) && (
          <>
            {/* <CreateItem type="template" onCreate={editing.onCreate} /> */}
            <CreateFolder onCreate={editing.onCreate} />
          </>
        )}
        {adminData?.role === 'COMMON_ADMIN' && (
          <div>
            <Button as={Link} className={styles.button} to="/templates-categories">{t('edit_categories')}</Button>
          </div>
        )}
        {/* <div>
          <Button
            as={Link}
            to={favourites.href}
            onClick={favourites.onClick}
            viewStyle={favourites.isOpen ? 'primary' : 'secondary'}
            icon={<ReactSVG src="/icons/favorite.svg" wrapper="span" className={classNames(styles.icon, favourites.isOpen && styles.active)} />}
            shadow
          >
            {t('favourites')}
          </Button>
        </div> */}
      </div>
      {search.results &&
        <div className={styles.searchResults}>
          {search.isLoading ? (
            <div className={styles.loader}>
              <LoadingIndicator color="var(--color-white-primary)" />
            </div>
          ) : (
            search.results.length ? (
              <ExplorerList
                foldersTree={search.results}
                selected={selected}
                editing={editing}
                hideOptions={hideOptions}
                onToggleMenu={onToggleMenu}
                onHoverSubmenuItem={onHoverSubmenuItem}
                actions={actions}
                navigation={{
                  ...navigation, onShowFolderContent: () => {}
                }}
              />
            ) : (
              <div className={styles.searchNoItems}>
                {t('search_nothing_found')}
              </div>
            )
          )}
        </div>
      }
    </div>
  );
};

export default ExplorerHead;
