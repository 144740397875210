import classNames from 'classnames';
import moment from 'moment';

import styles from './time-items.module.css';

const TimeItems = ({ utc, slots, value, onChange, timeText }) => {
  return (
    <div className={styles.root}>
      <div className={styles.items}>
        {slots?.map(({ id, date, available }) => {
          const formattedDate = moment(date).utcOffset('+03:00').format('HH:mm');
          const isActive = value === id || (Array.isArray(value) && value.includes(id));

          return (
            <div
              className={classNames(styles.item, !available && styles.disabled, isActive && styles.selected)}
              onClick={() => !isActive && onChange(id, date)}
              tabIndex="0"
              key={id}
            >
              {/* {moment(date).utcOffset(utc.utc).format('HH:mm')} */}
              {timeText ? (
                timeText(formattedDate, !available, isActive)
              ) : (
                formattedDate
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeItems;
