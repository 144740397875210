import styles from './devices-list.module.css';

import { devices_icons } from 'data/mock';

const getDeviceType = (windowResolution) => {
  if (!windowResolution) return 'desktop';
  const width = windowResolution.split('x')[0];
  if (width <= 575) return 'phone';
  if (width <= 1024) return 'tablet';
  return 'desktop';
};

const getOsName = (userDevice, userOs) => {
  if (!userDevice && !userOs) return 'unknown';
  if ((userOs && (/Mac OS|iOS/i).test(userOs)) || (userDevice && (/Apple|iP(hone|od|ad)|Macintosh/i).test(userDevice))) return 'apple';
  if ((userOs && (/Android/i).test(userOs)) || (userDevice && (/Android/i).test(userDevice))) return 'android';
  if ((userOs && (/Windows/i).test(userOs)) || (userDevice && (/Windows/i).test(userDevice))) return 'windows';
};

const DevicesList = ({ items }) => {
  return (
    <div className={styles.items}>
      {items?.map(({ userOs, userDevice, userBrowser, windowResolution, createdAt }) => {
        const deviceType = getDeviceType(windowResolution);
        const osName = getOsName(userDevice, userOs)
        return (
          <div className={styles.row} key={userBrowser + userOs + windowResolution}>
            <img
              src={deviceType ? (devices_icons[deviceType][osName] ?? devices_icons[deviceType].unknown) : devices_icons.desktop.unknown}
              className={styles.icon}
              width={77}
              height={77}
              alt=""
            />
            <div className={styles.content}>
              <div className={styles.name}>{userOs}</div>
              <div className={styles.description}>{userDevice ? userDevice + ', ' : ''}{windowResolution}</div>
              <div className={styles.browser}>{userBrowser}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DevicesList;
