import { Link } from 'react-router-dom';
import DevicesList from 'components/DevicesList';
import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-devices.module.css';

const WorkspaceDevices = ({ id, userId, items }) => {
  return (
    <WorkspaceBlock title="Устройства, с которых выполнен вход">
      <DevicesList items={items} />
      {(!!items?.length && userId) && (
        <div className={styles.buttons}>
          <Link className={styles.link} to={`/workspaces/${id}/devices/${userId}`}>Смотреть все</Link>
        </div>
      )}
    </WorkspaceBlock>
  );
};

export default WorkspaceDevices;
