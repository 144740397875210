import classnames from 'classnames';
import Logo from 'components/Logo';

import styles from './main-layout.module.css';

const MainLayout = ({ location, children }) => {
  return (
    <>
      <header className={classnames(styles.header, 'container')} id="header">
        <div className={styles.logo}>
          <Logo showText={true} />
        </div>
      </header>
      <div className={classnames(styles.content, 'container')}>
        {children}
      </div>
    </>
  );
};

export default MainLayout;
