import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { usePagination, DOTS } from './usePagination';

import styles from './pagination.module.css';

const Pagination = ({ page = 1, size = 10, count = 0, onChange }) => {
  if (!page) page = 1;
  const pages = usePagination({
    currentPage: page,
    totalCount: count,
    pageSize: size
  });

  if (!(count && pages && pages.length > 1)) return null;

  const lastPage = Number(pages[pages.length - 1]);

  const handleClickPrev = () => {
    const next = page > 1 ? page-1 : 1;
    onChange(next);
  };

  const handleClickNext = () => {
    const next = page < lastPage ? page+1 : lastPage;
    onChange(next);
  };

  const handleClickPage = (next) => {
    onChange(next);
  };

  return (
    <div className={styles.root}>
      <button className={classNames(styles.arrow, page <= 1 && styles.disabled)} onClick={handleClickPrev}>
        <ReactSVG src="/icons/arrow-left-bold.svg" className={styles.icon} wrapper="span" />
      </button>
      {pages && pages.map(item => {
        if (item === DOTS) return (<span key={item}>{item}</span>);
        return (
          <button
            className={classNames(styles.item, page === item && styles.active)}
            onClick={() => handleClickPage(Number(item))}
            key={item}
          >
            {item}
          </button>
        );
      })}
      <button className={classNames(styles.arrow, page >= lastPage && styles.disabled)} onClick={handleClickNext}>
        <ReactSVG src="/icons/arrow-right-bold.svg" className={styles.icon} wrapper="span" />
      </button>
    </div>
  );
};

export default Pagination;
