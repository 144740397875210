import { variablesTypes } from 'data/schemas';

const ignoreList = ['type', 'patternId', 'parentid', 'role', 'lang'];

export const formValuesToData = (schema, values) => {
  if (!values || !Object.keys(values).length) return {};
  return Object.keys(values).reduce((prev, key) => {
    const current = values[key];
    let value = '';

    if (current || current === false) {
      if (current.value) value = current.value;
      else value = current;

      if (ignoreList.includes(key) || value === false) return { ...prev, [key]: value };

      if (key === 'charset') return { ...prev, [key]: value.replaceAll(',', '') };
      if (schema[key]?.type === variablesTypes.LIST) return { ...prev, [key]: value.split(',') };
      if (value) return { ...prev, [key]: value };
    }
    return prev;
  }, {});
};

export const formDataToValues = (schema, values) => {
  if (!values || !Object.keys(values).length) return {};
  return Object.keys(values).reduce((prev, key) => {
    const current = values[key];
    let value = '';

    if (current || current === false) {
      if (current.value) value = current.value;
      else value = current;

      if (ignoreList.includes(key) || value === false) return { ...prev, [key]: value };

      if (key === 'charset') return { ...prev, [key]: value.split('').join(',') };
      if (schema[key]?.type === variablesTypes.LIST) return { ...prev, [key]: value.join(',') };
      if (value) return { ...prev, [key]: value };
    }
    return prev;
  }, {});
}
