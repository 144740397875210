import { forwardRef, useRef, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Select, { Option } from 'rc-select';
import { ReactSVG } from 'react-svg';
import { v4 } from 'uuid';
import axios from 'axios';
import { ThemeContext, useDebounce } from '@forma/forma-ui-kit';
import 'rc-select/assets/index.less';

import styles from './select.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const SelectInput = forwardRef(({
  id = v4(), name, options = [], onChange, onFocus, onBlur, onUnFocus, hidden, fullWidth, label,
  className, labelClass, containerClass, multiple, error, errorMessage, icon, notice, disabled, placeholder,
  defaultValue, value, showArrow = true, showClearButton = false, valueMutationFunction, searchable, creatable, afterDropdownButton,
  loadingMethod = 'post', loadingUrl, loadingParam = 'query', loadingMapper, customLabelKey, dropdownWidth, dropdownClassName, showAllSelected, register, ...props
}, ref) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const { token, lang: language } = useContext(ThemeContext);
  const [ loadedOptions, setLoadedOptions ] = useState(null);
  const [ isLoading, setLoading ] = useState(false);
  const [ dropdownPosition, setDropdownPosition ] = useState(null);

  // eslint-disable-next-line
  if (Object.keys(props).length) console.log('select unregistered props', props);

  useEffect(() => {
    updatePositions();
    document.dispatchEvent(new Event('selectUpdate', { bubbles: true }));
    // eslint-disable-next-line
  }, [options, loadedOptions]);

  const updatePositions = () => {
    setTimeout(() => {
      if (dropdownRef.current) {
        const classes = dropdownRef.current?.closest('.rc-select-dropdown')?.classList.value;
        const positions = [ 'topLeft', 'topRight', 'bottomLeft', 'bottomRight' ];
        positions.forEach(position => {
          if (classes?.match(position)) setDropdownPosition(position);
        });
      }
    }, 100);
  };

  const handleChange = (value, option) => {
    let val = value;
    if (valueMutationFunction) val = valueMutationFunction(val);
    if (onChange) onChange({ target: { name, value: val } }, option.data);
    if (loadingUrl) loadOptions(val);
  };
  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
    updatePositions();
    document.dispatchEvent(new Event('selectOpen', { bubbles: true }));
  };
  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
    if (onUnFocus) onUnFocus(e);
    document.dispatchEvent(new Event('selectClose', { bubbles: true }));
  };

  const loadOptions = useDebounce((inputValue) => {
    if (!API_URL || !loadingUrl) return;
    setLoading(true);
    axios.request({
      method: loadingMethod,
      url: API_URL + loadingUrl,
      params: loadingMethod === 'get' && { [loadingParam]: inputValue },
      data: loadingMethod === 'post' && { [loadingParam]: inputValue },
      headers: { 'authorization': `Bearer ${token}`, 'X-Language': `${language}` }
    }).then(res => {
      const options = loadingMapper ?
        loadingMapper(res.data, inputValue) : res.data.map((value) => ({ label: value, value: value }));

      setLoadedOptions(options);
      setLoading(false);
    }).catch(err => setLoading(false));
  }, 200);

  const adjustX = dropdownWidth === true ? 0 : 1;
  const builtinPlacements = {
    bottomLeft: {
      points: ['tl', 'bl'],
      offset: [0, 0],
      overflow: {
        adjustX: adjustX,
        adjustY: 1
      },
      htmlRegion: 'scroll'
    },
    bottomRight: {
      points: ['tr', 'br'],
      offset: [0, 0],
      overflow: {
        adjustX: adjustX,
        adjustY: 1
      },
      htmlRegion: 'scroll'
    },
    topLeft: {
      points: ['bl', 'tl'],
      offset: [0, 0],
      overflow: {
        adjustX: adjustX,
        adjustY: 1
      },
      htmlRegion: 'scroll'
    },
    topRight: {
      points: ['br', 'tr'],
      offset: [0, 0],
      overflow: {
        adjustX: adjustX,
        adjustY: 1
      },
      htmlRegion: 'scroll'
    }
  };

  let mode;
  if (multiple) mode = 'multiple';
  if (!!loadingUrl || searchable) mode = 'combobox';
  if (creatable) mode = 'tags';

  if (!placeholder) {
    if (creatable) placeholder = t('uikit:enter_value');
    else placeholder = t('uikit:select_value');
  }

  return (
    <div className={classNames(styles.container, fullWidth && styles.fullWidth, containerClass, hidden && styles.hidden)}>
      {label && <label className={classNames(styles.label, labelClass)} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer} ref={containerRef}>
        <Select
          id={id}
          // name={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={classNames(
            styles.input,
            className,
            (icon) && styles.withIcon,
            error && 'error',
            (dropdownWidth && containerRef.current && containerRef.current.getBoundingClientRect().width < dropdownWidth) && 'rc-select-wide',
            dropdownPosition && `rc-select-${dropdownPosition}`,
            showAllSelected && 'rc-select-tags'
          )}
          placeholder={placeholder ? placeholder : t('select_value')}
          mode={mode}
          disabled={disabled}
          allowClear={showClearButton}
          optionLabelProp={customLabelKey}
          menuItemSelectedIcon={() => multiple ? <ReactSVG src="/icons/checked.svg" /> : null}
          suffixIcon={({ loading }) =>
            loading ? <ReactSVG src="/icons/loading.svg" wrapper="span" /> : (showArrow && <ReactSVG src="/icons/dropdown.svg" wrapper="span" />)
          }
          removeIcon={() => null}
          notFoundContent={<span>{t('empty')}</span>}
          maxTagPlaceholder={(values) => (values && !showAllSelected) ? <span className="rc-select-selection-count">+{values.length}</span> : null}
          maxTagCount={showAllSelected ? 10 : 1}
          listHeight={290}
          dropdownMatchSelectWidth={dropdownWidth}
          builtinPlacements={builtinPlacements}
          dropdownClassName={classNames(
            dropdownClassName,
            multiple && 'rc-select-dropdown-multiple',
            (dropdownWidth && containerRef.current && containerRef.current.getBoundingClientRect().width < dropdownWidth) && 'rc-select-dropdown-wide'
          )}
          showSearch={!!loadingUrl || !!searchable || !!creatable}
          optionRender={(option) => {
            if ((options.findIndex(({ value }) => value === option.value) === -1)
              && (!loadedOptions || loadedOptions.findIndex(({ value }) => value === option.value) === -1)
              && (!Array.isArray(value) || !value.includes(option.value))
            ) return `Добавить "${option.label}"`;
            return option.label;
          }}
          dropdownRender={menu => (
            <div ref={dropdownRef}>
              {menu}
              {afterDropdownButton && (
                <div onClick={() => ref?.blur()}>
                  {afterDropdownButton}
                </div>
              )}
            </div>
          )}
          loading={isLoading}
          virtual={false}
          defaultValue={defaultValue}
          value={value || undefined}
          ref={r => ref = r}
        >
          {loadedOptions ? (
            // data attribute returns object, but it need for backward compatibility options list params
            loadedOptions.map(({ label, value, className, data }, index) => (
              <Option className={className} value={value} data={data} key={index}>
                {label}
              </Option>
            ))
          ) : (
            options.map(({ label, value, className, displayLabel }, index) => (
              <Option className={className} value={value} displayLabel={displayLabel} key={index}>
                {label}
              </Option>
            ))
          )}
        </Select>
        {icon &&
          <span className={styles.icons}>
            {icon && <span className={styles.icon}>{icon}</span>}
          </span>
        }
      </div>
      {(error && errorMessage) &&
        <span className={styles.errorText}>{errorMessage}</span>
      }
      {notice &&
        <span className={styles.notice}>{notice}</span>
      }
    </div>
  );
});

export default SelectInput;