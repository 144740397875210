import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from '@forma/forma-ui-kit';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-requests.module.css';

const DashboardRequests = ({ day, week, pending }) => (
  <DashboardBlock
    title="Шаблонов на настройку"
    buttons={
      <Button
        as={Link}
        viewStyle="text"
        icon={<img src="/icons/document-setting.svg" width={24} height={24} alt="" />}
        to="/workspaces"
      />
    }
  >
    <div className={styles.root}>
      <div className={classNames(styles.item, styles.active)}>
        <div className={styles.itemValue}>{pending}</div>
        <div className={styles.itemText}>В&nbsp;ожидании</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue}>{day}</div>
        <div className={styles.itemText}>Настроено сегодня</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue}>{week}</div>
        <div className={styles.itemText}>Настроено за&nbsp;неделю</div>
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardRequests;
