import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Button, ButtonsContainer, Input } from '@forma/forma-ui-kit';
import Select from 'components/fields/Select';
import DateTimeInput from 'components/fields/DateTimeInput';
import ControlField from 'components/fields/ControlField';

import styles from './demo-request-edit.module.css';

const fields = [
  'clientFullName', 'clientEmail', 'companyName', 'dateTime',
  'clientPhone', 'chanel', 'clientComment', 'managerId'
];

const DemoRequestEdit = ({ managers, timeslots, data, onCancel, onSave, onAdd }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [ searchParams ] = useSearchParams();

  const { register, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    if (data) onSave(values);
    else onAdd(values);
  };

  useEffect(() => {
    if (data) {
      fields.map(key => setValue(key, data[key]));
      setValue('dateTime', {
        date: data.date,
        utc: { utc: data.gmt },
        slot: data.timeslotId
      });
    }

    // eslint-disable-next-line
  }, [data]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Button
        as={Link}
        className={styles.buttonBack}
        viewStyle="text"
        icon={<ReactSVG src="/icons/arrow-left-bold.svg" wrapper="span" />}
        to={location.pathname + '?managerId=' + searchParams.get('managerId')}
      >
        {t('go_back')}
      </Button>
      <div className={styles.fields}>
        {fields.map(key => {
          if (key === 'dateTime') return (
            <ControlField
              component={DateTimeInput}
              id={key}
              name={key}
              label={t(`demo.${key}`)}
              disabled={!!data}
              timeslots={timeslots}
              fullWidth
              register={register}
              control={control}
              error={errors[key] && errors[key].message}
            />
          );

          if (key === 'managerId') return (
            <ControlField
              component={Select}
              id={key}
              name={key}
              label={t(`demo.${key}`)}
              options={managers?.map(({ fullName, id }) => ({ label: fullName, value: id }))}
              fullWidth
              register={register}
              control={control}
              error={errors[key] && errors[key].message}
            />
          );

          return (
            <ControlField
              component={Input}
              id={key}
              name={key}
              label={t(`demo.${key}`)}
              disabled={!!data}
              fullWidth
              register={register}
              control={control}
              error={errors[key] && errors[key].message}
            />
          );
        })}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="secondary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {data ? t('save') : t('add')}
        </Button>
      </ButtonsContainer>
    </form>
  )
};

export default DemoRequestEdit;
