import { useState, useRef, useEffect } from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, LoadingButton } from '@forma/forma-ui-kit';
import SideBlock from '../SideBlock';

import styles from './side.module.css';

const Side = ({
  id, sides, variablesGroups, availableVariables,
  onAdd, onRemove, onChange, onMove, onClickSave, onClickVariable, onClickFill, onClickAddVariations, isSaveLoading
}) => {
  const containerRef = useRef(null);
  const controlsRef = useRef(null);
  const { t } = useTranslation();
  const [ dragIndex, setDragIndex ] = useState(null);

  const updateControlsHeight = () => {
    if (controlsRef?.current && containerRef?.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const top = controlsRef.current.offsetTop;
      controlsRef.current.style.height = window.innerHeight - top - containerTop + 'px';
    }
  };

  useEffect(() => {
    updateControlsHeight();
    document.addEventListener('scroll', updateControlsHeight);
    return () => document.removeEventListener('scroll', updateControlsHeight);
  }, [controlsRef]);

  const handleDragStart = ({ active }) => {
    setDragIndex(active.data.current?.index);
  };

  const handleDragEnd = ({ active, over }) => {
    setDragIndex(null);
    if (over && active.id !== over.id) {
      onMove(active.data.current?.index, over.data.current?.index);
    }
  };

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.buttons}>
        <Button
          onClick={onClickFill}
          viewStyle="secondary"
          shadow
          fullWidth
        >
          {t('fill')}
        </Button>
        <LoadingButton
          id="save_template_button"
          isLoading={isSaveLoading}
          onClick={onClickSave}
          viewStyle="primary"
          shadow
          fullWidth
        >
          {t('save')}
        </LoadingButton>
      </div>
      <div className={classNames(styles.controls, 'styled-scrollbar')} ref={controlsRef}>
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SortableContext items={sides} strategy={verticalListSortingStrategy}>
            {sides.map(({ id, name, tattrFolders }, index) => (
              <SideBlock
                id={id}
                index={index}
                name={name}
                folders={tattrFolders}
                opacity={dragIndex === index ? 0 : 1}
                variablesGroups={variablesGroups}
                availableVariables={availableVariables}
                onClickVariable={onClickVariable}
                onClickAddVariations={onClickAddVariations}
                onRemove={onRemove}
                onChange={onChange}
                key={id}
              />
            ))}
          </SortableContext>
          {(dragIndex || dragIndex === 0) && (
            <DragOverlay zIndex={10} modifiers={[ restrictToFirstScrollableAncestor ]}>
              <SideBlock
                id={sides[dragIndex].id}
                index={dragIndex}
                name={sides[dragIndex].name}
                folders={sides[dragIndex].tattrFolders}
                availableVariables={availableVariables}
                onClickVariable={onClickVariable}
                onClickAddVariations={onClickAddVariations}
                onRemove={onRemove}
                onChange={onChange}
                isDragging
              />
            </DragOverlay>
          )}
        </DndContext>
        <div className={styles.controlsButtons}>
          <Button
            id="add_side_button"
            viewStyle="secondary"
            icon={<ReactSVG src="/icons/plus.svg" className={styles.iconAdd} />}
            onClick={onAdd}
            fullWidth
            shadow
          >
            {t('add_side')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Side;
