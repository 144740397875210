import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDaysInMonth } from 'date-fns';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import DashboardBlock from 'components/Dashboard/DashboardBlock';
import PaymentsChart from './PaymentsChart';

import styles from './payments-statistic.module.css';

const periods = [ 'day', 'week', 'month', 'year' ];

const PaymentsStatistic = ({ data }) => {
  const { t } = useTranslation();
  const [ viewPeriod, setViewPeriod ] = useState('week');

  const now = new Date();
  const daysInMonth = getDaysInMonth(now);

  const currentPeriod = data[viewPeriod]?.reduce((acc, current) => {
    const date = new Date(current.date);
    let key;
    if (viewPeriod === 'day') key = date.getHours();
    else if (viewPeriod === 'week') key = date.getDay();
    else if (viewPeriod === 'month') key = date.getDate();
    else if (viewPeriod === 'year') key = date.getMonth();

    const next = { ...acc };
    next[key] = next[key] ? { ...next[key], count: next[key].count + 1 } : { date: current.date, count: 1 };
    return next;
  }, {});

  return (
    <DashboardBlock>
      <div className={styles.header}>
        {periods.map(period => (
          <Button
            className={classNames(styles.button, viewPeriod === period && styles.active)}
            viewStyle="secondary"
            onClick={() => setViewPeriod(period)}
            key={period}
          >
            {t(period)}
          </Button>
        ))}
      </div>
      {data && (
        <div className={styles.content}>
          {viewPeriod === 'day' && (
            <PaymentsChart
              data={Array.from({ length: 24 }, (_, index) => index).map((hour) => (
                { name: `0${hour}`.slice(-2), value: currentPeriod[hour]?.count ?? 0 }
              ))}
              total={Object.values(currentPeriod).reduce((acc, current) => (acc+current.count), 0)}
            />
          )}
          {viewPeriod === 'week' && (
            <PaymentsChart
              data={Array.from({ length: 7 }, (_, index) => index).map((day) => (
                { name: t(`day_names.${day}`), value: currentPeriod[day]?.count ?? 0 }
              ))}
              total={Object.values(currentPeriod).reduce((acc, current) => (acc+current.count), 0)}
            />
          )}
          {viewPeriod === 'month' && (
            <PaymentsChart
              data={Array.from({ length: daysInMonth }, (_, index) => index).map((day) => {
                return { name: day, value: currentPeriod[day]?.count ?? 0 };
              })}
              total={Object.values(currentPeriod).reduce((acc, current) => (acc+current.count), 0)}
            />
          )}
          {viewPeriod === 'year' && (
            <PaymentsChart
              data={Array.from({ length: 12 }, (_, index) => index).map((month) => {
                return { name: t(`month_names.${month+1}`), value: currentPeriod[month]?.count ?? 0 };
              })}
              total={Object.values(currentPeriod).reduce((acc, current) => (acc+current.count), 0)}
            />
          )}
        </div>
      )}
    </DashboardBlock>
  );
};

export default PaymentsStatistic;
