import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { Modal } from '@forma/forma-ui-kit';
import FillDocument from 'components/FillDocument';

import {
  useGetSharedTemplateContentQuery,
  useGetSharedTemplateQuery,
  useRenderSharedTemplateMutation,
} from 'store/templates/templatesApi';
import { useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';

import styles from './Filling.module.css';

const Filling = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ isOpenEmpty, setOpenEmpty ] = useState(false);

  const { data: template, error: pageError } = useGetSharedTemplateQuery(id);
  const { data: templateContent, isSuccess: sidesSucess } = useGetSharedTemplateContentQuery(template && template.id, { skip: !template });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (sidesSucess && !templateContent?.sides.length) setOpenEmpty(true);
    // eslint-disable-next-line
  }, [sidesSucess]);

  // if (template && sidesSucess && !templateSides.sides.length) navigate(`/editor/${slug}`, { replace: true });

  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderSharedTemplateMutation();

  const handleClickDownload = async (name, sideValues, format = 'pdf') => {
    renderTemplate({
      id: template.id,
      name,
      sideValues: sideValues.map(({ tattrValues, ...rest }) => ({
        tattrValues: Object.entries(tattrValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.isArray(value) ? value.join(', ') : value }), {}),
        ...rest
      })),
      format: format
    }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.${format}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  return (
    <AdminLayout
      title={t('fill_document_by_template')}
      breadcrumbs={[{ name: t('shared_templates'), href: '/templates' }, { name: template?.name, href: `/editor/${id}` }, { name: t('fill_by_template') }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('fill_by_template')}</PageTitle>
      <FillDocument
        name={template?.name}
        sides={templateContent?.sides}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        counteragentsQueryHook={useGetCounteragentsQuery}
        productsQueryHook={() => ({})}
        showWordButton={true}
      />
      <Modal
        open={isOpenEmpty}
        onClose={() => setOpenEmpty(false)}
        buttons={[
          {
            as: Link,
            viewStyle: 'primary',
            children: t('open_document_in_editor'),
            to: `/editor/${id}`
          }
        ]}
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription} dangerouslySetInnerHTML={{ __html: t('empty_document_sides_description') }} />
      </Modal>
    </AdminLayout>
  );
};

export default Filling;
