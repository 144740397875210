import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Button, Switcher } from '@forma/forma-ui-kit';
import SearchInput from 'components/SearchInput';
import UserSelect from '../UserSelect';

import styles from './kanban-board-header.module.css';

const KanbanBoardHeader = ({ onSearch, onFilter, onClickAdd, items }) => {
  const { t } = useTranslation();

  const admins = items?.flatMap(({ tasks }) => tasks.map(({ manager }) => manager))
    .filter(({ id }, index, array) => id && array.findIndex(manager => manager.id === id) === index);

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <SearchInput
          onSearch={onSearch}
          className={styles.search}
          placeholder={t('enter_user_email')}
        />
        <UserSelect items={admins} onSelect={(_id, login) => onFilter({ managerlogin: login })} />
        <Button
          viewStyle="secondary"
          onClick={onClickAdd}
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          iconClassName={styles.addIcon}
          shadow
        >
          {t('create_task')}
        </Button>
      </div>
      <div className={styles.row}>
        <Switcher
          containerClass={styles.switcher}
          label={t('show_impossible')}
          onChange={e => onFilter({ impossible: e.target.checked })}
        />
      </div>
    </div>
  );
};

export default KanbanBoardHeader;