export const currencies = {
  ru: {
    code: 'ru-RU',
    symbol: 'RUB'
  },
  en: {
    code: 'en-US',
    symbol: 'USD'
  },
};

export const devices_icons = {
  'desktop': {
    'unknown': '/images/devices/desktop.svg',
    'windows': '/images/devices/desktop-windows.svg',
    'linux': '/images/devices/desktop-linux.svg',
    'apple': '/images/devices/desktop-apple.svg',
  },
  'tablet': {
    'unknown': '/images/devices/tablet.svg',
    'apple': '/images/devices/tablet-apple.svg',
    'android': '/images/devices/tablet-android.svg',
  },
  'phone': {
    'unknown': '/images/devices/phone.svg',
    'apple': '/images/devices/phone-apple.svg',
    'android': '/images/devices/phone-android.svg',
    'linux': '/images/devices/phone-linux.svg',
    'windows': '/images/devices/phone-windows.svg',
  }
};

export const pointColors = [
  { value: 20, color: '#C6CBCD' },
  { value: 50, color: '#A7E2FC' },
  { value: 70, color: '#FFEE94' },
  { value: 90, color: '#FFA88C' },
  { value: 100, color: '#79BE70' },
];

export const usersColors = [
  '#AB9068', '#B2B2B2', '#FFEE94', '#F4CC42', '#FFD78A', '#CED147', '#ACD781', '#97E772', '#8ED7B9', '#45DABF',
  '#49C6B7', '#5EE1F3', '#C8D4FF', '#94B5D4', '#A2B7FF', '#8987E8', '#CB7FEE', '#FFA0E4', '#D4A5F9', '#F780AB',
];

export const adminRoles = [ 'COMMON_ADMIN', 'TEST_ADMIN', 'CREATOR' ];