import classNames from 'classnames';

import styles from './menu-button.module.css';

const MenuButton = ({ isOpen, onClick }) => {
  return (
    <button className={classNames(styles.button, isOpen && styles.active)} onClick={onClick}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </button>
  );
};

export default MenuButton;