import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import SectionsList from 'components/variables/SectionsList';

import {
  useGetStrictSectionsQuery,
  useGetStrictFoldersQuery,
  useGetStrictVariablesQuery,
  useAddStrictSectionMutation,
  useUpdateStrictSectionMutation,
  useRemoveStrictSectionMutation,
  useAddStrictFolderMutation,
  useUpdateStrictFolderMutation
} from 'store/variables/variablesApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Sections = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: sections } = useGetStrictSectionsQuery();
  const { data: folders } = useGetStrictFoldersQuery();
  const { data: variables } = useGetStrictVariablesQuery();
  const [ addSection ] = useAddStrictSectionMutation();
  const [ updateSection ] = useUpdateStrictSectionMutation();
  const [ removeSection ] = useRemoveStrictSectionMutation();
  const [ addGroup ] = useAddStrictFolderMutation();
  const [ updateGroup ] = useUpdateStrictFolderMutation();

  const handleSaveSection = async (values) => {
    const res = await updateSection(values);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleAddSection = async (values) => {
    const res = await addSection(values);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleRemoveSection = async (id) => {
    const res = await removeSection(id);
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
    }
  };

  const handleAddGroup = async (values) => {
    const res = await addGroup(values);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleSaveGroup = async (values) => {
    const res = await updateGroup(values);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('variables_sections')}>
      <PageTitle>{t('site_name') + ' – ' + t('variables_sections')}</PageTitle>
      <SectionsList
        sections={sections}
        folders={folders}
        variables={variables}
        onAdd={handleAddSection}
        onSave={handleSaveSection}
        onRemove={handleRemoveSection}
        onAddGroup={handleAddGroup}
        onSaveGroup={handleSaveGroup}
      />
    </AdminLayout>
  )
};

export default Sections;