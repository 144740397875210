import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import {
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  PointerSensor
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { Button, UNSORTED_ID, nanoid } from '@forma/forma-ui-kit';
import { VariablesGroup } from '../Variables';
import VariablesItem from '../Variables/VariablesItem';

import styles from './side-block-groups.module.css';

const SideBlockGroups = ({ folders, onMove, onChange, ...props }) => {
  const { t } = useTranslation();
  const [ dragType, setDragType ] = useState(null);
  const [ dragOverIndex, setDragOverIndex ] = useState(null);
  const [ dragFolderIndex, setDragFolderIndex ] = useState(null);
  const [ dragVarIndex, setDragVarIndex ] = useState(null);

  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 3,
    },
  }));

  if (!folders) return null;

  const handleDragStart = ({ active }) => {
    if (active.data.current?.type === 'GROUP') {
      setDragFolderIndex(active.data.current?.index);
      setDragType('GROUP');
    } else {
      setDragVarIndex(active.data.current?.index);
      const folderIndex = folders.findIndex(({ id }) => id === active.data.current?.folderId);
      setDragFolderIndex(folderIndex);
      setDragType('VAR');
    }
  };

  const changeTattrFolder = ({ active, over }) => {
    if (!over) return null;

    const activeFolderId = active.data.current?.folderId;
    const activeIndex = active.data.current?.index;
    const overFolderId = over.data.current?.folderId;
    const overIndex = over.data.current?.index;

    const activeFolderIndex = folders.findIndex(({ id }) => id === activeFolderId);
    const overFolderIndex = folders.findIndex(({ id }) => id === overFolderId);

    const isBelowOverItem =
      active.rect.current.translated &&
      active.rect.current.translated.top > over.rect.top + over.rect.height;

    const modifier = isBelowOverItem ? 1 : 0;
    const newIndex = overIndex >= 0 ? overIndex + modifier : folders[overFolderIndex].tattrs.length + 1;

    return update(folders, {
      [activeFolderIndex]: {
        tattrs: {
          $splice: [[ activeIndex, 1 ]]
        }
      },
      [overFolderIndex]: {
        tattrs: {
          $splice: [[newIndex, 0, folders[activeFolderIndex].tattrs[activeIndex] ]]
        }
      },
    });
  };

  const handleDragEnd = ({ active, over }) => {
    setDragFolderIndex(null);
    setDragVarIndex(null);
    setDragOverIndex(null);
    setDragType(null);

    const activeFolderId = active.data.current?.folderId;
    const activeIndex = active.data.current?.index;
    const overFolderId = over?.data.current?.folderId;
    const overIndex = over?.data.current?.index;

    let next = null;

    if (activeFolderId && overFolderId) {
      if (activeFolderId !== overFolderId) {
        // console.log('move var between folders')
        next = changeTattrFolder({ active, over });
      } else if (activeIndex !== overIndex) {
        // console.log('move var inside single folder')
        const activeFolderIndex = folders.findIndex(({ id }) => id === activeFolderId);
        next = update(folders, {
          [activeFolderIndex]: {
            tattrs: {
              $splice: [
                [ activeIndex, 1 ],
                [ overIndex, 0, folders[activeFolderIndex].tattrs[activeIndex]]
              ]
            }
          }
        });
      }
    } else if (activeFolderId && !overFolderId && over) {
      // console.log('push var into folder')
      const activeFolderIndex = folders.findIndex(({ id }) => id === activeFolderId);
      if (activeFolderIndex !== over.data.current?.index) {
        next = update(folders, {
          [activeFolderIndex]: {
            tattrs: {
              $splice: [[ activeIndex, 1 ]]
            }
          },
          [over.data.current?.index]: {
            tattrs: {
              $splice: [[over.data.current?.index, 0, folders[activeFolderIndex].tattrs[activeIndex] ]]
            }
          },
        });
      }
    } else if (!activeFolderId && !overFolderId) {
      // console.log('move folder')
      next = update(folders, {
        $splice: [
          [activeIndex, 1],
          [overIndex, 0, folders[activeIndex]]
        ]
      });
    }

    if (next) onChange(next);
  };

  const handleDragOver = ({ active, over }) => {
    const activeFolderId = active.data.current?.folderId;
    const overFolderId = over?.data.current?.folderId;

    const overFolderIndex = overFolderId ? folders?.findIndex(({ id }) => id === overFolderId) : over?.data.current?.index;
    setDragOverIndex(overFolderIndex);

    if (!activeFolderId || !overFolderId || activeFolderId === overFolderId) return;

    const next = changeTattrFolder({ active, over });
    if (next) onChange(next);

    // update hovering index for drag overlay
    setDragFolderIndex(overFolderIndex);
    setDragVarIndex(over?.data.current?.index);
  };

  const handleRemoveGroup = (id) => {
    const folderIndex = folders.findIndex(folder => id === folder.id);
    onChange(update(folders, { $splice: [[ folderIndex, 1 ]] }));
  };

  const handleChangeGroup = (data) => {
    const folderIndex = folders.findIndex(folder => data.id === folder.id);
    onChange(update(folders, { [folderIndex]: { $set: data } }));
  };

  const handleClickAdd = () => {
    if (!folders.find(({ name }) => !name.length)) onChange(update(folders, { $push: [{ id: nanoid(), name: '', tattrs: [] }] }));
  };

  return (
    <div className={styles.root}>
      {folders && (
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          sensors={sensors}
        >
          {folders.map(({ id, name, tattrs }, index) => {
            if (id !== UNSORTED_ID || !tattrs?.length) return null;
            return (
              <VariablesGroup
                id={id}
                name={name}
                index={index}
                tattrs={tattrs}
                onChange={handleChangeGroup}
                {...props}
                key={id}
              />
            );
          })}
          <SortableContext items={folders}>
            <div className={styles.groups}>
              {folders.map(({ id, name, tattrs }, index) => {
                if (id === UNSORTED_ID) return null;
                return (
                  <VariablesGroup
                    id={id}
                    name={name}
                    index={index}
                    isOver={(dragType === 'VAR' && dragOverIndex === index)}
                    opacity={(dragType === 'GROUP' && dragFolderIndex === index) ? 0 : 1}
                    draggingVariableIndex={(dragType === 'VAR' && dragFolderIndex === index) ? dragVarIndex : null}
                    tattrs={tattrs}
                    onRemove={handleRemoveGroup}
                    onChange={handleChangeGroup}
                    isDraggable
                    {...props}
                    key={id}
                  />
                );
              })}
            </div>
          </SortableContext>
          {(dragType === 'GROUP' && (dragFolderIndex || dragFolderIndex === 0)) && (
            <DragOverlay zIndex={10} modifiers={[ restrictToFirstScrollableAncestor ]}>
              <VariablesGroup
                id={folders[dragFolderIndex].id}
                index={dragFolderIndex}
                name={folders[dragFolderIndex].name}
                tattrs={folders[dragFolderIndex].tattrs}
                isDragging
                isDraggable
                {...props}
              />
            </DragOverlay>
          )}
          {(dragType === 'VAR' && ((dragVarIndex || dragVarIndex === 0) && (dragFolderIndex || dragFolderIndex === 0))) && (
            <DragOverlay zIndex={10} modifiers={[ restrictToFirstScrollableAncestor ]}>
              <VariablesItem
                {...folders[dragFolderIndex].tattrs[dragVarIndex]}
                folderId={folders[dragFolderIndex].id}
                index={dragVarIndex}
                isDragging
              />
            </DragOverlay>
          )}
        </DndContext>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={handleClickAdd}
          className={styles.buttonAdd}
          iconClassName={styles.buttonAddIcon}
          viewStyle="secondary"
          size="medium"
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          fullWidth
          shadow
        >
          {t('create_group')}
        </Button>
      </div>
    </div>
  );
};

export default SideBlockGroups;
