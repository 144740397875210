import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import { checkIsActive } from '../SideMenu';

import styles from '../side-menu.module.css';

const Submenu = ({ isMenuOpen, items, isShowChilds, userPermissions, currentPathname, onClick }) => {
  const { t } = useTranslation();

  return (
    <ul className={classNames(styles.childs, isShowChilds && styles.open)}>
      {items && items.map(({ id, icon, href, routes, lang, disabled, permissions }) => {
        const isAllow = (userPermissions && permissions) ? !!userPermissions.find(pName => pName === permissions) : true;
        if (!isAllow) return null;
        const isActive = checkIsActive(currentPathname, href, routes);

        return (
          <li className={styles.childItem} key={id}>
            <Popup
              on={!isMenuOpen ? [ 'hover', 'focus' ] : []}
              className={classNames(styles.tooltip, 'tooltip')}
              trigger={
                <Link className={classNames(styles.childLink, isActive && styles.active, disabled && styles.disabled)} to={href} onClick={onClick}>
                  {(icon && !isMenuOpen) && <ReactSVG src={icon} className={styles.childIcon} wrapper="span" />}
                  {t(lang)}
                </Link>
              }
              position={['right center']}
              offsetX={18}
              offsetY={0}
              arrow={false}
            >
              {t(lang)}
            </Popup>
          </li>
        );
      })}
    </ul>
  );
};

export default Submenu;