import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Table, TableCell, TableRow } from 'components/Table';
import { Modal, Button, RemoveModal } from '@forma/forma-ui-kit';
import TariffEdit from './TariffEdit';
import PatternListItem from './PatternListItem';
import PatternEdit from './PatternEdit';

import styles from './tariffs-list.module.css';

const TariffsListItem = ({ item, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isEditOpen, setEditOpen ] = useState(false);
  const [ isChildsOpen, setChildsOpen ] = useState(false);
  const [ isAddOpen, setAddOpen ] = useState(false);

  const { id, name, canAddUsers, usersLimit, patterns } = item;

  return (
    <>
      <TableRow>
        <TableCell className={styles.id} onClick={() => setChildsOpen(!isChildsOpen)}>
          {id}
          <ReactSVG src="/icons/dropdown.svg" className={classNames(styles.nameArrow, isChildsOpen && styles.open)} wrapper="span" />
        </TableCell>
        <TableCell className={styles.name} onClick={() => setChildsOpen(!isChildsOpen)}>
          {name}
        </TableCell>
        <TableCell className={styles.users} onClick={() => setChildsOpen(!isChildsOpen)}>
          {usersLimit} {canAddUsers && <ReactSVG src="/icons/checked.svg" wrapper="span" title={t('can_add_users')} />}
        </TableCell>
        <TableCell className={styles.buttons}>
          <Button viewStyle="text" className={styles.remove} title={t('edit')} onClick={() => setEditOpen(true)}>
            <ReactSVG src="/icons/edit.svg" />
          </Button>
          <Modal
            open={isEditOpen}
            onClose={() => setEditOpen(false)}
            title={t('edit_tariff')}
          >
            <TariffEdit
              data={item}
              onCancel={() => setEditOpen(false)}
              onSave={data => { onSave({ ...data, patterns }); setEditOpen(false); }}
            />
          </Modal>

          <RemoveModal
            control={
              <Button viewStyle="text" className={styles.remove} title={t('delete')}>
                <ReactSVG src="/icons/trash.svg" />
              </Button>
            }
            title={t('delete_tariff')}
            onRemove={() => onRemove(id)}
            itemName={name}
          />
        </TableCell>
      </TableRow>
      {isChildsOpen &&
        <TableRow className={styles.subitems}>
          <TableCell colSpan={4} className={styles.subitemsCell}>
            <Table columns={[
              { className: styles.subitemsHeadCell, children: t('id') },
              { className: styles.subitemsHeadCell, children: t('name') },
              { className: styles.subitemsHeadCell, children: t('duration') },
              { className: styles.subitemsHeadCell, children: <>{t('price')} / {t('discount')}</> },
              { className: styles.subitemsHeadCell, children: '' }
            ]}>
              {patterns?.map((pattern, index) => (
                <PatternListItem
                  canAddUsers={canAddUsers}
                  item={pattern}
                  onSave={data => onSave({ ...item, patterns: [ ...patterns.filter(({ id }) => id !== data.id), data ] })}
                  onRemove={id => onSave({ ...item, patterns: patterns.filter(({ id: pId }) => pId !== id) })}
                  key={index}
                />
              ))}
              <TableRow className={styles.subitemsButtons}>
                <TableCell colSpan={5}>
                  <Button
                    className={styles.addButton}
                    viewStyle="primary"
                    size="extraSmall"
                    onClick={() => setAddOpen(true)}
                  >
                    {t('add_duration')}
                  </Button>
                  <Modal
                    open={isAddOpen}
                    onClose={() => setAddOpen(false)}
                    title={t('add_duration')}
                  >
                    <PatternEdit
                      canAddUsers={canAddUsers}
                      onCancel={() => setAddOpen(false)}
                      onAdd={data => { onSave({ ...item, patterns: [ ...patterns, data ] }); setAddOpen(false); }}
                      isAdd
                    />
                  </Modal>
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
        </TableRow>
      }
    </>
  );
};

export default TariffsListItem;
