import classNames from 'classnames';
import LoadingIndicator from 'components/LoadingIndicator';
import PaymentsToday from './PaymentsToday';
import PaymentsWeek from './PaymentsWeek';
import PaymentsMonth from './PaymentsMonth';
import PaymentsConversion from './PaymentsConversion';
import PaymentsProlongation from './PaymentsProlongation';
import PaymentsStatistic from './PaymentsStatistic';

import styles from './dashboard-payments.module.css';

const DashboardPayments = ({ isLoading, payments, conversion, prolongations, paymentsDates }) => {
  if (isLoading) return (
    <div style={{ margin: '80px 0' }}><LoadingIndicator /></div>
  );

  return (
    <div className={styles.root}>
      <div className={classNames(styles.column, styles.conversion)}>
        <PaymentsConversion {...conversion} />
      </div>
      <div className={classNames(styles.column, styles.week)}>
        <PaymentsWeek {...payments.week} />
      </div>
      <div className={classNames(styles.column, styles.month)}>
        <PaymentsMonth {...payments.month} />
      </div>
      <div className={classNames(styles.column, styles.total)}>
        <PaymentsToday {...payments.day} />
      </div>
      <div className={classNames(styles.column, styles.prolongation)}>
        <PaymentsProlongation {...prolongations} />
      </div>
      <div className={classNames(styles.column, styles.statistic)}>
        <PaymentsStatistic data={paymentsDates} />
      </div>
    </div>
  );
};

export default DashboardPayments;
