import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';
import ContextMenu from 'components/ContextMenu';
import UserIcon from '../UserIcon';
import TaskEditModal from '../TaskEditModal';
import PriorityIcon from '../PriorityIcon';

import styles from './kanban-card.module.css';
import { ReactSVG } from 'react-svg';

const KanbanCard = ({ admins, positions, data, onChange }) => {
  const { t } = useTranslation();
  const { id, description, creator, manager, type, priority, duration, durationInMinutes, durationType, status, filename } = data;
  const [ isModalOpen, setModalOpen ] = useState(false);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({ id, disabled: isModalOpen });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0 : 1
  };

  const handleClickCard = (e) => {
    if (e.target.classList.contains(styles.user) || e.target.closest('.' + styles.user)) {}
    else if (e.target.classList.contains(styles.root) || e.target.closest('.' + styles.root)) setModalOpen(true);
  };

  return (
    <div
      className={classNames(styles.root, status !== 'READY' && durationInMinutes  >= 4320 && styles.error, status === 'RETURN_TO_WORK' && styles.danger)}
      onClick={handleClickCard}
      {...attributes}
      {...listeners}
      style={style}
      ref={setNodeRef}
    >
      <div className={styles.content}>
        {(durationInMinutes > 4320 || status === 'RETURN_TO_WORK') && (
          <div className={styles.labelWrapper}>
            {durationInMinutes > 4320 && status !== 'READY' && <span className={classNames(styles.label, styles.error)}>{t('very_long_time')}</span>}
            {status === 'RETURN_TO_WORK' && <span className={classNames(styles.label, styles.danger)}>{t('to_correct')}</span>}
          </div>
        )}
        <div className={styles.title}>
          {type === 'SHAREDTEMPLATE' ? (
            <>
              <img src="/images/small_logo.svg" className={styles.titleIcon} width={17} height={17} alt={t('forma_template')} />
              {t('forma_template')}
            </>
          ) : (
            creator.login
          )}
        </div>
        <div className={styles.description}>{filename || description}</div>
        <div className={classNames(styles.time, styles[status === 'READY' ? 'DURATION_NORMAL' : durationType])}>
          {duration}
        </div>
      </div>
      <div className={styles.controls}>
        <div className={styles.status}>
          {status === 'READY' ? (
            <ReactSVG src="/icons/tick.svg" wrapper="span" style={{ color: 'var(--primary-color)' }} />
          ) : (
            <PriorityIcon priority={Number(priority)} />
          )}
        </div>
        <ContextMenu
          control={<div className={styles.user}><UserIcon name={manager.name || manager.login} className={styles.userIcon} color={manager.color} /></div>}
          items={
            admins?.map(({ name, login, color }) => ({
              text: <div className={styles.option}>
                <UserIcon
                  name={name || login}
                  color={color}
                  className={styles.optionIcon}
                /> {name || login}
              </div>,
              onClick: () => onChange({ id, managerlogin: login })
            }))
          }
        />
      </div>
      <TaskEditModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        admins={admins}
        positions={positions}
        id={data.id}
      />
    </div>
  );
};

export default KanbanCard;
