import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { Button } from '@forma/forma-ui-kit';
import Dashboard from 'components/Dashboard';
import { useGetAnalyticsQuery } from 'store/analytics/analyticsApi';

import styles from './Main.module.css';

const Main = () => {
  const { t } = useTranslation();
  const { data: analytics, refetch, isFetching } = useGetAnalyticsQuery();

  return (
    <AdminLayout
      title={t('dashboard')}
      titleButtons={
        <>
          <div className={styles.status}>Данные актуальны на <br />{analytics?.lastUpdate && format(new Date(analytics.lastUpdate), 'dd.MM.yyyy г., HH:mm')}</div>
          <Button
            viewStyle="secondary"
            icon={<ReactSVG src="/icons/reload.svg" wrapper="span" />}
            onClick={refetch}
            shadow
          >
            Обновить аналитику
          </Button>
        </>
      }
    >
      <PageTitle>{t('site_name') + ' – ' + t('dashboard')}</PageTitle>

      <Dashboard isLoading={isFetching} {...analytics} />
    </AdminLayout>
  );
};

export default Main;