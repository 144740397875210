import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Modal } from '@forma/forma-ui-kit';

import styles from './workspace-users-modal.module.css';

const WorkspaceUsersModal = ({ open, onClose, users = [], selectedUserId, onSelectUser }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={styles.items}>
        <div
          className={classNames(styles.item, !selectedUserId && styles.active)}
          onClick={() => { onSelectUser(null); onClose(); }}
        >
          <div className={styles.left}>
            <div className={styles.title}>Все аккаунты</div>
          </div>
          <div className={styles.right}>
            <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} />
          </div>
        </div>
        {users.map(({ id, name, login }) => (
          <div
            className={classNames(styles.item, selectedUserId === id && styles.active)}
            onClick={() => { onSelectUser(id); onClose(); }}
            key={login}
          >
            <div className={styles.left}>
              <div className={styles.title}>{login}</div>
              <div className={styles.name}>{name}</div>
              <div className={styles.info}>-</div>
            </div>
            <div className={styles.right}>
              <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkbox} />
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default WorkspaceUsersModal;
