import { baseApi } from '../store';

export const productsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Products', 'Product', 'ProductsCategories'] }).injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query({
      query: data => ({
        url: '/products',
        method: 'GET',
        params: data
      }),
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation({
      query: data => ({
        url: '/products',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    importProducts: builder.mutation({
      query: data => ({
        url: '/products/batch',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    getProductById: builder.query({
      query: id => ({
        url: `/products/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Product', id: id }],
    }),
    saveProduct: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'Product', id: data.id }, 'Products', 'ProductsCategories'],
    }),
    removeProduct: builder.mutation({
      query: id => ({
        url: `/products/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Products'],
    }),

    getProductsCategories: builder.query({
      query: () => ({
        url: '/products/categories',
        method: 'GET'
      }),
      providesTags: ['ProductsCategories'],
    }),
    addProductsCategory: builder.mutation({
      query: data => ({
        url: `/products/categories`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    updateProductsCategory: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/products/categories/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    removeProductsCategory: builder.mutation({
      query: id => ({
        url: `/products/categories/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
  })
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useImportProductsMutation,
  useGetProductByIdQuery,
  useSaveProductMutation,
  useRemoveProductMutation,
  useGetProductsCategoriesQuery,
  useAddProductsCategoryMutation,
  useUpdateProductsCategoryMutation,
  useRemoveProductsCategoryMutation
} = productsApi;
