import DashboardBlock from 'components/Dashboard/DashboardBlock';
import { formatPrice } from 'helpers/formatValues';
import { currencies } from 'data/mock';

import styles from './payments-today.module.css';

const PaymentsToday = ({ count, amount, card, invoices }) => (
  <DashboardBlock
    title="Всего сегодня"
  >
    <div className={styles.root}>
      <div className={styles.item}>
        <div className={styles.itemValue}>{count}</div>
        <div className={styles.itemText}>
          оплат на <span className={styles.success}>{formatPrice(amount, currencies.ru)}</span> {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
        </div>
      </div>
      <div className={styles.text}>Из них:</div>
      <div className={styles.row}>
        <div className={styles.itemSimple}>
          <div className={styles.itemValueSimple}>{invoices.count}</div>
          <div className={styles.itemTextSimple}>
            оплат <span className={styles.success}>по счёту</span><br />
            на {formatPrice(invoices.amount, currencies.ru)}
          </div>
        </div>
        <div className={styles.itemSimple}>
          <div className={styles.itemValueSimple}>{card.count}</div>
          <div className={styles.itemTextSimple}>
            оплат <span className={styles.success}>по карте</span><br />
            на {formatPrice(card.amount, currencies.ru)}
          </div>
        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default PaymentsToday;
