import classNames from 'classnames';
import LoadingIndicator from 'components/LoadingIndicator';
import DashboardUsers from './DashboardUsers';
import DashboardScoring from './DashboardScoring';
import DashboardBill from './DashboardBill';
import DashboardRequests from './DashboardRequests';
import DashboardTemplates from './DashboardTemplates';
import DashboardLTV from './DashboardLTV';
import DashboardTariff from './DashboardTariff';
import DashboardDAU from './DashboardDAU';
import DashboardDealTime from './DashboardDealTime';
import DashboardStatistic from './DashboardStatistic';
import DashboardDocs from './DashboardDocs';
import DashboardPlatforms from './DashboardPlatforms';

import styles from './dashboard.module.css';

const Dashboard = ({
  isLoading, averageScore, registers, rendersCreated, sharedTemplates, templatesCreated, tcRequests, dau, ltv,
  averagePayment, conversion, crm, leadDuration, payments, topBrowsers, topOs, topResolution, topTariff
}) => {
  if (!averageScore || isLoading) return (
    <div style={{ margin: '80px 0' }}><LoadingIndicator /></div>
  );

  return (
    <div className={styles.root}>
      <div className={classNames(styles.column, styles.users)}>
        <DashboardUsers registers={registers} payments={payments} conversion={conversion} />
      </div>
      <div className={classNames(styles.column, styles.scoring)}>
        <DashboardScoring {...averageScore} />
      </div>
      <div className={classNames(styles.column, styles.bill)}>
        <DashboardBill {...averagePayment} />
      </div>
      <div className={classNames(styles.column, styles.requests)}>
        <DashboardRequests {...tcRequests} />
      </div>
      <div className={classNames(styles.column, styles.templates)}>
        <DashboardTemplates {...sharedTemplates} />
      </div>
      <div className={classNames(styles.column, styles.ltv)}>
        <DashboardLTV {...ltv} />
      </div>
      <div className={classNames(styles.column, styles.tariff)}>
        <DashboardTariff {...topTariff} />
      </div>
      <div className={classNames(styles.column, styles.dau)}>
        <DashboardDAU value={dau} />
      </div>
      <div className={classNames(styles.column, styles.dealTime)}>
        <DashboardDealTime value={leadDuration} />
      </div>
      <div className={classNames(styles.column)}>
        <DashboardStatistic items={crm} />
      </div>
      <div className={classNames(styles.column)}>
        <DashboardDocs {...rendersCreated} />
      </div>
      <div className={classNames(styles.column)}>
        <DashboardPlatforms os={topOs} browsers={topBrowsers} resolutions={topResolution} />
      </div>
    </div>
  );
};

export default Dashboard;
