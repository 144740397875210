import { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '@forma/forma-ui-kit';

import styles from './table.module.css';

const TableRow = ({ id, className, isActive, isSelected, children }) => {
  return (
    <tr id={id} className={classNames(styles.row, className, isActive && styles.active, isSelected && styles.selected)}>
      {children}
    </tr>
  );
};

const TableCell = ({ className, title, children, colSpan, onClick }) => {
  const { viewport } = useContext(ThemeContext);
  return (
    <td className={classNames(styles.cell, className)} onClick={onClick} colSpan={colSpan}>
      {(viewport === 'phone' && title) &&
        <div className={styles.columnTitle}>{title}</div>
      }
      {children}
    </td>
  );
};

const Table = ({ columns, className, children }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <table className={styles.table}>
        {columns && (
          <thead>
            <tr className={styles.head}>
              {columns.map((item, index) =>
                <th className={classNames(styles.headCell, item?.className)} key={index}>{item?.children}</th>
              )}
            </tr>
          </thead>
        )}
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  );
};

export { Table, TableRow, TableCell };