import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';

import {
  useDownloadTcRequestsFileMutation
} from 'store/tcRequests/tcRequestsApi';

const FileDownload = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [ downloadFile ] = useDownloadTcRequestsFileMutation();

  const downloadTemplate = (id) => {
    downloadFile(id).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('tcrequest_file')} ${id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  useEffect(() => {
    downloadTemplate(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <AdminLayout title={t('tcrequests')}>
      <PageTitle>{t('site_name') + ' – ' + t('tcrequests')}</PageTitle>
      <h1 style={{ textAlign: 'center' }}>{t('file_downloads')}</h1>
      <h3 style={{ textAlign: 'center' }}>{t('if_download_not_started')}</h3>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => downloadTemplate(id)}>{t('download_again')}</Button>
      </div>
    </AdminLayout>
  )
};

export default FileDownload;