import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-templates.module.css';

const DashboardTemplates = ({ lastWeek, total }) => (
  <DashboardBlock
    title="Собственных шаблонов"
  >
    <div className={styles.root}>
      <div className={styles.value}>{total}</div>
      {!!lastWeek && (
        <div className={styles.text}>+{lastWeek} за последние 7 дней</div>
      )}
    </div>
  </DashboardBlock>
);

export default DashboardTemplates;
