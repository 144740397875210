import { baseApi } from 'store/store';

export const tasksApi = baseApi.enhanceEndpoints({ addTagTypes: 'Tasks' }).injectEndpoints({
  endpoints: builder => ({
    getTasks: builder.query({
      query: () => ({
        url: '/tasks/',
        method: 'GET',
      }),
      providesTags: [{ type: 'Tasks', id: 'All' }]
    }),
    getTasksByPositions: builder.query({
      query: data => ({
        url: '/tasks/positions',
        method: 'GET',
        params: data
      }),
      providesTags: [{ type: 'Tasks', id: 'Positions' }]
    }),
    createTask: builder.mutation({
      query: data => ({
        url: '/tasks',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [{ type: 'Tasks' }]
    }),
    getTaskById: builder.query({
      query: id => ({
        url: `/tasks/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Tasks', id }]
    }),
    getTaskFile: builder.mutation({
      query: id => ({
        url: `/tasks/${id}/download`,
        method: 'GET',
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
    }),
    updateTask: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/tasks/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Tasks' }]
    }),
    removeTask: builder.mutation({
      query: id => ({
        url: `/tasks/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Tasks' }]
    }),
    addTaskComment: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/tasks/${id}/comment`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Tasks', id }]
    }),
  })
});

export const {
  useGetTasksQuery,
  useGetTasksByPositionsQuery,
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useGetTaskFileMutation,
  useRemoveTaskMutation,
  useAddTaskCommentMutation
} = tasksApi;
