import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logoutUser } from './auth/authSlice';
import { setUserData } from './user/userSlice';
import { setNotification } from './notifications/notificationsSlice';
import { baseApi } from './store';
import i18n from '../i18n';

const API_URL = process.env.REACT_APP_API_URL;

const query = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    const { language } = getState().user;

    if (token) headers.set('authorization', `Bearer ${token}`);
    if (language) headers.set('X-Language', `${language}`);

    return headers;
  },
});

const baseQuery = async (args, api, extraOptions) => {
  const { t } = i18n;
  const result = await query(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(logoutUser());
      api.dispatch(setUserData(null));
      api.dispatch(baseApi.util.resetApiState());
    } else if (result.error.status === 'FETCH_ERROR') {
      api.dispatch(setNotification(t('errors.fetch_error'), 'ERROR'));
    }

    result.error.data?.error?.forEach(text => {
      api.dispatch(setNotification(text, 'ERROR'))
    });
  }

  return result;
}

export default baseQuery;
