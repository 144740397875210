import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation } from 'react-router-dom';
import { ThemeContext, Notifications, useViewport, useScrollToTop } from '@forma/forma-ui-kit';
import { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale';
import ProtectedRoute from './pages/ProtectedRoute';
import MainQuery from './pages/MainQuery';

import { selectToken } from 'store/auth/authSlice';
import { setUserLang, selectUserLang, selectAdminData } from 'store/user/userSlice';
import { removeNotification, selectNotifications } from 'store/notifications/notificationsSlice';
import { variablesApi } from 'store/variables/variablesApi';
import { schemasApi } from 'store/schemas/schemasApi';
import { selectMenuOpen } from 'store/common/commonSlice';

import { routes } from 'data/routes';
import { currencies } from 'data/mock';

const apiUrl = process.env.REACT_APP_API_URL;

registerLocale('ru', ru);

const RouteComponent = ({ isLogged, children }) => {
  const { pathname, hash, key } = useLocation();

  useScrollToTop(pathname, hash, key);

  return children;
};

const App = () => {
  const dispatch = useDispatch();
  const viewport = useViewport();
  const admin = useSelector(selectAdminData);
  const token = useSelector(selectToken);
  const isLogged = !!token;
  const notifications = useSelector(selectNotifications);
  const language = useSelector(selectUserLang);
  const isMenuOpen = useSelector(selectMenuOpen);
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleMouseUp = (e) => {
      if (e.target?.tagName === 'A' || e.target?.tagName === 'BUTTON') e.target.blur();
      else if (e.target.closest('a')) e.target.closest('a').blur();
      else if (e.target.closest('button')) e.target.closest('button').blur();
    };
    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const theme = {
    viewport,
    isMenuOpen,
    lang: i18n.resolvedLanguage ?? i18n.language,
    apiUrl: apiUrl ?? '',
    token,
    currencies
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      routes.map(props =>
        <Route
          key={props.path}
          {...props}
          element={
            <ProtectedRoute
              isAllowed={(props.logged && isLogged && props.roles.includes(admin?.role)) || (!props.logged && !isLogged) || props.skip_auth}
              // isAllowed={props.skip_auth || (!isLogged && !props.roles) || (admin && props.roles.includes(admin.role))}
              redirectPath={(isLogged && admin?.role) ? props.loggedRedirect : props.redirect}
            >
              <RouteComponent isLogged={isLogged}>
                {props.element}
              </RouteComponent>
            </ProtectedRoute>
          }
        />
      )
    )
  );

  useEffect(() => {
    if (i18n.resolvedLanguage !== language) {
      dispatch(setUserLang(i18n.resolvedLanguage));
      dispatch(variablesApi.util.resetApiState(), schemasApi.util.resetApiState());
    }
    // eslint-disable-next-line
  }, [i18n.resolvedLanguage]);

  return (
    <ThemeContext.Provider value={theme}>
      {i18n.resolvedLanguage &&
        <MainQuery isLogged={isLogged} />
      }
      <Notifications items={notifications} onRemove={id => dispatch(removeNotification(id))} />
      {(!isLogged || admin) && (
        <RouterProvider router={router} />
      )}
    </ThemeContext.Provider>
  );
};

export default App;
