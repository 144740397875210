import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './dropdown.module.css';

const Dropdown = ({ open = false, onToggle, id, title, children, viewStyle, size, className }) => {
  const [ isOpen, setIsOpen ] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    // eslint-disable-next-line;
  }, [open]);

  const handleClose = () => {
    if (onToggle) onToggle(!isOpen);
    else setIsOpen(!isOpen);
  };

  return (
    <div className={classNames(styles.root, viewStyle && styles[viewStyle], size && styles[size], className)} id={id}>
      <div className={styles.header} onClick={handleClose}>
        <span className={styles.title}>{title}</span>
        <ReactSVG src="/icons/dropdown_thin.svg" className={classNames(styles.arrow, isOpen && styles.open)} />
      </div>
      {isOpen &&
        <div className={styles.content}>{children}</div>
      }
    </div>
  );
};

export default Dropdown;
