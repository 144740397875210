import { useDragLayer } from 'react-dnd';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

const getItemStyles = (
  initialCursorOffset,
  initialOffset,
  currentOffset,
  width,
  height
) => {
  if (!initialOffset || !currentOffset || !initialCursorOffset) return { display: 'none' };
  const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;

  return {
    transform,
    WebkitTransform: transform,
    height: height + 'px',
    width: width + 'px',
  };
};

const DragLayer = ({ className, width, height, children }) => {
  const {
    isDragging,
    initialCursorOffset,
    initialFileOffset,
    currentFileOffset,
  } = useDragLayer((monitor) => ({
    item: null,
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) return null;

  return (
    <div className={className} style={layerStyles}>
      <div style={{ ...getItemStyles(initialCursorOffset, initialFileOffset, currentFileOffset, width, height), borderRadius: 'var(--border-radius-l)' }}>
        {children}
      </div>
    </div>
  );
};

export default DragLayer;
