import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-templates.module.css';

const WorkspaceTemplates = ({ packs, renders, rendersPacks, templates }) => (
  <WorkspaceBlock title="Создано за сегодня">
    <div className={styles.items}>
      <div className={styles.item}>
        <div className={styles.itemLabel}>Шаблонов:</div>
        <div className={styles.itemValue}>{templates}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemLabel}>Пакетов шаблонов:</div>
        <div className={styles.itemValue}>{packs}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemLabel}>Документов:</div>
        <div className={styles.itemValue}>{renders}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemLabel}>Комплектов документов:</div>
        <div className={styles.itemValue}>{rendersPacks}</div>
      </div>
    </div>
  </WorkspaceBlock>
);

export default WorkspaceTemplates;
