import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import PromocodesList from 'components/PromocodesList';

import {
  useAddPromocodeMutation,
  useDeletePromocodeMutation,
  useGetPromocodesQuery,
  useUpdatePromocodeMutation
} from 'store/promocodes/promocodesApi';
import { useGetTariffsQuery } from 'store/tariffs/tariffsApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Promocodes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: promocodes } = useGetPromocodesQuery();
  const { data: tariffs } = useGetTariffsQuery();
  const [ addPromocode ] = useAddPromocodeMutation();
  const [ updatePromocode ] = useUpdatePromocodeMutation();
  const [ removePromocode ] = useDeletePromocodeMutation();

  const handleSavePromo = async (data) => {
    const res = await updatePromocode(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleAddPromo = async (data) => {
    const res = await addPromocode(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleRemovePromo = async (id) => {
    const res = await removePromocode(id);
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('tariffs')}>
      <PageTitle>{t('site_name') + ' – ' + t('tariffs')}</PageTitle>
      <PromocodesList
        items={promocodes}
        tariffs={tariffs}
        onAdd={handleAddPromo}
        onSave={handleSavePromo}
        onRemove={handleRemovePromo}
      />
    </AdminLayout>
  )
};

export default Promocodes;