import { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { ThemeContext } from '@forma/forma-ui-kit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import WorkspaceUsersModal from '../WorkspaceUsersModal';
import { pointColors } from 'data/mock';

import styles from './workspace-header.module.css';

const WorkspaceHeader = ({
  id, users, points, featured, onClickSignAs, selectedUserId, onSelectUser, onAddFavourite, onRemoveFavourite
}) => {
  const { t } = useTranslation();
  const [ isOpenUsers, setOpenUsers ] = useState(false);
  const pColor = pointColors?.find((item) => item.value >= points);
  const { viewport } = useContext(ThemeContext);

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Button
          className={styles.select}
          viewStyle="secondary"
          iconClassName={styles.selectIcon}
          icon={<ReactSVG src={'/icons/dropdown.svg'} wrapper="span" />}
          onClick={() => setOpenUsers(true)}
        >
          {(users && selectedUserId) ? users.find(({ id }) => id === selectedUserId)?.login : 'Все аккаунты'}
        </Button>
        <Button
          className={styles.button}
          viewStyle="tertiary"
          icon={<ReactSVG src={featured ? '/icons/favorite.svg' : '/icons/favorite-empty.svg'} wrapper="span" />}
          onClick={() => featured ? onRemoveFavourite(id) : onAddFavourite(id)}
          title={t('favourites')}
        />
        {['tabletS', 'phone'].includes(viewport) && (
          <div className={styles.count} style={{ background: pColor?.color }}>
            {points}
          </div>
        )}
      </div>
      <div className={styles.right}>
        <Button
          className={classNames(styles.button, styles.signIn)}
          onClick={onClickSignAs}
          viewStyle="tertiary"
        >
          {t('sign_as_user')}
        </Button>
        {!['tabletS', 'phone'].includes(viewport) && (
          <div className={styles.count} style={{ background: pColor?.color }}>
            {points}
          </div>
        )}
      </div>

      <WorkspaceUsersModal
        open={isOpenUsers}
        onClose={() => setOpenUsers(false)}
        users={users}
        selectedUserId={selectedUserId}
        onSelectUser={onSelectUser}
      />
    </div>
  );
};

export default WorkspaceHeader;
