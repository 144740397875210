import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { Button, ButtonsContainer, Input } from '@forma/forma-ui-kit';
import VariationAnswer from './VariationAnswer';

import styles from './variation-edit.module.css';

const VariationEdit = ({ id, title, multiple, variants, parents, onChange }) => {
  const { t } = useTranslation();

  const handleChangeAnswer = (index, data) => {
    let next = [...variants];
    if (data.default && !multiple) next = next.map((item) => ({ ...item, default: false }));
    next.splice(index, 1, data);

    onChange({ id, title, multiple, variants: next, parents });
  };

  const handleRemoveAnswer = (index) => {
    const next = [...variants];
    next.splice(index, 1);
    onChange({ id, title, multiple, variants: next, parents });
  };

  const handleClickAdd = () => {
    const next = [...variants];
    next.push({ id: nanoid(8), key: '', default: false, value: '', tattrs: [], tables: [] });
    onChange({ id, title, multiple, variants: next, parents });
  };

  const handleChangeName = (value) => {
    onChange({ id, title: value, multiple, variants, parents });
  };

  // const handleChangeMulti = (checked: boolean) => {
  //   onChange({ id, title, multiple: checked, variants });
  // };

  return (
    <div className={styles.root}>
      <div className={styles.question}>
        <div className={styles.subtitle}>{t('question_text')}</div>
        <Input
          name="question"
          placeholder={t('who_is_performer')}
          className={styles.input}
          onChange={e => handleChangeName(e.target.value)}
          value={title}
          maxLength={100}
        />
      </div>
      <div className={styles.subtitle}>
        {t('answer_options')}
        {/* <br /> */}
        {/* <Switcher
          name="multiple"
          label={t('can_select_multiple_values_from_list')}
          containerClass={styles.reversed}
          onChange={e => handleChangeMulti(e.target.checked)}
          checked={multiple}
        /> */}
      </div>
      {variants?.map((item, index) => (
        <VariationAnswer
          data={item}
          onChange={data => handleChangeAnswer(index, data)}
          onRemove={() => handleRemoveAnswer(index)}
          index={index}
          key={index}
        />
      ))}
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="tertiary"
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          className={styles.buttonAdd}
          iconClassName={styles.buttonAddIcon}
          onClick={handleClickAdd}
        />
      </ButtonsContainer>
    </div>
  );
};

export default VariationEdit;
