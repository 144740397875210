import { baseApi } from 'store/store';

export const timeslotsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTimeslots: builder.query({
      query: () => ({
        url: '/timeslots',
        method: 'GET',
      }),
      providesTags: ['Timeslots']
    }),
    setTimeslot: builder.mutation({
      query: data => ({
        url: '/timeslots',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Timeslots', 'BookedTimeslots']
    }),
    getBookedTimeslots: builder.query({
      query: data => ({
        url: '/timeslotelements',
        method: 'GET',
        params: data
      }),
      providesTags: ['BookedTimeslots']
    }),
    updateTimeslot: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/timeslotsadmin/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Timeslots']
    }),

    getManagers: builder.query({
      query: () => ({
        url: '/managers',
        method: 'GET',
      }),
      providesTags: ['Managers']
    }),
    addManager: builder.mutation({
      query: data => ({
        url: '/managers',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Managers']
    }),
    deleteManager: builder.mutation({
      query: id => ({
        url: `/managers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Managers']
    }),
    setTimeslotManager: builder.mutation({
      query: data => ({
        url: '/managers/timeslot',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['BookedTimeslots']
    }),
    deleteManagerTimeslot: builder.mutation({
      query: data => ({
        url: '/managers/timeslot',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: ['BookedTimeslots']
    }),
    enableManagerTimeslot: builder.mutation({
      query: data => ({
        url: '/managers/timeslot/status',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ManagersTimeslotsStatus']
    }),
    disableManagerTimeslot: builder.mutation({
      query: data => ({
        url: '/managers/timeslot/status',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: ['ManagersTimeslotsStatus']
    }),
    getManagerDisabledTimeslots: builder.query({
      query: id => ({
        url: `/managers/timeslot/status/${id}`,
        method: 'GET',
      }),
      providesTags: ['ManagersTimeslotsStatus']
    }),
  })
});

export const {
  useGetTimeslotsQuery,
  useSetTimeslotMutation,
  useGetBookedTimeslotsQuery,
  useGetBookedTimeslotsByManagerQuery,
  useUpdateTimeslotMutation,

  useGetManagersQuery,
  useAddManagerMutation,
  useDeleteManagerMutation,
  useSetTimeslotManagerMutation,
  useDeleteManagerTimeslotMutation,
  useEnableManagerTimeslotMutation,
  useDisableManagerTimeslotMutation,
  useGetManagerDisabledTimeslotsQuery
} = timeslotsApi;
