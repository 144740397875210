import {
  useGetAdminDataQuery,
  useGetProfileDataQuery,
  useGetProfilePermissionsQuery,
} from 'store/user/userApi';

const MainQuery = ({ isLogged }) => {
  useGetProfileDataQuery(undefined ,{ skip: !isLogged });
  useGetAdminDataQuery(undefined ,{ skip: !isLogged });
  useGetProfilePermissionsQuery(undefined ,{ skip: !isLogged });
  return null;
};

export default MainQuery;
