import { Link } from 'react-router-dom';
import { Tooltip } from '@forma/forma-ui-kit';
import { currencies } from 'data/mock';
import { formatPrice, formatValue } from 'helpers/formatValues';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-users.module.css';

const DashboardUsers = ({ registers, payments, conversion }) => (
  <DashboardBlock>
    <div className={styles.root}>
      <div className={styles.column} style={{ width: '40%' }}>
        <div className={styles.header}>
          <div className={styles.title}>
            Количество <br /> регистраций
          </div>
          <div className={styles.buttons}>
            <Tooltip
              control={<img src="/icons/question-circle.svg" alt="" />}
            >
              Количество созданных воркспейсов
            </Tooltip>
            <Link to="/registers"><img src="/icons/link.svg" alt="" /></Link>
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.registers}>
              <span>Сегодня:</span>
              <b>{registers.day}</b>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.registers}>
              <span>Неделя:</span>
              <b>{registers.week}</b>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.registers}>
              <span>Месяц:</span>
              <b>{registers.month}</b>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.column} style={{ width: '40%' }}>
        <div className={styles.header}>
          <div className={styles.title}>
            Количество <br /> оплат
          </div>
          <div className={styles.buttons}>
            <Link to="/payments"><img src="/icons/link.svg" alt="" /></Link>
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.payments}>
              <b>{formatValue(payments.day.count, currencies.ru)} шт</b>
              <span>{formatPrice(payments.day.amount, currencies.ru)}</span> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.payments}>
              <b>{formatValue(payments.week.count, currencies.ru)} шт</b>
              <span>{formatPrice(payments.week.amount, currencies.ru)}</span> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.payments}>
              <b>{formatValue(payments.month.count, currencies.ru)} шт</b>
              <span>{formatPrice(payments.month.amount, currencies.ru)}</span> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.column} style={{ width: '20%' }}>
        <div className={styles.header}>
          <div className={styles.title}>
            Конверсия <br /> в оплату
          </div>
          <div className={styles.buttons}>
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.conversion}>
              <b>{conversion.day.toFixed()}%</b> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.conversion}>
              <b>{conversion.week.toFixed()}%</b> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.conversion}>
              <b>{conversion.month.toFixed()}%</b> {/*<img src="/icons/chevron-double-up.svg" alt="" />*/}
            </div>
          </div>

        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardUsers;
