import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal, Button, ButtonsContainer } from '@forma/forma-ui-kit';
import WorkspaceHeader from './WorkspaceHeader';
import WorkspaceMainInfo from './WorkspaceMainInfo';
import WorkspacePayment from './WorkspacePayment';
import WorkspaceTemplates from './WorkspaceTemplates';
import WorkspaceStatistic from './WorkspaceStatistic';
import WorkspaceFilling from './WorkspaceFilling';
import WorkspaceCRM from './WorkspaceCRM';
import WorkspaceSource from './WorkspaceSource';
import WorkspaceSearch from './WorkspaceSearch';
import WorkspaceDevices from './WorkspaceDevices';

import styles from './workspace-info.module.css';

const WorkspaceInfo = ({
  id, data, params, crm = {}, statistic,
  onChangeFilter, onClickSignAs, onClickDownloadRequest, onClickDownloadRequests,
  selectedUserId, onSelectUser, onAddFavourite, onRemoveFavourite, onRemove
}) => {
  const { t } = useTranslation();
  const createdToday = data?.createdToday ?? {};
  const [ isModalRemoveShow, setModalRemoveShow ] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <WorkspaceHeader
          id={id}
          params={params}
          users={data?.users?.items}
          points={data?.points ?? 0}
          featured={data?.workspace.featured}
          onClickSignAs={onClickSignAs}
          selectedUserId={selectedUserId}
          onSelectUser={onSelectUser}
          onAddFavourite={onAddFavourite}
          onRemoveFavourite={onRemoveFavourite}
        />
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.column, styles.shrink)}>
          <WorkspaceMainInfo {...data} />
        </div>
        <div className={classNames(styles.column, styles.wide)}>
          <WorkspacePayment {...data} />
          <WorkspaceTemplates {...createdToday} />
        </div>
        <div className={classNames(styles.column)}>
          <WorkspaceStatistic params={params} data={statistic} onChangeFilter={onChangeFilter} />
        </div>
        <div className={classNames(styles.column, styles.wide)}>
          <WorkspaceFilling id={id} items={data?.tcRequests} onClickDownload={onClickDownloadRequest} onClickDownloadAll={onClickDownloadRequests} />
        </div>
        <div className={classNames(styles.column, styles.shrink)}>
          <WorkspaceCRM {...crm} />
          <WorkspaceSource items={data?.sources} />
        </div>
        <div className={classNames(styles.column, styles.wide)}>
          <WorkspaceSearch id={id} items={data?.searchActivity ?? []} />
        </div>
        <div className={classNames(styles.column, styles.shrink)}>
          <WorkspaceDevices id={id} userId={selectedUserId} items={data?.devices} />
        </div>
      </div>
      <ButtonsContainer style={{ justifyContent: 'flex-end' }}>
        <Button
          viewStyle="danger"
          onClick={() => setModalRemoveShow(true)}
        >
          {t('remove_workspace')}
        </Button>
      </ButtonsContainer>


      <Modal
        open={isModalRemoveShow}
        onClose={() => setModalRemoveShow(false)}
        title={t('remove_workspace')}
        buttons={[
          {
            children: t('delete'),
            viewStyle: 'danger',
            onClick: () => onRemove(id)
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          },
        ]}
      >
        <div className={styles.modalContent} style={{ textAlign: 'center' }}>
          <div>{t('are_you_sure_delete_workspace', { login: data?.user?.login })}</div>
          <div>{t('cancellation_notification')}</div>
        </div>
      </Modal>
    </div>
  );
};

export default WorkspaceInfo;
