import { useState, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { add, max } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import TimeItems from 'components/fields/DateTimeInput/TimeItems';
import { Button } from '@forma/forma-ui-kit';

import styles from './demo-requests-calendar.module.css';

const utc = { utc: '+03:00' };

const DemoRequestsCalendar = ({ timeslots, bookedSlots, onClickTimeslot, disabledTimeslots }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [ showTimeItems, setShowTimeItems ] = useState(false);
  const [ date, setDate ] = useState(new Date());


  const dates = useMemo(() => timeslots?.reduce((acc, current) => {
    const date = current.date.slice(0, 10);
    const slot = { id: current.id, date: current.date, available: current.available && !disabledTimeslots?.find(item => item.id === current.id) };
    const next = {
      date: date,
      dateObj: new Date(date),
      available: current.available || acc[date]?.available || false,
      slots: acc[date] ? [ ...acc[date].slots, slot ] : [ slot ]
    };
    return ({ ...acc, [date]: next });
  }, {}), [timeslots, disabledTimeslots]);

  const disabledDates = dates && Object.values(dates).filter(({ available }) => !available).map(({ dateObj }) => dateObj);

  const handleChangeDate = (date) => {
    setDate(date);
    setShowTimeItems(true);
  };

  const timeslotsValue = bookedSlots?.map(({ timeslotId }) => timeslotId);

  const timeText = (date, disabled, active) => {
    let title = t('free_time');
    if (disabled) title = t('busy_time');
    if (active) title = t('booked_time');
    return (
      <span title={title} className={styles.timeItem}>{date}</span>
    );
  };

  const handleClickTimeslot = (slotId) => {
    const slot = timeslots.find(({ id }) => id === slotId);
    onClickTimeslot(slotId, !(slot?.available && !disabledTimeslots?.find(item => item.id === slotId)));
  };

  return (
    <div className={styles.root}>
      {showTimeItems && (
        <div className={styles.calendarHeader}>
          <Button
            className={styles.buttonBack}
            viewStyle="text"
            icon={<ReactSVG src="/icons/arrow-left-bold.svg" wrapper="span" />}
            onClick={() => setShowTimeItems(false)}
          >
            {t('go_back')}
          </Button>
        </div>
      )}
      {!showTimeItems && (
        <div className={styles.calendar}>
          <ReactDatePicker
            selected={date}
            onChange={handleChangeDate}
            excludeDates={disabledDates}
            minDate={new Date()}
            // minDate={add(new Date(), { months: -1 })}
            maxDate={dates ? max(Object.values(dates).map(({ dateObj }) => dateObj)) : add(new Date(), { months: 1 })}
            previousMonthButtonLabel={
              <ReactSVG src="/icons/arrow-left.svg" className={styles.arrow} width="22px" height="16px" alt="" />
            }
            nextMonthButtonLabel={
              <ReactSVG src="/icons/arrow-right.svg" className={styles.arrow} width="22px" height="16px" alt="" />
            }
            locale={i18n.language}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            inline
          />
        </div>
      )}

      {showTimeItems && (
        <div className={styles.time}>
          {/* <div className={styles.timeHead}>
            <TimezoneSelect value={utc} onChange={handleChangeUTC} />
          </div> */}
          <div className={styles.timeItems}>
            <TimeItems
              utc={utc}
              slots={(dates && dates[moment(date).format('yyyy-MM-DD')]) && dates[moment(date).format('yyyy-MM-DD')].slots}
              value={timeslotsValue}
              onChange={handleClickTimeslot}
              timeText={timeText}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoRequestsCalendar;
