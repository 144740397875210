import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import GroupsList from 'components/variables/GroupsList';

import {
  useGetStrictVariablesQuery,
  useGetStrictFoldersQuery,
  useAddStrictFolderMutation,
  useRemoveStrictFolderMutation,
  useUpdateStrictFolderMutation,
  useUpdateStrictVariableMutation,
  useAddStrictVariableMutation,
  useGetStrictSectionsQuery
} from 'store/variables/variablesApi';
import { useGetTemplateVariablesTypesQuery } from 'store/schemas/schemasApi';

import { setNotification } from 'store/notifications/notificationsSlice';

const Folders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: sections } = useGetStrictSectionsQuery();
  const { data: variables } = useGetStrictVariablesQuery();
  const { data: folders } = useGetStrictFoldersQuery();
  const { data: variblesTypes } = useGetTemplateVariablesTypesQuery();

  const [ addFolder ] = useAddStrictFolderMutation();
  const [ updateFolder ] = useUpdateStrictFolderMutation();
  const [ removeFolder ] = useRemoveStrictFolderMutation();
  const [ updateVariable, { isLoading: isMoveLoading } ] = useUpdateStrictVariableMutation();
  const [ addVariable ] = useAddStrictVariableMutation();

  const handleSaveVariable = async (values) => {
    let data = { ...values };
    delete(data.lang);
    const res = await updateVariable(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleAddVariable = async (values) => {
    let data = { ...values };
    delete(data.lang);
    const res = await addVariable(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleSaveFolder = async (data) => {
    const res = await updateFolder(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleAddFolder = async (values) => {
    const res = await addFolder(values);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleRemoveFolder = async (id) => {
    const res = await removeFolder(id);
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  return (
    <AdminLayout title={t('variables_groups')}>
      <PageTitle>{t('site_name') + ' – ' + t('variables_groups')}</PageTitle>
      <GroupsList
        isLoading={isMoveLoading}
        folders={folders}
        sections={sections}
        variables={variables}
        variblesTypes={variblesTypes}
        onSave={handleSaveFolder}
        onAdd={handleAddFolder}
        onRemove={handleRemoveFolder}
        onSaveVariable={handleSaveVariable}
        onAddVariable={handleAddVariable}
      />
    </AdminLayout>
  )
};

export default Folders;