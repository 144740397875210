import { ReactSVG } from 'react-svg';
import { Button } from '@forma/forma-ui-kit';
import DateInput from 'components/fields/DateInput';

import styles from './dashboard-registers-header.module.css';

const DashboardRegistersHeader = ({ filter, onChangeFilter, view, onChangeView }) => {
  const handleChangeDateFrom = (e) => {
    onChangeFilter({ dtFrom: e.target.value });
  };
  const handleChangeDateTo = (e) => {
    onChangeFilter({ dtTo: e.target.value });
  };

  return (
    <div className={styles.root}>
      <div className={styles.inputs}>
        <DateInput
          name="dtFrom"
          icon={<ReactSVG src="/icons/calendar.svg" style={{ color: '#81888C', pointerEvents: 'none' }} wrapper="span" />}
          onChange={handleChangeDateFrom}
          value={filter.dtFrom}
        />
        -
        <DateInput
          name="dtTo"
          icon={<ReactSVG src="/icons/calendar.svg" style={{ color: '#81888C', pointerEvents: 'none' }} wrapper="span" />}
          onChange={handleChangeDateTo}
          value={filter.dtTo}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          viewStyle={view === 'chart' ? 'primary' : 'secondary'}
          icon={<ReactSVG src="/icons/chart.svg" wrapper="span" />}
          onClick={() => onChangeView(view === 'chart' ? 'list' : 'chart')}
          shadow
        />
      </div>
    </div>
  );
};

export default DashboardRegistersHeader;