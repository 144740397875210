import { useTranslation } from 'react-i18next';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-tariff.module.css';

const DashboardTariff = ({ name, count, duration, percent, tariffId }) => {
  const { t } = useTranslation();
  const months = duration/30;

  return (
    <DashboardBlock
      title="Самый популярный тариф"
    >
      <div className={styles.root}>
        <div className={styles.value}>
          {name} <span className={styles.valueText}>{months >= 12 ? t('count_years', { count: months/12 }) : t('count_months', { count: months })}</span>
        </div>
        <div className={styles.text}>{percent.toFixed()}% оплат по данному тарифу</div>
      </div>
    </DashboardBlock>
  );
};

export default DashboardTariff;
