import { baseApi } from 'store/store';

export const resourcesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getResourcesList: builder.query({
      query: () => ({
        url: '/resources/',
        method: 'GET',
      }),
      providesTags: ['Resources']
    }),
    getResource: builder.query({
      query: ({ id, lang }) => ({
        url: `/resources/${id}/${lang}`,
        method: 'GET',
      }),
      providesTags: (result, error, data) => [{ type: 'Resources', id: data.id }],
      // transformResponse: result => result.schema.reduce((prev, current) => ({ ...prev, [current.scope]: current }), {})
    }),
    addResource: builder.mutation({
      query: data => ({
        url: '/resources/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Resources']
    }),
    updateResource: builder.mutation({
      query: ({ id, lang, ...data }) => ({
        url: `/resources/${id}/${lang}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Resources']
    }),
    removeResource: builder.mutation({
      query: ({ id, lang }) => ({
        url: `/resources/${id}/${lang}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Resources']
    }),
    setEditorStyles: builder.mutation({
      query: data => ({
        url: `/statics/defaultStyle`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['EditorStyles']
    }),
  })
});

export const {
  useGetResourcesListQuery,
  useGetResourceQuery,
  useAddResourceMutation,
  useUpdateResourceMutation,
  useRemoveResourceMutation,
  useSetEditorStylesMutation
} = resourcesApi;
