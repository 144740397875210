import { useTranslation } from 'react-i18next';
// import { Button } from '@forma/forma-ui-kit';
import CreateFolder from '../CreateFolder';
import CreateTemplate from '../CreateTemplate';

import styles from './no-items.module.css';

const NoItems = ({ id, onCreate, favourites }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {onCreate ? t('empty_folder_description_create') : t('empty_folder_description')}
      </div>
      {/* {favourites?.isOpen ? (
        <div className={styles.buttons}>
          <Button
            as={Link}
            to={favourites.href}
            onClick={favourites.onClick}
            viewStyle="secondary"
            shadow
          >
            {t('return_to_templates')}
          </Button>
        </div>
      ) : ( */}
        {onCreate && (
          <div className={styles.buttons}>
            <CreateTemplate onCreate={onCreate} parentid={id} />
            <CreateFolder onCreate={onCreate} parentid={id} />
          </div>
        )}
      {/* )} */}
    </div>
  );
};

export default NoItems;
