import styles from './dashboard-block.module.css';

const DashboardBlock = ({ title, buttons, children }) => (
  <div className={styles.root}>
    {(title || buttons) && (
      <div className={styles.header}>
        {title && (
          <div className={styles.title}>
            {title}
          </div>
        )}
        {buttons && (
          <div className={styles.buttons}>
            {buttons}
          </div>
        )}
      </div>
    )}
    <div className={styles.content}>{children}</div>
  </div>
);

export default DashboardBlock;
