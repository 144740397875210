import { format } from 'date-fns';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';

import styles from './dashboard-scoring-header.module.css';

const DashboardScoringHeader = ({ onSave, total }) => {
  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
          <Button
            viewStyle="primary"
            onClick={onSave}
            disabled={total !== 100}
            shadow
          >
            Сохранить
          </Button>
          <div className={styles.status}>Последнее обновление от <br /> {format(new Date(), 'dd.MM.yyyy г., HH:mm')}</div>
      </div>
      <div className={styles.total}>
        Сумма всех балов: <span className={classNames(styles.totalValue, total === 100 && styles.success, total < 100 && styles.danger, total > 100 && styles.error)}>{total}</span>
      </div>
    </div>
  );
};

export default DashboardScoringHeader;
