import { useEffect } from 'react';

const PageTitle = ({ children }) => {
  useEffect(() => {
    document.title = children;
  });

  return null;
};

export default PageTitle;
