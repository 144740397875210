import { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { unstable_usePrompt as usePromt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import classNames from 'classnames';
import { Modal, ProductsTableSelectModal, nanoid, useDebounce, editorCache } from '@forma/forma-ui-kit';
import Side, { SidePlaceholder } from './Side';
import VariableAddModal from './VariableAddModal';
import FillingModal from 'pages/Templates/FillingModal'
// import SignatureAdd from './SignatureAdd';
import VariationsSelectModal from './VariationsSelectModal';
import VariationsEditModal from './VariationsEditModal';
import {
  appendSidesColors,
  sidesUpdateFunctions,
  sideVariableHelpers,
  sideVariationHelpers
} from '@forma/forma-ui-kit';

import styles from './files-editor.module.css';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/notifications/notificationsSlice';

const defaultMargins = {
  top: '2.54cm',
  bottom: '2.54cm',
  left: '2.54cm',
  right: '2.54cm'
};

const SAVING_INTERVAL = 60;
const CACHE_TIME = 60*60*24;

const Editor = lazy(() => import('./Editor'));

const FilesEditor = ({
  id, template, variablesGroups, availableVariables, onSave, isSaveLoading, products
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultSide = { id: nanoid(), name: `${t('side')} 1`, tattrFolders: [], tables: [], carticles: [] };
  const [ showingModal, setShowingModal ] = useState(null);
  const intervalRef = useRef(null);
  const [ sides, setSides ] = useState(template?.sides ?? [defaultSide]);
  const [ pageMargins, setPageMargins ] = useState(null);
  const [ isChanged, setIsChanged ] = useState(false);
  const [ isFillOpen, setFillOpen ] = useState(false);
  const [ fromCache, setFromCache ] = useState(null);

  const handleUnload = (e) => {
    e.preventDefault();
    const confirmationMessage = '';
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  usePromt({ when: isChanged, message: t('you_have_unsaved_changes') });

  useEffect(() => {
    editorCache.check(CACHE_TIME);

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isChanged) window.onbeforeunload = handleUnload;
    else window.onbeforeunload = null;
  }, [isChanged]);

  useEffect(() => {
    if (sides) appendSidesColors(sides);
  }, [sides]);

  useEffect(() => {
    if (!template) return;
    if (template.sides?.length) {
      setSides(template.sides);
      dispatch(addNotification({ content: 'Стороны были обновлены', type: 'ERROR' }));
    }
    setPageMargins(template.border ?? defaultMargins);

    const cachedData = editorCache.get(id);
    if (cachedData && template.content !== cachedData.content) setFromCache(cachedData);

    // eslint-disable-next-line
  }, [id, template]);

  useEffect(() => {
    if (!template) return;

    intervalRef.current = setInterval(() => {
      const content = window.editor?.getData();
      if (content && content !== template.content) editorCache.set(id, { content: window.editor.getData(), sides });
    }, SAVING_INTERVAL * 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [id, template, sides]);

  const handleClickAddVariable = () => setShowingModal({ type: 'variable' });
  const handleClickAddSignature = () => setShowingModal({ type: 'signature' });
  const handleClickAddVariations = (sideId) => setShowingModal({ type: 'variations', sideId });
  const handleClickEditVariations = (sideId, id) => setShowingModal({ type: 'variation-edit', sideId, id });
  const handleClickAddTable = () => setShowingModal({ type: 'products' });
  const handleClickEditTable = (sideId, id) => setShowingModal({ type: 'products', sideId, id });

  const closeModal = () => setShowingModal(null);

  const saveDocument = async (sides, showModal) => {
    if (!availableVariables || !pageMargins) return;

    const data = window.editor.getData();

    const isSuccess = await onSave({
      id: id,
      content: data,
      sides: sides.map(side => ({
        ...side,
        tattrFolders: side.tattrFolders.map(({ id, name, tattrs }) => ({
          id,
          name,
          tattrs: tattrs.map(({ id }) => {
            let inVationsCount = 0;
            const contentVariables = document.querySelectorAll(`span.variable[id="${id}"][data-side-id="${side.id}"]`);
            if (contentVariables.length) {
              for (const variable of contentVariables) {
                const variationParent = variable.closest('.variations-description');
                if (variationParent) inVationsCount++;
              }
            }

            return ({
              id,
              from: availableVariables[id] ? 'strictattr' : 'cattr',
              countBody: contentVariables.length,
              countVariative: inVationsCount
            });
          })
        }))
      })),
      border: pageMargins,
    }, showModal);
    if (isSuccess) {
      if (id) editorCache.delete(id);
      setIsChanged(false);
    }
    return isSuccess;
  };

  const getRemovedVariables = () => {
    const variables = [];
    const contentVariables = document.querySelectorAll('span.variable');
    if (contentVariables.length) {
      for (const variable of contentVariables) {
        const side = sides.find(({ id }) => id === variable.dataset.sideId);
        if (!side) {
          variables.push(variable);
          continue;
        }

        const hasVariable = !!side.tattrFolders.find(({ tattrs }) => (tattrs.find(({ id }) => id === variable.id)));
        if (!hasVariable) variables.push(variable);
      }
    }
    return variables;
  };

  const handleClickSave = () => {
    if (!sides) return;

    const content = window.editor.getData();
    const problems = {};
    const removedVariables = getRemovedVariables(sides);

    if (removedVariables.length) {
      problems.removed = t('you_have_removed_variables');

      for (const variable of removedVariables) {
        variable.classList.add('error');
      }
    }

    const { sides: next, hasUnused } = sidesUpdateFunctions.setUnusedParams(sides, content);

    setSides(next);

    if (hasUnused) problems.unused = t('you_have_unused_variables');
    if (problems.unused || problems.removed) setShowingModal({ type: 'unused', problems: Object.values(problems) });
    else saveDocument(next, true);
  };

  const handleClickSaveAndDelete = () => {
    const removedVariables = getRemovedVariables(sides);
    for (const variable of removedVariables) {
      window.editor?.execute('removeVariables', { id: variable.id, sideId: variable.dataset.sideId });
    }

    let _sides = sides;
    setSides(prev => {
      _sides = sidesUpdateFunctions.clean(prev);
      return _sides;
    });

    if (_sides) saveDocument(_sides, true);
    setShowingModal(null);
  };

  const handleClickSideVariable = (sideId, sideName, tattrId, tattrName, tattrType) => {
    window.editor?.execute('addVariable', {
      id: tattrId,
      text: `${sideName}.${tattrName}`,
      type: tattrType,
      sideId: sideId,
      sideName: sideName
    });
  };

  const changeTemplateSideName = useDebounce((sideId, sideName) => {
    window.editor?.execute('changeSideName', { sideId, sideName });
    window.editor?.execute('changeProductsTableSideName', { sideId, sideName });
  }, 300);

  const handleAddSide = () => {
    setSides(prev => [ ...prev, { id: nanoid(), name: `${t('side')} ${prev.length+1}`, tattrFolders: [], tables: [], carticles: [] }]);
  };

  const handleChangeSide = (data) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => data.id === id);
      return update(prev, {
        [sideIndex]: {
          $merge: data
        }
      });
    });
    changeTemplateSideName(data.id, data.name);
  };

  const handleRemoveSide = (sideId) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => sideId === id);
      return update(prev, {
        $splice: [[sideIndex, 1]]
      });
    });
    window.editor?.execute('removeVariables', { sideId });
  };

  const handleMoveSide = (oldIndex, newIndex) => {
    setSides(prev =>
      update(prev, {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, prev[oldIndex]]
        ]
      })
    );
  };

  const handleAddVar = (side, varData, checked, group) => {
    setShowingModal(null);
    const hasVariable = sides?.find(({ id, tattrFolders }) =>
      (id === side.id) && tattrFolders.find(({ tattrs }) => !!(tattrs?.find(({ id }) => id === varData.id)))
    );

    if (checked && !hasVariable) {
      setSides(prev => {
        const sideIndex = prev.findIndex(({ id }) => side.id === id);
        return update(prev, {
          [sideIndex]: {
            tattrFolders: {
              $set: sideVariableHelpers.addVariableToFolder(prev[sideIndex].tattrFolders, varData, { id: group?.id, name: group?.name })
            }
          }
        });
      });
    }

    window.editor?.execute('addVariable', {
      id: varData.id, text: `${side.name}.${varData.name}`, type: varData.type, sideId: side.id, sideName: side.name
    });
  };

  const handleAddVariableToVariation = (source, variation, variant) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === variation.sideId);
      if (sideIndex === -1) return prev;

      return update(prev, {
        [sideIndex]: {
          carticles: {
            $set: sideVariationHelpers.addVariableToVariation(
              prev[sideIndex].carticles, variation.id, variant.id, { sideId: source.sideId, id: source.id }
            )
          }
        }
      });
    });
  };

  const handleRemoveVariableFromVariation = (source, variation, variant) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === variation.sideId);
      if (sideIndex === -1) return prev;

      return update(prev, {
        [sideIndex]: {
          carticles: {
            $set: sideVariationHelpers.removeVariableFromVariation(
              prev[sideIndex].carticles, variation.id, variant.id, { sideId: source.sideId, id: source.id }
            )
          }
        }
      });
    });
  };

  const handleAddTableToVariation = (source, variation, variant) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === variation.sideId);
      if (sideIndex === -1) return prev;

      return update(prev, {
        [sideIndex]: {
          carticles: { $set: sideVariationHelpers.addTableToVariation(prev[sideIndex].carticles, variation.id, variant.id, source.id) }
        }
      });
    });
  };

  const handleRemoveTableFromVariation = (source, variation, variant) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === variation.sideId);
      if (sideIndex === -1) return prev;

      return update(prev, {
        [sideIndex]: {
          carticles: { $set: sideVariationHelpers.removeTableFromVariation(prev[sideIndex].carticles, variation.id, variant.id, source.id) }
        }
      });
    });
  };

  // const handleAddSignature = (url) => {
  //   window.editor?.execute('signatureInsert', url);
  // };

  const handleCreateVariations = (sideId, data) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    setSides(prev => {
      return update(prev, {
        [sideIndex]: {
          carticles: { $push: [ data ] }
        }
      });
    });

    window.editor?.execute('variationsBlock', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  const handleAddVariations = (sideId, data) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    window.editor?.execute('variationsBlock', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  const handleSaveVariation = (sideId, data) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    setSides(prev => {
      const index = prev[sideIndex].carticles.findIndex(item => item.id === data.id);
      return update(prev, {
        [sideIndex]: {
          carticles: {
            [index]: {
              $merge: data
            }
          }
        }
      });
    });

    window.editor?.execute('variationsBlock', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  const handleRemoveVariation = (sideId, id) => {
    setSides(prev => { 
      const sideIndex = prev.findIndex(({ id }) => sideId === id);
      const index = prev[sideIndex]?.carticles.findIndex(item => item.id === id);
      if (!index || index === -1) return prev;
      return update(prev, {
        [sideIndex]: {
          carticles: { $splice: [[index, 1]] }
        }
      });
    });
  };

  const handleAddVariationToVariation = (source, variation, variant) => {
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === source.sideId);
      if (sideIndex === -1) return prev;
      return update(prev, {
        [sideIndex]: {
          carticles: { $set: sideVariationHelpers.addVariationToVariation(prev[sideIndex].carticles, variation.id, variant.id, source.id) }
        }
      });
    });
  };

  const handleRemoveVariationFromVariation = (source, variation, variant) => {
    if (source.id === variation.id) return;
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => id === source.sideId);
      if (sideIndex === -1) return prev;
      return update(prev, {
        [sideIndex]: {
          carticles: {
            $set: sideVariationHelpers.removeVariationFromVariation(prev[sideIndex].carticles, variation.id, variant.id, source.id)
          }
        }
      });
    });
  };

  const handleAddOrChangeTable = (sideId, data) => {
    closeModal();
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    const tableIndex = sides[sideIndex].tables.findIndex(({ id }) => data.id === id);
    if (tableIndex === -1) {
      setSides(prev => {
        return update(prev, {
          [sideIndex]: {
            tables: { $push: [ data ] }
          }
        });
      });
      window.editor?.execute('addProductsTable', { ...data, sideId, sideName: sides[sideIndex].name });
    } else {
      setSides(prev => {
        return update(prev, {
          [sideIndex]: {
            tables: { $splice: [[tableIndex, 1, data]] }
          }
        });
      });
      window.editor?.execute('changeProductsTable', data);
    }
  };

  // TODO: remove after change tables
  const handleChangeTable = (sideId, data) => {
    // closeModal();
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => sideId === id);
      const tableIndex = prev[sideIndex]?.tables.findIndex(({ id }) => data.id === id);
      if (tableIndex === -1) return prev;
      return update(prev, {
        [sideIndex]: {
          tables: { $splice: [[tableIndex, 1, data]] }
        }
      });
    });
    window.editor?.execute('changeProductsTable', data);
  };

  const handleClickFill = () => {
    if (isChanged) {
      saveDocument(sides).then((res) => {
        if (res) setTimeout(() => setFillOpen(true), 300);
      });
    } else {
      setFillOpen(true);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Suspense>
          <Editor
            id={id}
            content={template?.content}
            page={{
              margins: pageMargins || template?.border || defaultMargins,
              onChangeMargins: setPageMargins
            }}
            variables={{
              onClickAddVariable: handleClickAddVariable
            }}
            signature={{
              onClickAddSignature: handleClickAddSignature
            }}
            variations={{
              onAddVariable: handleAddVariableToVariation,
              onRemoveVariable: handleRemoveVariableFromVariation,
              onAddTable: handleAddTableToVariation,
              onRemoveTable: handleRemoveTableFromVariation,
              onAddVariation: handleAddVariationToVariation,
              onRemoveVariation: handleRemoveVariationFromVariation,
              onClickAdd: handleClickAddVariations,
              onClickEdit: handleClickEditVariations,
              onRemove: handleRemoveVariation
            }}
            productsTable={{
              products: products,
              onClickAddTable: handleClickAddTable,
              onClickEditTable: handleClickEditTable,
              onChangeTable: handleChangeTable
            }}
            // colontitules={{
            //   onClicklOpenColontitules: handleClickAddColontitules
            // }}
            onChange={() => setIsChanged(true)}
          />
        </Suspense>
      </div>
      <div className={classNames(styles.side, 'styled-scrollbar')}>
        {template ? (
          <Side
            id={id}
            sides={sides}
            variablesGroups={variablesGroups}
            availableVariables={availableVariables}
            onAdd={handleAddSide}
            onChange={handleChangeSide}
            onRemove={handleRemoveSide}
            onMove={handleMoveSide}
            onClickSave={() => handleClickSave(sides)}
            onClickVariable={handleClickSideVariable}
            onClickAddVariations={handleClickAddVariations}
            onClickFill={handleClickFill}
            isSaveLoading={isSaveLoading}
          />
        ) : (
          <SidePlaceholder />
        )}
      </div>
      {template &&
        <VariableAddModal
          open={showingModal?.type === 'variable'}
          onClose={() => setShowingModal(null)}
          sides={sides}
          variablesGroups={variablesGroups}
          availableVariables={availableVariables}
          onAddVar={handleAddVar}
        />
      }
      <VariationsSelectModal
        open={showingModal?.type === 'variations'}
        onClose={closeModal}
        onCreate={handleCreateVariations}
        onSelect={handleAddVariations}
        sides={sides}
        sideId={showingModal?.sideId}
      />
      <VariationsEditModal
        open={showingModal?.type === 'variation-edit'}
        onClose={closeModal}
        onSave={handleSaveVariation}
        data={sides.find(({ id }) => showingModal?.sideId === id)?.carticles.find(({ id }) => id === showingModal?.id)}
        sides={sides}
        sideId={showingModal?.sideId}
      />
      {/* <SignatureAdd
        open={showingModal === 'signature'}
        onClose={() => setShowingModal(null)}
        onAdd={handleAddSignature}
      /> */}
      <ProductsTableSelectModal
        open={showingModal?.type === 'products'}
        onClose={closeModal}
        onSelect={handleAddOrChangeTable}
        data={sides.find(({ id }) => showingModal?.sideId === id)?.tables.find(({ id }) => id === showingModal?.id)}
        sides={sides}
        sideId={showingModal?.sideId}
      />
      <Modal
        size="small"
        open={showingModal?.type === 'unused'}
        onClose={() => setShowingModal(null)}
        title={t('save_document_question')}
        buttons={[
          {
            children: t('save'),
            onClick: handleClickSaveAndDelete,
            viewStyle: 'primary',
          },
          {
            children: t('fix'),
            viewStyle: 'tertiary',
          }
        ]}
      >
        <div className={styles.modalDescription}>
          {showingModal?.problems?.map((text, index) => <div key={index}>{text}</div>)}
          <div style={{ marginTop: '16px' }}>{t('saving_clear_variables')}</div>
        </div>
      </Modal>
      <Modal
        size="small"
        open={!!fromCache}
        onClose={() => setFromCache(null)}
        title={t('document_recovery')}
        buttons={[
          {
            children: t('restore'),
            viewStyle: 'primary',
            onClick: () => {
              if (!fromCache) return;
              window.editor?.setData(fromCache.content);
              setSides(fromCache.sides);
            }
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary',
            onClick: () => editorCache.delete(id)
          }
        ]}
      >
        <div className={styles.modalDescription}>
          {t('document_recovery_description')}
        </div>
      </Modal>
      <FillingModal
        open={isFillOpen}
        onClose={() => setFillOpen(false)}
      />
    </div>
  );
};

export default FilesEditor;