import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';

import styles from './variations-select-modal.module.css';

const VariationsBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.banner}>
      <div className={styles.bannerContent}>
        <div className={styles.bannerText}>{t('what_is_variations_block')}</div>
        <img className={styles.bannerImage} src="/images/variations-button.svg" alt="" />
      </div>
      <div className={styles.bannerButtons}>
        <Button
          as="a"
          viewStyle="secondary"
          href="https://forma.today/knowlege/"
          target="_blank"
        >
          {t('go_to_knowledge')}
        </Button>
      </div>
    </div>
  );
};

export default VariationsBanner;
