import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { resourcesSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './resource-edit.module.css';

const ResourceEdit = ({ template, data, onCancel, onSave, onAdd }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(resourcesSchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    if (data) onSave(formValuesToData(resourcesSchema, values));
    else onAdd(formValuesToData(resourcesSchema, values));
  };

  const value = watch('value');

  return (
    <div className={styles.content}>
      {data?.id.split('.')[0] === 'email' && (
        <div className={styles.preview}>
          <div className={styles.previewTitle}>{t('preview')}</div>
          <div dangerouslySetInnerHTML={{ __html: data.id === 'email.template' ? value : template?.value.replace(/\${body}/, value) }} />
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {Object.keys(resourcesSchema).map(key => {
              const attrs = { ...resourcesSchema[key] };

              return (
                <FillingItem
                  {...attrs}
                  required={attrs.required ?? false}
                  id={key}
                  name={t(`vars.${key}`)}
                  key={key}
                  readOnly={data && key === 'id'}
                  errorMessage={t('errors.regexp')}
                  register={register}
                  control={control}
                  error={errors[key] && errors[key].message}
                />
              )
            }
          )}
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            viewStyle="tertiary"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            viewStyle="primary"
            disabled={!isValid}
          >
            {t('save')}
          </Button>
        </ButtonsContainer>
      </form>
    </div>
  )
};

export default ResourceEdit;
