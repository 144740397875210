import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Input, Switcher, Textarea, RemoveModal } from '@forma/forma-ui-kit';

import styles from './variation-edit.module.css';

const VariationAnswer = ({ data, index, onChange, onRemove }) => {
  const { t } = useTranslation();
  const [ isShowDescription, setShowDescription ] = useState(false);

  return (
    <div className={styles.answer}>
      <div className={styles.answerContent}>
        <div className={styles.answerNumber}>{index+1}</div>
        <Input
          name={`answer_${index}`}
          // placeholder={t('who_is_performer')}
          className={styles.input}
          onChange={e => onChange({ ...data, key: e.target.value })}
          value={data.key}
          maxLength={100}
        />
        <Switcher
          name={`default_${index}`}
          label={t('use_by_default')}
          containerClass={classNames(styles.switcher, styles.reversed)}
          onChange={e => onChange({ ...data, default: e.target.checked })}
          checked={data.default}
        />
        {/* <Button
          viewStyle="text"
          icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
          className={styles.buttonRemove}
          iconClassName={styles.buttonRemoveIcon}
        /> */}
        <RemoveModal
          title={t('do_you_want_delete_answer')}
          control={
            <Button
              className={styles.remove}
              viewStyle="textLight"
              icon={<ReactSVG src="/icons/trash.svg" wrapper="span"></ReactSVG>}
              title={t('delete')}
            />
          }
          onRemove={() => onRemove && setTimeout(() => onRemove(), 100)}
        />
      </div>
      {isShowDescription ? (
        <Textarea
          name={`description_${index}`}
          className={styles.input}
          containerClass={styles.answerDescription}
          onChange={e => onChange({ ...data, value: e.target.value })}
          value={data.value}
        />
      ) : (
        <div className={styles.answerButtons}>
          <Button
            viewStyle="textPrimary"
            onClick={() => setShowDescription(true)}
            className={styles.buttonAddDescription}
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          >
            {t('add_description')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default VariationAnswer;
