import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-docs.module.css';

const getDeviceType = (windowResolution) => {
  if (!windowResolution) return 'desktop';
  const width = windowResolution.split('x')[0];
  if (width <= 575) return 'phone';
  if (width <= 1024) return 'tablet';
  return 'desktop';
};

const getOsName = (userOs) => {
  if (!userOs) return 'unknown';
  if ((/Mac OS|iOS/i).test(userOs)) return 'apple';
  if ((/Android/i).test(userOs)) return 'android';
  if ((/Windows/i).test(userOs)) return 'windows';
  if ((/Ubuntu/i).test(userOs)) return 'ubuntu';
  if ((/Linux/i).test(userOs)) return 'linux';
  return 'unknown';
};

const getBrowserName = (browser) => {
  if (!browser) return 'unknown';
  if ((/Atom/i).test(browser)) return 'atom';
  if ((/Chrome/i).test(browser)) return 'chrome';
  if ((/Edge/i).test(browser)) return 'edge';
  if ((/Firefox/i).test(browser)) return 'firefox';
  if ((/MSIE|Windows NT/i).test(browser)) return 'ie';
  if ((/Brave/i).test(browser)) return 'brave';
  if ((/Yandex/i).test(browser)) return 'yandex';
  if ((/Safari/i).test(browser)) return 'safari';
  return 'unknown';
};

const DashboardPlatforms = ({ os, browsers, resolutions }) => (
  <DashboardBlock>
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.title}>Операционные системы</div>
        <div className={styles.subtitle}>Топ 3:</div>
        <div className={styles.items}>
          {os?.map(({ name, percent }) => (
            <div className={styles.item} key={name + percent}>
              <img src={`/icons/platforms/${getOsName(name)}.svg`} className={styles.itemIcon} width={24} height={24} alt="" />
              <span className={styles.itemLabel}>{name}</span>
              <span className={styles.itemValue}>{percent.toFixed()}%</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.title}>Разрешения</div>
        <div className={styles.subtitle}>Топ 3:</div>
        <div className={styles.items}>
          {resolutions?.map(({ name, percent }) => (
            <div className={styles.item} key={name + percent}>
              <img src={`/icons/platforms/${getDeviceType(name)}.svg`} className={styles.itemIcon} width={24} height={24} alt="" />
              <span className={styles.itemLabel}>{name}</span>
              <span className={styles.itemValue}>{percent.toFixed()}%</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.title}>Браузеры</div>
        <div className={styles.subtitle}>Топ 3:</div>
        <div className={styles.items}>
          {browsers?.map(({ name, percent }) => {
            return (
              <div className={styles.item} key={name + percent}>
                <img src={`/icons/platforms/${getBrowserName(name)}.svg`} className={styles.itemIcon} width={24} height={24} alt="" />
                <span className={styles.itemLabel}>{name}</span>
                <span className={styles.itemValue}>{percent.toFixed()}%</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardPlatforms;
