import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { promocodeSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './promocode-edit.module.css';

const PromocodeEdit = ({ data, tariffs, promoTypes, onCancel, onSave, onAdd, isAdd }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(promocodeSchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });

  const onSubmit = (values) => {
    const data = { ...formValuesToData(promocodeSchema, values) };

    if (data.type === 'discountPercent') {
      delete(data['discountAmount']);
      delete(data['patternId']);
      delete(data['trialProlongDays']);
    } else if (data.type === 'discountAmount') {
      delete(data['discountPercent']);
      delete(data['patternId']);
      delete(data['trialProlongDays']);
    } else if (data.type === 'trialProlong') {
      delete(data['discountPercent']);
      delete(data['discountAmount']);
      delete(data['patternId']);
    } else if (data.type === 'tariff') {
      delete(data['discountPercent']);
      delete(data['discountAmount']);
      delete(data['trialProlongDays']);
    }

    if (isAdd) onAdd(data);
    else onSave(data);
  };

  const schema = {...promocodeSchema};

  const type = watch('type');
  if (type === 'discountPercent') {
    delete(schema['discountAmount']);
    delete(schema['patternId']);
    delete(schema['trialProlongDays']);
  } else if (type === 'discountAmount') {
    delete(schema['discountPercent']);
    delete(schema['patternId']);
    delete(schema['trialProlongDays']);
  } else if (type === 'trialProlong') {
    delete(schema['discountPercent']);
    delete(schema['discountAmount']);
    delete(schema['patternId']);
  } else if (type === 'tariff') {
    delete(schema['discountPercent']);
    delete(schema['discountAmount']);
    delete(schema['trialProlongDays']);
  }

  const reusable = watch('reusable');
  if (!reusable) {
    delete(schema['usesLimit'])
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        {Object.keys(schema).map(key => {
            const attrs = { ...promocodeSchema[key] };
            if (key === 'type') attrs.options = promoTypes;
            else if (key === 'patternId' && tariffs) {
              attrs.options =
                tariffs
                  .reduce((acc, { patterns }) => [ ...acc, ...patterns], [])
                  .reduce((acc, { id, name }) => [ ...acc, { value: id, label: name }], []);
            }
            return (
              <FillingItem
                {...attrs}
                required={attrs.required ?? false}
                id={key}
                name={t(`vars.${key}`)}
                key={key}
                readOnly={data && key === 'id'}
                errorMessage={t('errors.regexp')}
                register={register}
                control={control}
                error={errors[key] && errors[key].message}
              />
            )
          }
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          type="button"
          viewStyle="tertiary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default PromocodeEdit;
