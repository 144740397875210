import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { tariffDurationSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './pattern-edit.module.css';

const PatternEdit = ({ canAddUsers, data, onCancel, onSave, onAdd, isAdd }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(tariffDurationSchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });

  const onSubmit = (values) => {
    if (isAdd) onAdd(formValuesToData(tariffDurationSchema, values));
    else onSave(formValuesToData(tariffDurationSchema, values));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        {Object.keys(tariffDurationSchema).map(key => {
            if (!canAddUsers && key === 'userPrice') return null;
            return (
              <FillingItem
                {...tariffDurationSchema[key]}
                required={tariffDurationSchema[key].required ?? false}
                id={key}
                name={t(`vars.${key}`)}
                key={key}
                readOnly={data && key === 'id'}
                errorMessage={t('errors.regexp')}
                register={register}
                control={control}
                error={errors[key] && errors[key].message}
              />
            )
          }
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          type="button"
          viewStyle="tertiary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default PatternEdit;
