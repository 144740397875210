import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/LoadingIndicator';
import { Input } from '@forma/forma-ui-kit';
import DashboardScoringHeader from './DashboardScoringHeader';

import styles from './dashboard-scoring.module.css';

const itemsToValues = (items) => items.reduce((acc, current) => ({ ...acc, [current.id]: current.cost }), {});

const DashboardScoring = ({ isLoading, items, onChange }) => {
  const { t } = useTranslation();
  const [ values, setValues ] = useState(items ? itemsToValues(items) : {});

  useEffect(() => {
    if (items) setValues(itemsToValues(items));
  }, [items]);

  const groupedItems = items?.reduce((acc, current) => {
    const next = { ...acc };
    const splittedName = current.id.split('.');
    const name = splittedName[splittedName.length-1];
    next[name] = next[name] ? [ ...next[name], current ] : [ current ];
    return next;
  }, {});

  const handleSave = () => {
    onChange(items.map(({ id, description }) => ({ id, description, cost: values[id] })));
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <DashboardScoringHeader
          onSave={handleSave}
          total={Object.values(values).reduce((acc, current) => (acc + current), 0)}
        />
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <div style={{ margin: '40px' }}>
            <LoadingIndicator />
          </div>
        ) : (
          Object.keys(groupedItems).map(key => (
            <div className={styles.group} key={key}>
              <div className={styles.groupName}>{t(`scoring.${key}`)}</div>
              <div className={styles.groupItems}>
                {groupedItems[key].map(({ id, description }) => (
                  <div className={styles.item} key={id}>
                    <div className={styles.itemName}>{description}</div>
                    <div className={styles.itemValue}>
                      <Input
                        onChange={e => setValues(prev => ({ ...prev, [id]: +(e.target.value.replaceAll(/[^\d]/g, '')) }))}
                        value={values[id] ?? ''}
                        className={styles.input}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardScoring;
