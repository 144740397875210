import { useTranslation } from 'react-i18next';
import Select from 'components/fields/Select';
import PriorityIcon from '../PriorityIcon';

import styles from './priority-select.module.css';

const PrioritySelect = ({ containerClass, className, onChange, value }) => {
  const { t } = useTranslation();

  const priorityOptions = [
    {
      label: <div className={styles.priorityOption}><PriorityIcon priority={400} /> {t('priorities.400')}</div>,
      value: '400',
      displayLabel: <div className={styles.priorityOption}>{t('priority')}: <PriorityIcon priority={400} /> {t('priorities.400')}</div>
    },
    {
      label: <div className={styles.priorityOption}><PriorityIcon priority={300} /> {t('priorities.300')}</div>,
      value: '300',
      displayLabel: <div className={styles.priorityOption}>{t('priority')}: <PriorityIcon priority={300} /> {t('priorities.300')}</div>
    },
    {
      label: <div className={styles.priorityOption}><PriorityIcon priority={200} /> {t('priorities.200')}</div>,
      value: '200',
      displayLabel: <div className={styles.priorityOption}>{t('priority')}: <PriorityIcon priority={200} /> {t('priorities.200')}</div>
    },
    {
      label: <div className={styles.priorityOption}><PriorityIcon priority={100} /> {t('priorities.100')}</div>,
      value: '100',
      displayLabel: <div className={styles.priorityOption}>{t('priority')}: <PriorityIcon priority={100} /> {t('priorities.100')}</div>
    },
  ];

  return (
    <Select
      containerClass={containerClass}
      className={className}
      options={priorityOptions}
      customLabelKey="displayLabel"
      placeholder={t('select_priority')}
      onChange={onChange}
      value={value}
    />
  );
};

export default PrioritySelect;
