import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ButtonsContainer } from '@forma/forma-ui-kit';
import VariableSelectModal from '../VariableSelectModal';

import styles from './variable-add.module.css';

const VariableAddModal = ({
  open, onClose, sides, variablesGroups, availableVariables, onAddVar
}) => {
  const { t } = useTranslation();
  const [ side, setSide ] = useState(null);

  useEffect(() => {
    if (!open) setSide(null);
  }, [open]);

  const handleToggleVar = (data, checked, group) => {
    if (!side) return;
    onAddVar({ id: side.id, name: side.name }, data, checked, group);
  };

  return (
    <>
      <Modal
        open={open}
        title={<span>{t('add_variabe_to_template')}:<br />{t('select_side')}</span>}
        onClose={onClose}
      >
        <ButtonsContainer className={styles.buttons}>
          {sides.map(({ id, name, tattrFolders, tables }) => (
            <Button
              id={`select_side_button_${id}`} // id for onboarding
              viewStyle="tertiary"
              onClick={() => setSide({ id, name, tattrFolders, tables })}
              fullWidth
              key={id}
            >
              {name}
            </Button>
          ))}
        </ButtonsContainer>
      </Modal>
      <VariableSelectModal
        open={!!side}
        selectedVariables={side?.tattrFolders &&
          side.tattrFolders.reduce((acc, current) => [ ...acc, ...Object.values(current.tattrs).map(({ id }) => id) ], [])
        }
        onClose={() => setSide(null)}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        onToggleVar={handleToggleVar}
      />
    </>
  );
};

export default VariableAddModal;
