import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ContextMenu from 'components/ContextMenu';
import { Modal, mock } from '@forma/forma-ui-kit';

import styles from './requests-grid.module.css';


const RequestsGridItem = ({ id, filename, ext, templateAt, user, workspace, onCompleteItem, onDownload }) => {
  const { t } = useTranslation();
  const [ isOptionsOpen, setOptionsOpen ] = useState(false);
  const [ isCompleteOpen, setCompleteOpen ] = useState(false);

  const options = [];
  if (!templateAt) options.push({
    onClick: () => setCompleteOpen(true),
    text: 'Отметить как настроенный'
  });
  options.push({
    onClick: () => onDownload(id, filename, workspace.id),
    text: 'Скачать'
  });

  return (
    <div className={styles.item} onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={mock.filesIcons[ext] ? mock.filesIcons[ext] : mock.filesIcons.other} alt="" />
      </div>
      <div className={styles.name}>
        {filename}
      </div>
      <div className={classNames(styles.status, templateAt && styles.success)}>{templateAt ? 'Настроено' : 'Не настроено'}</div>

      <ContextMenu
        open={isOptionsOpen}
        items={options}
        control={open => (
          <button className={classNames(styles.optionsButton, open && styles.active)}>
            <ReactSVG src="/icons/more.svg" />
          </button>
        )}
        offsetY={12}
        offsetX={0}
        onOpen={() => setOptionsOpen(true)}
        onClose={() => setOptionsOpen(false)}
      />

      <Modal
        open={isCompleteOpen}
        onClose={() => setCompleteOpen}
        title={t('are_you_sure_template_created')}
        buttons={[
          {
            viewStyle: 'tertiary',
            children: t('cancel')
          },
          {
            onClick: () => onCompleteItem(id),
            viewStyle: 'primary',
            children: t('submit'),
          }
        ]}
      >
        {t('done_email_will_be_send')}
        <br /><br />
        {t('user')}: {user.login}
        <br />
        {t('file_name')}: {filename}
      </Modal>
    </div>
  );
};

const RequestsGrid = ({ items, onCompleteItem, onDownload }) => {
  if (!items) return null;

  return (
    <div className={styles.list}>
      {items.map(item => (
        <RequestsGridItem
          {...item}
          onCompleteItem={onCompleteItem}
          onDownload={onDownload}
          key={item.id}
        />
      ))}
    </div>
  );
};

export default RequestsGrid;
