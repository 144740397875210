import { baseApi } from 'store/store';

export const tariffsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTariffs: builder.query({
      query: () => ({
        url: '/tariffsadmin/',
        method: 'GET',
      }),
      providesTags: ['Tariffs']
    }),
    addTariff: builder.mutation({
      query: data => ({
        url: '/tariffsadmin/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Tariffs']
    }),
    getTariffById: builder.query({
      query: id => ({
        url: `/tariffsadmin/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Tariffs', id: id }],
    }),
    updateTariffById: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/tariffsadmin/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Tariffs']
    }),
    removeTariffById: builder.mutation({
      query: id => ({
        url: `/tariffsadmin/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tariffs']
    }),
    getTariffsHistory: builder.query({
      query: () => ({
        url: '/tariffsadmin/instances',
        method: 'GET',
      }),
      providesTags: ['TariffsHistory']
    }),
    updateTariffEnd: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/tariffsadmin/instances/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['TariffsHistory']
    }),
    getTariffsHistoryById: builder.query({
      query: id => ({
        url: `/tariffsadmin/instances/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'TariffsHistory' }, { type: 'TariffsHistory', id: id }],
    }),
    getTariffsHistoryCurrent: builder.query({
      query: data => ({
        url: '/tariffsadmin/instances/current',
        method: 'GET',
        params: data
      }),
      providesTags: [{ type: 'TariffsHistory', id: 'current' }]
    }),
    setTariffPay: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/tariffsadmin/instances/${id}/pay`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['TariffsHistory']
    }),
    setTariffWorkspace: builder.mutation({
      query: ({ id, ...data }) => ({
        url: '/tariffsadmin/choose/',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['TariffsHistory']
    }),
  })
});

export const {
  useGetTariffsQuery,
  useAddTariffMutation,
  useGetTariffByIdQuery,
  useUpdateTariffByIdMutation,
  useRemoveTariffByIdMutation,
  useGetTariffsHistoryQuery,
  useUpdateTariffEndMutation,
  useGetTariffsHistoryByIdQuery,
  useGetTariffsHistoryCurrentQuery,
  useSetTariffPayMutation,
  useSetTariffWorkspaceMutation
} = tariffsApi;
