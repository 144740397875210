import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import DashboardRegisters from 'components/DashboardRegisters';

import { useGetAnalyticsRegistersQuery } from 'store/analytics/analyticsApi';

const Registers = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAnalyticsRegistersQuery();

  return (
    <AdminLayout
      title="Количество регистраций"
      breadcrumbs={[
        { name: t('dashboard'), href: '/' },
        { name: 'Количество регистраций' }
      ]}
    >
      <PageTitle>{t('site_name') + ' – Количество регистраций'}</PageTitle>
      <DashboardRegisters items={data} isLoading={isLoading} />
    </AdminLayout>
  );
};

export default Registers;