import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, nanoid } from '@forma/forma-ui-kit';

import styles from './select-switcher.module.css';

const SelectSwitcher = ({
  containerId, id = nanoid(), label, name, checked = false, onChange, className, iconClassName, icon, onClickEdit
}) => {
  const { t } = useTranslation();

  return (
    <label className={classNames(styles.root, className)} htmlFor={id} id={containerId}>
      {onClickEdit && (
        <Button
          viewStyle="text"
          className={styles.edit}
          iconClassName={styles.editIcon}
          icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
          title={t('edit')}
          onClick={onClickEdit}
        />
      )}
      <span className={styles.text}>{label}</span>
      <input
        type="checkbox"
        id={id}
        name={name}
        className={styles.input}
        onChange={e => onChange(e.target.checked)}
        checked={checked}
      />
      {icon ? icon(checked) : <span className={classNames(styles.icon, iconClassName)}></span>}
    </label>
  );
};

export default SelectSwitcher;
