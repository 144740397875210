import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  viewport: '',
  isMenuOpen: localStorage.getItem('menu_open') === 'true'
};

export const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
      localStorage.setItem('menu_open', action.payload);
    },
  }
});

export default commonSlice.reducer;

export const { setMenuOpen } = commonSlice.actions;

export const selectMenuOpen = (state) => state.common.isMenuOpen;
