import { useState } from 'react';
import { Tabs } from '@forma/forma-ui-kit';
import VariableFields from './VariableFields';
import VariableGroups from './VariableGroups';
import VariableTemplates from './VariableTemplates';

import styles from './variable-edit.module.css';

// data, folders, sections, variblesTypes, onCancel, onSave, isAdd, onAdd
const VariableEdit = ({ templates, ...props }) => {
  const [ currentTab, setCurrentTab ] = useState('edit');

  return (
    <div className={styles.root}>
    <Tabs
      contentClassName={styles.tabContent}
      current={currentTab}
      onChange={setCurrentTab}
      tabViewStyle="tertiary"
      items={[
        {
          id: 'edit',
          name: 'Основное',
          children: (
            <div key="edit">
              <VariableFields
                {...props}
              />
            </div>
          )
        },
        {
          id: 'groups',
          name: 'Группы',
          children: (
            <div key="groups">
              <VariableGroups
                {...props}
              />
            </div>
          )
        },
        {
          id: 'templates',
          name: 'Шаблоны',
          children: (
            <div key="templates">
              <VariableTemplates
                items={templates}
              />
            </div>
          )
        }
      ]}
    />
    </div>
  );
};

export default VariableEdit;
