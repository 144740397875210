import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ResourcesList from 'components/ResourcesList';

import {
  useGetResourcesListQuery,
  useAddResourceMutation,
  useUpdateResourceMutation,
  useRemoveResourceMutation
} from 'store/resources/resourcesApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Resources = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ search, setSearch ] = useState('');

  const { resources } = useGetResourcesListQuery(undefined, {
    selectFromResult: ({ data }) => ({
      resources: data?.filter(({ id }) => !!id.match(search)),
    }),
  });

  const [ addResource ] = useAddResourceMutation();
  const [ updateResource ] = useUpdateResourceMutation();
  const [ removeResource ] = useRemoveResourceMutation();

  const handleSaveResource = async ({ id, lang, value }) => {
    const res = await updateResource({ id, lang, value });
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleAddResource = async ({ id, lang, value }) => {
    const res = await addResource({ id, lang, value });
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleRemoveResource = async ({ id, lang }) => {
    const res = await removeResource({ id, lang });
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('translations')}>
      <PageTitle>{t('site_name') + ' – ' + t('translations')}</PageTitle>
      <ResourcesList
        items={resources}
        onAdd={handleAddResource}
        onSave={handleSaveResource}
        onRemove={handleRemoveResource}
        onSearch={setSearch}
      />
    </AdminLayout>
  )
};

export default Resources;