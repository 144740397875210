import { format } from 'date-fns';
import WorkspaceSearchFilters from './WorkspaceSearchFilters';

import styles from './workspace-search-details.module.css';


const WorkspaceSearchDetails = ({ items, createdAt, createdTo, onFilter }) => {
  const dates = items?.reduce((acc, current) => (
    { ...acc, [current.createdAt.split('T')[0]]: acc[current.createdAt.split('T')[0]] ? [ ...acc[current.createdAt.split('T')[0]], current ] : [ current ] }
  ), {});

  return (
    <div className={styles.root}>
      <WorkspaceSearchFilters from={createdAt} to={createdTo} onFilter={onFilter} />
      <div className={styles.right}>
        {dates && Object.entries(dates).map(([ date, items ]) => (
          <div className={styles.block} key={date}>
            <div className={styles.blockTitle}>{format(new Date(date + 'T00:00:01'), 'dd.MM.yyyy')}</div>
            <div className={styles.items}>
              {items?.map(({ id, createdAt, data }) => (
                <div className={styles.row} key={id}>
                  <div className={styles.date}>{format(new Date(createdAt), 'HH:mm')}</div>
                  <div className={styles.content}>{data}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkspaceSearchDetails;
