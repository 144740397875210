import { baseApi } from 'store/store';

export const schemasApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTemplateSectionsVariables: builder.query({
      query: () => ({
        url: '/schemas/strictattrs',
        method: 'GET',
      }),
      transformResponse: result => result.schema.reduce((prev, current) => ({ ...prev, [current.id]: current }), {}),
      providesTags: [{ type: 'Schemas', id: 'Variables' }]
    }),
    getTemplateSectionsGroups: builder.query({
      query: () => ({
        url: '/schemas/strictattrshierarhy',
        method: 'GET',
      }),
      transformResponse: result => result.schema.reduce((prev, current) => ({ ...prev, [current.scope]: current }), {}),
      providesTags: [{ type: 'Schemas', id: 'Sections' }]
    }),
    getTemplateVariablesGroups: builder.query({
      query: () => ({
        url: '/schemas/strictattrsfolders',
        method: 'GET',
      }),
      transformResponse: result => result.schema,
      providesTags: [{ type: 'Schemas', id: 'Folders' }]
    }),
    getTemplateVariablesTypes: builder.query({
      query: () => ({
        url: '/schemas/types',
        method: 'GET',
      }),
      transformResponse: result => result.schema,
      providesTags: [{ type: 'Schemas', id: 'Types' }]
    }),
    getPermissions: builder.query({
      query: () => ({
        url: '/schemas/permissions',
        method: 'GET'
      }),
      transformResponse: result => result.schema.permissions
    })
  })
});

export const {
  useGetTemplateSectionsVariablesQuery,
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateVariablesGroupsQuery,
  useGetTemplateVariablesTypesQuery,
  useGetPermissionsQuery
} = schemasApi;
