import { Trans } from 'react-i18next';
import { Tooltip } from '@forma/forma-ui-kit';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-deal-time.module.css';

const DashboardDealTime = ({ value }) => (
  <DashboardBlock
    title="Длина сделки"
    buttons={
      <Tooltip
        control={<img src="/icons/question-circle.svg" alt="" />}
      >
        <div style={{ width: '226px' }}>
          От момента регистрации до оплаты тарифа
        </div>
      </Tooltip>
    }
  >
    <div className={styles.root}>
      <div className={styles.value}>
        <Trans
          i18nKey="count_days"
          count={value}
        />
        {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardDealTime;
