import { baseApi } from 'store/store';

export const analyticsApi = baseApi.enhanceEndpoints({ addTagTypes: 'Analytics' }).injectEndpoints({
  endpoints: builder => ({
    getAnalytics: builder.query({
      query: data => ({
        url: '/analytics',
        method: 'GET',
        params: data
      }),
      providesTags: ['Analytics']
    }),
    getAnalyticsPayments: builder.query({
      query: () => ({
        url: '/analytics/payments',
        method: 'GET'
      })
    }),
    getAnalyticsRegisters: builder.query({
      query: () => ({
        url: '/analytics/registers',
        method: 'GET'
      }),
    }),
    getWorkspaceAnalytics: builder.query({
      query: ({ wid, ...data }) => ({
        url: `/analytics/${wid}`,
        method: 'GET',
        params: data
      }),
      providesTags: (result, error, { wid }) => [{ type: 'WorkspaceAnalytics', id: wid }],
    }),
    getWorkspaceAnalyticsGraph: builder.query({
      query: ({ wid, ...data }) => ({
        url: `/analytics/${wid}/graph`,
        method: 'GET',
        params: data
      }),
      providesTags: (result, error, { wid }) => [{ type: 'WorkspaceAnalyticsGraph', id: wid }],
    }),
    getWorkspaceAnalyticsCRM: builder.query({
      query: ({ wid, ...data }) => ({
        url: `/analytics/${wid}/crm`,
        method: 'GET',
        params: data
      }),
      providesTags: (result, error, { wid }) => [{ type: 'WorkspaceAnalyticsCrm', id: wid }],
    }),
    getWorkspaceAnalyticsDevices: builder.query({
      query: ({ wid, uid }) => ({
        url: `/analytics/${wid}/devices/${uid}`,
        method: 'GET'
      }),
      providesTags: (result, error, { uid }) => [{ type: 'WorkspaceAnalyticsDevices', id: uid }],
    }),
    getWorkspaceAnalyticsSearch: builder.query({
      query: ({ wid, ...data }) => ({
        url: `/analytics/${wid}/activity`,
        method: 'GET',
        params: data
      }),
      providesTags: (result, error, { wid }) => [{ type: 'WorkspaceAnalyticsSearch', id: wid }],
    }),
    getFavouriteWorkspaces: builder.query({
      query: data => ({
        url: '/analytics/featured',
        method: 'GET',
      }),
      providesTags: ['WorkspacesFavourites']
    }),
    addFavouriteWorkspace: builder.mutation({
      query: data => ({
        url: '/analytics/featured',
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, { wid }) => [{ type: 'TariffsHistory', id: 'current' }, { type: 'WorkspaceAnalytics', id: wid }],
    }),
    removeFavouriteWorkspace: builder.mutation({
      query: data => ({
        url: '/analytics/featured',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: (result, error, { wid }) => [{ type: 'TariffsHistory', id: 'current' }, { type: 'WorkspaceAnalytics', id: wid }],
    }),
  })
});

export const {
  useGetAnalyticsQuery,
  useGetAnalyticsPaymentsQuery,
  useGetAnalyticsRegistersQuery,
  useGetWorkspaceAnalyticsQuery,
  useGetWorkspaceAnalyticsGraphQuery,
  useGetWorkspaceAnalyticsCRMQuery,
  useGetWorkspaceAnalyticsDevicesQuery,
  useGetWorkspaceAnalyticsSearchQuery,
  useGetFavouriteWorkspacesQuery,
  useAddFavouriteWorkspaceMutation,
  useRemoveFavouriteWorkspaceMutation,
} = analyticsApi;
