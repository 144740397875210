import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { strictattrsSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './variable-fields.module.css';

const minSchema = [ 'id', 'type', 'name', 'description' ];

const VariableFields = ({ data, folders, sections, variblesTypes, onCancel, onSave, isAdd, onAdd }) => {
  const { t } = useTranslation();
  const [ schema, setSchema ] = useState(minSchema);

  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(strictattrsSchema, data)
  });

  const type = watch('type');
  useEffect(() => {
    if (type && variblesTypes && variblesTypes[type]) {
      const selectedSchema = Object.keys(variblesTypes[type].properties);

      const inWordsIndex = selectedSchema.findIndex(key => key === 'dataInWords');
      if (inWordsIndex !== -1) selectedSchema.splice(inWordsIndex, 1); 
      const caseIndex = selectedSchema.findIndex(key => key === 'dataCase');
      if (caseIndex !== -1) selectedSchema.splice(caseIndex, 1); 
    
      setSchema(minSchema.concat(selectedSchema.filter((item) => minSchema.indexOf(item) < 0)));
    }
  }, [type, variblesTypes])

  const onSubmit = (values) => {
    isAdd ? onAdd(formValuesToData(strictattrsSchema, values)) : onSave(formValuesToData(strictattrsSchema, values))
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        {schema.map(key => {
            const attrs = { ...strictattrsSchema[key] };
            if (key === 'values') {
              attrs.inputWidth = '350';
              if (data && data[key]) {
                attrs.options = Object.values(data[key]).reduce((prev, current) => [ ...prev, { value: current, label: current } ], []);
              }
            } else if (key === 'type' && variblesTypes) {
              attrs.options = Object.keys(variblesTypes).reduce((prev, current) => [ ...prev, { value: current, label: variblesTypes[current].name } ], []);
            } else if (key === 'sections' && sections) {
              attrs.options = Object.keys(sections).reduce((prev, current) => [ ...prev, { value: current, label: sections[current].name } ], []);
            } else if (key === 'folders' && folders) {
              attrs.options = Object.keys(folders).reduce((prev, current) => [ ...prev, { value: current, label: folders[current].name } ], []);
            }

            return (
              <FillingItem
                {...attrs}
                required={attrs.required ?? false}
                id={key}
                name={t(`vars.${key}`)}
                key={key}
                readOnly={data && key === 'id'}
                errorMessage={t('errors.regexp')}
                register={register}
                control={control}
                error={errors[key] && errors[key].message}
              />
            )
          }
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          viewStyle="tertiary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default VariableFields;
