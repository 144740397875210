import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-source.module.css';

const sources = {
  'link': { icon: '/icons/social/link.svg', name: 'Прямая ссылка' },
  'vcru': { icon: '/icons/social/document.svg', name: 'vc.ru' },
  'yandex': { icon: '/icons/social/yandex.svg', name: 'Яндекс' },
  'vk': { icon: '/icons/social/vk.svg', name: 'Вконтакте' },
  'instagram': { icon: '/icons/social/instagram.svg', name: 'Instaram ' },
  'telegram': { icon: '/icons/social/telegram.svg', name: 'Telegram' },
  'facebook': { icon: '/icons/social/facebook.svg', name: 'Facebook' },
}

const WorkspaceSource = ({ items }) => (
  <WorkspaceBlock title="Источник">
    {items?.map(({ key, value }) => (
      <div className={styles.item}>
        {sources[key] ? (
          <><img src={sources[key].icon} alt="" /> {sources[key].name}</>
        ) : (
          key
        )}
      </div>
    ))}
  </WorkspaceBlock>
);

export default WorkspaceSource;
