import { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  subMonths,
  addMonths,
  setMonth
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import Select from 'components/fields/Select';

import styles from './statistic-calendar.module.css';

const StatisticCalendar = ({ month, year, items, onChangeMonth }) => {
  const { t } = useTranslation();

  const currentDate = new Date();
  const activeDate = month ? new Date(month) : new Date();
  const setActiveDate = (date) => onChangeMonth(date.toISOString());

  const getWeekDaysNames = () => {
    const weekStartDate = startOfWeek(activeDate, { weekStartsOn: 1 });
    const weekDays = [];

    for (let day = 0; day < 7; day++) {
      const name = format(addDays(weekStartDate, day), 'EEEEEE', { locale: ru });
      weekDays.push(<div className={styles.weekName} key={name}>{name}</div>);
    }

    return <div className={styles.weekContainer}>{weekDays}</div>;
  };

  const generateDatesForCurrentWeek = (date, activeDate) => {
    const week = [];
    for (let day = 0; day < 7; day++) {
      const currentDate = addDays(date, day);
      const itemDay = items?.find(({ date }) => isSameDay(new Date(date), currentDate));
      week.push(
        <div className={classNames(
          styles.day,
          !isSameMonth(currentDate, activeDate) && styles.inactiveDay,
          isSameDay(currentDate, new Date()) && styles.today
        )} key={`${itemDay}_${day}`}>
          {format(currentDate, 'dd')} <span className={styles.dayCount}>{itemDay?.count}</span>
        </div>
      );
    }
    return <Fragment key={date}>{week}</Fragment>;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = startOfMonth(activeDate);
    const endOfTheSelectedMonth = endOfMonth(activeDate);
    const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
    const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn: 1 });

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(generateDatesForCurrentWeek(currentDate, activeDate));
      currentDate = addDays(currentDate, 7);
    }

    return <div className={styles.weekContainer}>{allWeeks}</div>;
  };

  return (
    <section>
      <div className={styles.header}>
        <Button
          viewStyle="tertiary"
          className={styles.button}
          iconClassName={styles.buttonIcon}
          onClick={() => setActiveDate(subMonths(activeDate, 1))}
          icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
        />
        <Select
          containerClass={styles.select}
          options={
            Array.from({ length: 12 }, (k, index) => {
              const date = subMonths(currentDate, index);
              return {
                label: `${t(`month_names.${date.getMonth()+1}`)} ${date.getFullYear()}`,
                value: String(date.getMonth())
              }
            }).reverse()
          }
          value={String(activeDate.getMonth())}
          onChange={(e) => setActiveDate(setMonth(activeDate, e.target.value))}
        />
        <Button
          viewStyle="tertiary"
          className={styles.button}
          iconClassName={styles.buttonIcon}
          onClick={() => setActiveDate(addMonths(activeDate, 1))}
          icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
        />
      </div>

      {getWeekDaysNames()}
      {getDates()}
    </section>
  );
};

export default StatisticCalendar;
