import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { TableRow, TableCell } from 'components/Table';
import { Button, useDebounce, RemoveModal } from '@forma/forma-ui-kit';
import VariableItem from './VariableItem';

import styles from './variables-groups.module.css';

const GroupItem = ({
  id, name, attrs: _attrs, variables, onClickEdit, onRemove, onSaveVariable, onMoveVariables, sections, folders, variblesTypes
}) => {
  const { t } = useTranslation();
  const [ isOpen, setOpen ] = useState(false);
  const [ attrs, setAttrs ] = useState(null);

  useEffect(() => {
    setAttrs(_attrs);
  }, [_attrs]);

  const saveMoved = useDebounce(attrs => onMoveVariables(attrs), 500);

  const handlePaste = (source, target) => {
    setAttrs(prev => {
      const next = [ ...prev ];
      next.splice(target.index, 0, source.id);
      saveMoved(next);
      return next;
    });
  };

  const handleCut = (source) => {
    setAttrs(prev => {
      const next = [ ...prev ];
      if (next[source.index] === source.id) next.splice(source.index, 1);
      // needs for case then element pasted upper prev position (sorting inside group)
      else if (next[source.index+1] === source.id) next.splice(source.index+1, 1);
      saveMoved(next);
      return next;
    });
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.id} title={t('id')} onClick={() => setOpen(!isOpen)}>
          {id}
        </TableCell>
        <TableCell className={styles.name} title={t('name')} onClick={() => setOpen(!isOpen)}>
          {name}
          <ReactSVG className={classNames(styles.nameArrow, isOpen && styles.open)} src="/icons/dropdown.svg" wrapper="span" />
        </TableCell>
        <TableCell className={styles.buttons}>
          <Button viewStyle="text" onClick={() => onClickEdit(id)}>
            <ReactSVG src="/icons/edit.svg" wrapper="span" />
          </Button>
          <RemoveModal
            control={<Button viewStyle="text" className={styles.remove}><ReactSVG src="/icons/trash.svg" wrapper="span" /></Button>}
            title={t('delete_group')}
            onRemove={() => onRemove(id)}
            itemName={name}
          />
        </TableCell>
      </TableRow>
      {isOpen &&
        <TableRow className={styles.subitems}>
          <TableCell className={styles.subitemsCell} colSpan={3}>
            <div className={styles.list}>
              {attrs?.map((varId, index) => {
                const item = variables.find(item => varId === item.id);
                if (!item) return null;
                return (
                  <VariableItem
                    item={item}
                    groupId={id}
                    index={index}
                    onCut={handleCut}
                    onPaste={handlePaste}
                    onSave={onSaveVariable}
                    folders={folders}
                    sections={sections}
                    variblesTypes={variblesTypes}
                    key={index}
                  />
                );
              })}
            </div>
          </TableCell>
        </TableRow>
      }
    </>
  );
};

export default GroupItem;
