import { baseApi } from 'store/store';
import { setAdminData, setUserData, setUserPermissions } from './userSlice';
import { setToken } from '../auth/authSlice';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminData: builder.query({
      query: () => ({
        url: '/adminusers/current',
        method: 'GET'
      }),
      providesTags: ['Admin'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAdminData(data));
        } catch (error) {}
      }
    }),
    getProfileData: builder.query({
      query: () => ({
        url: '/user/',
        method: 'GET'
      }),
      providesTags: ['User'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserData(data));
        } catch (error) {}
      }
    }),
    setProfileData: builder.mutation({
      query: data => ({
        url: '/user/',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['User'],
    }),
    setProfilePassword: builder.mutation({
      query: data => ({
        url: '/user/password',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
          localStorage.setItem('token', data.token);
        } catch (error) {}
      }
    }),
    getProfilePermissions: builder.query({
      query: () => ({
        url: `/user/permissions`,
        method: 'GET'
      }),
      transformResponse: result => result.permissions,
      providesTags: ['UserPermissions'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserPermissions(data));
        } catch (error) {}
      }
    }),

    getGroupsList: builder.query({
      query: () => ({
        url: `/usergroups/`,
        method: 'GET'
      }),
      transformResponse: result => result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {}),
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation({
      query: data => ({
        url: `/usergroups/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroup: builder.mutation({
      query: data => ({
        url: `/usergroups/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation({
      query: id => ({
        url: `/usergroups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Groups'],
    }),

    getUsersList: builder.query({
      query: data => ({
        url: `/users/`,
        method: 'GET',
        params: data
      }),
      providesTags: ['Users'],
    }),
    getUserData: builder.query({
      query: id => ({
        url: `/users/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, data) => [{ type: 'User', id: data.id }],
    }),
    setUserData: builder.mutation({
      query: data => ({
        url: `/users/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'User', id: data.id }],
    }),
    addUser: builder.mutation({
      query: data => ({
        url: `/users/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users'],
    }),
    inviteUser: builder.mutation({
      query: data => ({
        url: `/users/invites`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users'],
    }),
    getUserGroups: builder.query({
      query: id => ({
        url: `/users/${id}/groups`,
        method: 'GET'
      }),
      // providesTags: (result, error, id) => [{ type: 'UserGroup', id: id }],
    }),
    setUserGroups: builder.mutation({
      query: data => ({
        url: `/users/${data.id}/groups`,
        method: 'POST',
        body: data
      }),
      // invalidatesTags: (result, error, data) => [{ type: 'UserGroup', id: data.id }],
    }),
  }),
});

export const {
  useGetAdminDataQuery,
  useGetProfileDataQuery,
  useSetProfileDataMutation,
  useSetProfilePasswordMutation,
  useGetProfilePermissionsQuery,
  useGetGroupsListQuery,
  useGetUserGroupsQuery,
  useGetUsersListQuery,
  useGetUserDataQuery,
  useSetUserDataMutation,
  useAddUserMutation,
  useInviteUserMutation,
  useSetUserGroupsMutation,
  useAddGroupMutation,
  useUpdateGroupMutation
} = userApi;
