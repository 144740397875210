import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { Input, useDebounce } from '@forma/forma-ui-kit';

import styles from './search-input.module.css';

const SearchInput = ({ onSearch, className, placeholder }) => {
  const { t } = useTranslation();
  const [ searchValue, setSearchValue ] = useState('');

  const handleChangeSearch = useDebounce(e => {
    setSearchValue(e.target.value);
    onSearch && onSearch(e.target.value);
  }, 300);

  return (
    <div className={classnames(styles.search, (searchValue && searchValue.length) && styles.active, className)}>
      <ReactSVG className={styles.searchIcon} src="/icons/search.svg" />
      <Input
        type="text"
        className={styles.searchInput}
        placeholder={placeholder ? placeholder : t('search')}
        onChange={handleChangeSearch}
        showClearButton={true}
      />
    </div>
  );
};

export default SearchInput;
