import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import { locales } from 'i18n.js';

import styles from './langswitch.module.css';

const LangSwitch = () => {
  const { i18n } = useTranslation();

  if (!i18n || !i18n.resolvedLanguage || !locales) return null;

  return (
    <div className={styles.root}>
      {locales.map(lang => {
        return (
          <Button
            viewStyle="secondary"
            className={classnames(styles.button, i18n.resolvedLanguage === lang && styles.active)}
            onClick={() => i18n.changeLanguage(lang)}
            shadow
            key={lang}
          >
            {lang}
          </Button>
        );
      })}
    </div>
  );
};

export default LangSwitch;
