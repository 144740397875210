import classNames from 'classnames';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-statistic.module.css';

const DashboardStatistic = ({ items }) => (
  <DashboardBlock>
    <div className={styles.root}>
      {items?.map(({ id, name, color, count}) => (
        <div className={styles.item} key={id}>
          <div className={classNames(styles.itemTitle)} style={{ background: color }}>{name}</div>
          <div className={styles.itemValue}>{count}</div>
        </div>
      ))}
    </div>
  </DashboardBlock>
);

export default DashboardStatistic;
