import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './logo.module.css';

const Logo = ({ showText, className }) => {
  const { t } = useTranslation();
  const [ isHover, setIsHover ] = useState(false);

  return (
    <Link to="/" className={classnames(styles.link, className)} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <img className={styles.image} src={isHover ? '/images/small_logo_animated.svg' : '/images/small_logo.svg'} alt={t('main_company_name')} />
      {showText &&
        <img className={styles.imageText} src="/images/title.svg" alt={t('main_company_name')} />
      }
    </Link>
  );
}

export default Logo;