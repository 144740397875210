import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFromTo } from 'components/Pagination/usePagination';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import WorkspacesList from 'components/WorkspacesList';

import {
  useGetTariffsHistoryCurrentQuery,
  useGetTariffsQuery,
  useSetTariffPayMutation,
  useSetTariffWorkspaceMutation,
  useUpdateTariffEndMutation
} from 'store/tariffs/tariffsApi';
import { useGetTokenMutation, useRemoveWorkspaceMutation } from 'store/workspaces/workspacesApi';
import {
  useRemoveFavouriteWorkspaceMutation,
  useAddFavouriteWorkspaceMutation
} from 'store/analytics/analyticsApi';
import { setNotification } from 'store/notifications/notificationsSlice';
import { useDownloadTcRequestsArchiveMutation } from 'store/tcRequests/tcRequestsApi';

const siteUrl = process.env.REACT_APP_LK_URL;

const Workspaces = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [params, setParams] = useState({});

  const { data: tariffs } = useGetTariffsQuery();
  const { data: workspaces, isFetching: isLoading, refetch: getTariffsRefetch } = useGetTariffsHistoryCurrentQuery({ ...params, ...getFromTo(page, 100) });
  const [ getToken ] = useGetTokenMutation();
  const [ setTariffPay ] = useSetTariffPayMutation();
  const [ setTariffWorkspace ] = useSetTariffWorkspaceMutation();
  const [ downloadTcRequestsArchive ] = useDownloadTcRequestsArchiveMutation();
  const [ addFavourite ] = useAddFavouriteWorkspaceMutation();
  const [ removeFavourite ] = useRemoveFavouriteWorkspaceMutation();
  const [ removeWorkspace ] = useRemoveWorkspaceMutation();
  const [ updateTariffEnd ] = useUpdateTariffEndMutation();

  const handleChangeSearch = (value) => {
    setParams(prev => ({ ...prev, loginOrInn: value }));
  };

  const handleChangeFilter = (params) => {
    setParams(prev => ({ ...prev, ...params }));
  };

  const handleClickSignAs = (id) => {
    getToken({ wid: id }).then(result => {
      if (result.data?.token) window.open(`${siteUrl}/auth?token=${result.data.token}`, '_blank');
    });
  };

  const handleChangePage = (page) => {
    navigate(location.pathname + '?page=' + page + location.hash);
  };

  const handleSetPaid = async (data) => {
    const res = await setTariffPay(data);
    if (res && !res.error) {
      dispatch(setNotification(t('tariff_mark_paid'), 'SUCCESS'));
    }
  };

  const handleChangeTariff = (workspaceId, { pattern, tariff, addUsersCount }) => {
    setTariffWorkspace({
      wid: workspaceId,
      tariffId: tariff.id,
      patternId: pattern.id,
      addUsersCount: addUsersCount && Number(addUsersCount),
      // paid: true
    });
  };

  const handleClickDownloadRequests = (workspaceId) => {
    downloadTcRequestsArchive({ wid: workspaceId, notProcessed: true }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('tcrequest_file')} ${workspaceId}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const handleRemoveWorkspace = (id) => {
    removeWorkspace(id);
    getTariffsRefetch();
    dispatch(setNotification(t('workspace_was_deleted'), 'ERROR'))
  };

  const handleProlongTariff = (id, dtTo) => {
    updateTariffEnd({ id, dtTo });
  };

  return (
    <AdminLayout title={t('workspaces')}>
      <PageTitle>{t('site_name') + ' – ' + t('workspaces')}</PageTitle>
      <WorkspacesList
        isLoading={isLoading}
        featured={!!(params?.featured)}
        items={workspaces?.tariffInstances}
        workspacesCount={workspaces?.workspacesCount}
        usersCount={workspaces?.usersCount}
        onSearch={handleChangeSearch}
        onFilter={handleChangeFilter}
        tariffs={tariffs}
        onClickSignAs={handleClickSignAs}
        onClickDownloadRequests={handleClickDownloadRequests}
        onSetPaid={handleSetPaid}
        onChangeTariff={handleChangeTariff}
        onAddFavourite={wid => addFavourite({ wid })}
        onRemoveFavourite={wid => removeFavourite({ wid })}
        onRemove={handleRemoveWorkspace}
        onProlongTariff={handleProlongTariff}
        pagination={{
          count: workspaces?.count,
          onChange: handleChangePage,
          page: Number(page),
          size: 100
        }}
      />
    </AdminLayout>
  )
};

export default Workspaces;