import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  closestCorners,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import TaskCreateModal from 'components/KanbanBoard/TaskCreateModal';
import KanbanBoardHeader from './KanbanBoardHeader';
import KanbanColumn from './KanbanColumn';
import KanbanCard from './KanbanCard';

import styles from './kanban-board.module.css';

const KanbanBoard = ({ admins, items, onChange, isAddLoading, onFilter }) => {
  const [ isShowAdd, setShowAdd ] = useState(null);
  const [ filters, setFilters ] = useState({});
  const [ columns, setColumns ] = useState(items);
  const [ overColumn, setOverColumn ] = useState(null);
  const [ activeId, setActiveId ] = useState(null);

  useEffect(() => {
    setColumns(items);
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const handleClickAdd = () => {
    setShowAdd(true);
  };

  const handleChangeSearch = (value) => {
    setFilters(prev => ({ ...prev, login: value }));
  };

  const handleChangeFilter = (params) => {
    setFilters(prev => ({ ...prev, ...params }));
    onFilter(params);
  };

  const findColumn = (columns, id) => {
    if (!id) return null;
    const column = columns.find(({ position }) => position === id);
    if (column) return column;
    return columns.find(({ tasks }) => tasks.find(task => id === task.id));
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  }

  const handleDragOver = (event) => {
    const { active, over, delta } = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(columns, activeId);
    const overColumn = findColumn(columns, overId);

    if (!activeColumn || !overColumn) return null;

    setOverColumn(overColumn.position);

    if (activeColumn === overColumn) return null;

    setColumns((prevState) => {
      const activeItems = activeColumn.tasks;
      const overItems = overColumn.tasks;
      const activeIndex = activeItems.findIndex((i) => i.id === activeId);
      const overIndex = overItems.findIndex((i) => i.id === overId);
      const newIndex = () => {
        const putOnBelowLastItem = overIndex === overItems.length - 1 && delta.y > 0;
        const modifier = putOnBelowLastItem ? 1 : 0;
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      };

      return prevState.map((c) => {
        if (c.position === activeColumn.position) {
          return { ...c, tasks: activeItems.filter((i) => i.id !== activeId) };
        } else if (c.position === overColumn.position) {
          return { ...c, tasks: [
            ...overItems.slice(0, newIndex()),
            activeItems[activeIndex],
            ...overItems.slice(newIndex(), overItems.length)
          ] };
        } else {
          return c;
        }
      });
    });
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(columns, activeId);
    const overColumn = findColumn(columns, overId);

    setActiveId(null);

    if (!activeColumn || !overColumn || activeColumn !== overColumn) {
      return null;
    }
    const activeIndex = activeColumn.tasks.findIndex((i) => i.id === activeId);
    const overIndex = overColumn.tasks.findIndex((i) => i.id === overId);
    if (activeIndex !== overIndex) {
      setColumns((prevState) => {
        return prevState.map((column) => {
          if (column.position === activeColumn.position) {
            return { ...column, tasks: arrayMove(overColumn.tasks, activeIndex, overIndex) };
          } else {
            return column;
          }
        });
      });
    }

    setOverColumn(null);

    const oldColumn = findColumn(items, activeId);
    if (oldColumn.position !== overColumn.position) {
      const params = { id: activeId };
      params.position = overColumn.position;
      const result = await onChange(params);
      if (result.error) setColumns(items);
    }
  };

  const positions = columns?.map(({ position }) => position);
  const activeTask = columns?.flatMap(({ tasks }) => tasks).find(({ id }) => id === activeId);

  return (
    <div className={styles.root}>
      <KanbanBoardHeader
        onClickAdd={handleClickAdd}
        onSearch={handleChangeSearch}
        onFilter={handleChangeFilter}
        admins={admins}
        items={items}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <div className={classNames(styles.columns, filters.impossible && styles.fiveCols)}>
          {columns?.map(({ position, tasks }) => (
            <KanbanColumn
              admins={admins}
              positions={positions}
              position={position}
              tasks={tasks}
              filters={filters}
              isOver={overColumn === position}
              onChangeItem={onChange}
              key={position}
            />
          ))}
        </div>

        <DragOverlay>
          {activeId ? (
            <KanbanCard admins={admins} positions={positions} data={activeTask} />
          ): null}
        </DragOverlay>
      </DndContext>
      <TaskCreateModal
        open={isShowAdd}
        onClose={() => setShowAdd(false)}
        isLoading={isAddLoading}
        admins={admins}
      />
    </div>
  );
};

export default KanbanBoard;
