import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { Modal, Button, ButtonsContainer } from '@forma/forma-ui-kit';
import VariationsBanner from './VariationsBanner';
import VariationEdit from '../VariationEdit';
import VariationsList from '../VariationsList';

import styles from './variations-select-modal.module.css';

const VariationsSelectModal = ({
  open, onClose, sides, sideId, onCreate, onSelect
}) => {
  const { t } = useTranslation();
  const [ data, setData ] = useState(null);
  const [ side, setSide ] = useState(null);

  useEffect(() => {
    if (!sideId) return;
    const side = sides.find(({ id }) => sideId === id);
    if (!side) return;
    setSide(side);
  }, [sides, sideId]);

  const handleClose = () => {
    onClose();
    setData(null);
    if (!sideId) setSide(null);
  };

  const handleClickItem = (data) => {
    if (!side) return;
    onSelect(side.id, data);
    handleClose();
  };

  if (!side) return (
    <Modal
      size="small"
      open={open}
      title={<span>{t('add_variations_block')}:<br />{t('select_side')}</span>}
      onClose={handleClose}
    >
      <ButtonsContainer className={styles.buttons}>
        {sides.map(side => (
          <Button
            // id={`select_table_button_${id}`} // id for onboarding
            viewStyle="tertiary"
            onClick={() => setSide(side)}
            fullWidth
            key={side.id}
          >
            {side.name}
          </Button>
        ))}
      </ButtonsContainer>
    </Modal>
  );

  return (
    <Modal
      size="large"
      open={open}
      title={t('add_variations_block')}
      onClose={handleClose}
      buttonsClassName={styles.modalButtons}
      buttons={data ? [
        {
          children: t('go_back'),
          viewStyle: 'tertiary',
          closing: false,
          onClick: () => setData(null)
        },
        {
          children: t('create_question'),
          viewStyle: 'primary',
          disabled: !(data.title?.length >= 3 && data?.variants.length && data.variants.every(({ key }) => key.length >= 2)),
          onClick: () => onCreate(side.id, data)
        }
      ] : [
        {
          children: t('create_new_question'),
          viewStyle: 'primary',
          closing: false,
          onClick: () => setData({ id: nanoid(8), title: '', multiple: false, parents: [], variants: [] })
        }
      ]}
    >
      {data ? (
        <div className={styles.modalSelect}>
          <VariationEdit {...data} onChange={setData} />
        </div>
      ) : (
        <div className={styles.modalSelect}>
          {side?.carticles?.length ? (
            <VariationsList items={side.carticles} onClickItem={handleClickItem} />
          ) : (
            <>
              <div className={styles.subtitle}>{t('add_variations_block_description')}</div>
              <div className={styles.bannerWrapper}>
                <VariationsBanner />
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default VariationsSelectModal;
