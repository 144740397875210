import { ReactSVG } from 'react-svg';

import styles from './loading-indicator.module.css';

const LoadingIndicator = ({ color }) => {
  return (
    <ReactSVG src="/icons/loading.svg" className={styles.icon} style={{ color: color }} />
  );
};

export default LoadingIndicator;
