import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import WorkspaceBlock from '../WorkspaceBlock';
import { mock } from '@forma/forma-ui-kit';

import styles from './workspace-filling.module.css';

const WorkspaceFillingItem = ({ id, ext, templateAt, filename, onClickDownload }) => {
  return (
    <div className={styles.item} onClick={() => onClickDownload(id, filename)} title="Скачать">
      <div className={styles.itemHead}>
        <div className={classNames(styles.itemStatus, templateAt && styles.success)}>
          <ReactSVG className={styles.itemStatusIcon} src="/icons/checked.svg" wrapper="span" />
          {templateAt ? 'Настроено' : 'Не настроено'}
        </div>
        <span className={styles.itemDownload}>
          <ReactSVG className={styles.itemDownloadIcon} src="/icons/download.svg" wrapper="span" />
        </span>
      </div>
      <div className={styles.itemImage}>
        {templateAt ? (
          <img className={styles.itemIcon} src="/icons/file_i_b.svg" alt="" />
        ) : (
          <img className={styles.itemIcon} src={mock.filesIcons[ext] ? mock.filesIcons[ext] : mock.filesIcons.other} alt="" />
        )}
      </div>
      <div className={styles.itemName}>{filename}</div>
    </div>
  );
};

const WorkspaceFilling = ({ id, items = [], onClickDownload, onClickDownloadAll }) => {
  return (
    <WorkspaceBlock title="Отправленные шаблоны на заполнение">
      <div className={styles.title}>
        {!!items.length && (
          <span className={styles.link} onClick={() => onClickDownloadAll(id)}>
            <ReactSVG className={styles.linkIcon} src="/icons/download.svg" wrapper="span" />
            Скачать все
          </span>
        )}
      </div>
      <div className={styles.items}>
        {items.map(item => <WorkspaceFillingItem {...item} onClickDownload={onClickDownload} key={item.id} />)}
      </div>
      {!!items.length && (
        <div className={styles.buttons}>
          <Link className={styles.link} to={`/workspaces/${id}/filling-requests`}>Смотреть все</Link>
        </div>
      )}
    </WorkspaceBlock>
  );
};

export default WorkspaceFilling;
