import { createSlice } from '@reduxjs/toolkit';
import { defaultLocale } from '../../i18n';

const initialState = {
  permissions: [],
  language: localStorage?.getItem('i18nextLng')?.split('-')[0] ?? defaultLocale
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUserLang: (state, action) => {
      state.language = action.payload;
    },
    setAdminData: (state, action) => {
      state.admin = action.payload;
    },
    setUserData: (state, action) => {
      state.profile = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  }
});

export default userSlice.reducer;

export const { setAdminData, setUserData, setUserPermissions, setUserLang } = userSlice.actions;

export const selectUserPermissions = (state) => state.user.permissions;
export const selectAdminData = (state) => state.user.admin;
export const selectUserData = (state) => state.user.profile;
export const selectUserLang = (state) => state.user.language;
