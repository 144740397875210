import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/Table';
import { Modal, Button, ButtonsContainer } from '@forma/forma-ui-kit';
import TariffsListItem from './TariffsListItem';
import TariffEdit from './TariffEdit';

import styles from './tariffs-list.module.css';

const TariffsList = ({ items, onAdd, onSave, onRemove }) => {
  const { t } = useTranslation();
  const [ isAddOpen, setAddOpen ] = useState(false);

  return (
    <div className={styles.root}>
      <Table columns={[
        { children: t('id') },
        { children: t('name') },
        { children: t('users_count') },
        { children: '' }
      ]}>
        {items?.map(item => (
          <TariffsListItem
            item={item}
            onSave={onSave}
            onRemove={onRemove}
            key={item.id}
          />
        ))}
      </Table>
      <ButtonsContainer>
        <Button className={styles.addButton} viewStyle="primary" onClick={() => setAddOpen(true)}>
          {t('add_tariff')}
        </Button>
        <Modal
          open={isAddOpen}
          onClose={() => setAddOpen(false)}
          title={t('add_tariff')}
        >
          <TariffEdit
            onCancel={() => setAddOpen(false)}
            onAdd={data => { setAddOpen(false); onAdd(data); }}
            isAdd
          />
        </Modal>
      </ButtonsContainer>
    </div>
  );
};

export default TariffsList;
