import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import ExplorerHead from './ExplorerHead';
import ExplorerList from './ExplorerList';

import styles from './file-explorer.module.css';

const optionsInitialState = {
  itemId: null,
  copyToItemId: null,
  moveToItemId: null
};

const FileExplorer = ({
  foldersTree, items, updated, selected, isLoading, search, navigation, editing, actions, favourites, hideOptions
}) => {
  const { t } = useTranslation();
  const [ moveParams, setMoveParams ] = useState(null);
  const [ optionsState, setOptionsState ] = useState(optionsInitialState);

  const handleToggleMenu = (id) => {
    setOptionsState(prev => ({ ...prev, itemId: id }));
  };

  const handleHoverSubmenuItem = (id) => {
    setOptionsState(prev => ({ ...prev, moveToItemId: id }));
  };

  return (
    <div className={styles.root}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.nav}>
          <ExplorerHead
            selected={selected}
            items={items}
            search={search}
            favourites={favourites}
            editing={editing}
            navigation={navigation}
            actions={actions}
            onToggleMenu={handleToggleMenu}
            onHoverSubmenuItem={handleHoverSubmenuItem}
            hideOptions={hideOptions}
          />
        </div>
        <ExplorerList
          favourites={favourites}
          foldersTree={foldersTree}
          updated={updated}
          selected={selected}
          isLoading={isLoading}
          navigation={navigation}
          editing={editing}
          actions={actions}
          onToggleMenu={handleToggleMenu}
          onHoverSubmenuItem={handleHoverSubmenuItem}
          hideOptions={hideOptions}
          optionsState={optionsState}
          onDrop={(editing?.onCopy || editing?.onMove) && setMoveParams}
        />
      </DndProvider>
      {(editing?.onMove || editing?.onCopy) && (
        <Modal
          title={t('select_action')}
          open={!!moveParams}
          onClose={() => setMoveParams(null)}
          buttons={[
            {
              children: t('copy'),
              onClick: () => (moveParams && editing.onCopy) && editing.onCopy({
                id: moveParams.source,
                targetId: moveParams.target,
                name: moveParams.name,
                type: moveParams.type
              }),
              viewStyle: 'tertiary',
            },
            {
              children: t('move'),
              onClick: () => (moveParams && editing.onMove) && editing.onMove({
                id: moveParams.source,
                targetId: moveParams.target,
                name: moveParams.name,
                type: moveParams.type
              }),
              viewStyle: 'primary',
            }
          ]}
        >
          <div className={styles.modalMove} />
        </Modal>
      )}
    </div>
  );
};

export default FileExplorer;
