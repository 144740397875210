import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import classNames from 'classnames';
import ru from 'date-fns/locale/ru';
import { nanoid } from '@forma/forma-ui-kit';

import styles from './datepicker.module.css';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

registerLocale('ru', ru);

const DateInput = forwardRef(({ id = nanoid(), name, options = [], selected, onChange, onFocus, onBlur, hidden, fullWidth, label, control, labelClass,
  className, containerClass, error, errorMessage, icon, notice, placeholder, value, showTimeSelect, ...props }, ref) => {
  const { t } = useTranslation();

  const handleChange = (date) => {
    const val = format(new Date(date), 'yyyy-MM-dd');
    if (onChange) onChange({target: { name: name, value: val }});
  };
  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
  };
  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
  };

  return (
    <div className={classNames(styles.container, fullWidth && styles.fullWidth, containerClass, hidden && styles.hidden)}>
      {label && <label className={classNames(styles.label, labelClass)} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer}>
        <DatePicker
          {...props}
          id={id}
          name={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={classNames(styles.input, className, (icon) && styles.withIcon, error && 'error')}

          showTimeSelect={showTimeSelect}
          placeholderText={placeholder ? placeholder : t('select_date')}
          selected={value && new Date(value)}
          value={value && new Date(value)}
          autoComplete="off"
          locale="ru"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat={showTimeSelect ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
          ref={ref}
        />
        {icon &&
          <span className={styles.icons}>
            {icon && <span className={styles.icon}>{icon}</span>}
            {/* {label && <label className={styles.label} htmlFor={id}>{label}</label>} */}
          </span>
        }
      </div>
      {(error && errorMessage) &&
        <span className={styles.errorText}>{errorMessage}</span>
      }
      {notice &&
        <span className={styles.notice}>{notice}</span>
      }
    </div>
  );
});

export default DateInput;