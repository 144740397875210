import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@forma/forma-ui-kit';
import DashboardBlock from '../DashboardBlock';
import { pointColors } from 'data/mock';

import styles from './dashboard-scoring.module.css';

const DashboardScoring = ({ day, week, month }) => (
  <DashboardBlock
    title="Cредний скоринг балл"
    buttons={
      <>
        <Tooltip
          control={<img src="/icons/question-circle.svg" alt="" />}
        >
          <div style={{ width: '226px' }}>
            Система ранжирования Формы, которая помогает определить, насколько пользователь готов купить продукт
          </div>
        </Tooltip>
        <Button
          as={Link}
          viewStyle="text"
          icon={<img src="/icons/settings.svg" width={24} height={24} alt="" />}
          to="/scoring"
        />
      </>
    }
  >
    <div className={styles.root}>
      <div className={styles.item}>
        <div className={styles.itemValue} style={{ background: pointColors?.find((item) => item.value >= day).color }}>
          {day.toFixed()}
        </div>
        <div className={styles.itemText}>Сегодня</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue} style={{ background: pointColors?.find((item) => item.value >= week).color }}>
          {week.toFixed()}
        </div>
        <div className={styles.itemText}>Неделя</div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue} style={{ background: pointColors?.find((item) => item.value >= month).color }}>
          {month.toFixed()}
        </div>
        <div className={styles.itemText}>Месяц</div>
      </div>
    </div>
  </DashboardBlock>
);

export default DashboardScoring;
