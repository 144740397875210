import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Modal, Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { templateCategorySchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './template-category-edit.module.css';

const TemplateCategoryEditModal = ({ title, open, onClose, data, categories, onSave }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(templateCategorySchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit = (values) => {
    onSave(formValuesToData(templateCategorySchema, values));
    reset();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.root}
      title={title}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {Object.keys(templateCategorySchema).map(key => {
              const attrs = { ...templateCategorySchema[key] };
              if (key === 'parentid' && categories) attrs.options = categories.reduce((acc, { id, name }) => [ ...acc, { value: id, label: name } ], []);
              return (
                <FillingItem
                  {...attrs}
                  required={attrs.required ?? false}
                  id={key}
                  name={t(`vars.${key}`)}
                  key={key}
                  readOnly={data && key === 'id'}
                  errorMessage={t('errors.regexp')}
                  register={register}
                  control={control}
                  error={errors[key] && errors[key].message}
                />
              )
            }
          )}
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            viewStyle="tertiary"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            viewStyle="primary"
            disabled={!isValid}
          >
            {t('save')}
          </Button>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

export default TemplateCategoryEditModal;
