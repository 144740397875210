import { useState } from 'react';
import update from 'immutability-helper';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Input, sideVariableHelpers, RemoveModal } from '@forma/forma-ui-kit';
import SideBlockGroups from '../SideBlockGroups';
import { VariablesPlaceholder } from '../Variables';
import VariableSelectModal from '../VariableSelectModal';

import styles from './side-block.module.css';

const SideBlock = ({
  id, index, name, folders, opacity, isDragging, variablesGroups, availableVariables, onRemove, onChange, onClickVariable, onClickAddVariations
}) => {
  const { t } = useTranslation();
  const [ isShow, setIsShow ] = useState(true);
  const [ isShowModalSelect, setShowModalSelect ] = useState(false);
  const [ isShowModalRemove, setShowModalRemove ] = useState(false);
  const [ folderToAdd, setFolderToAdd ] = useState(null);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, data: { index } });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity
  };

  const handleToggleVar = (varData, checked, group) => {
    let next = folders;
    if (checked) {
      next = sideVariableHelpers.addVariableToFolder(folders, varData, { id: folderToAdd || group?.id, name: group?.name });
    } else {
      const folderIndex = folders.findIndex(({ tattrs }) => !!(tattrs.find(({ id }) => id === varData.id)));
      const index = folders[folderIndex]?.tattrs.findIndex(({ id }) => id === varData.id);
      if (index === -1) return;
      next = update(folders, {
        [folderIndex]: {
          tattrs: {
            $splice: [[ index, 1 ]]
          }
        }
      });
    }

    onChange({ id, name, tattrFolders: next });
  };

  const handleMoveFolders = (oldIndex, newIndex) => {
    onChange({ id, name, tattrFolders: update(folders, {
      $splice: [
        [ oldIndex, 1 ],
        [ newIndex, 0, folders[oldIndex] ]
      ]
    }) });
  };

  const handleChangeFolders = (folders) => {
    onChange({ id, name, tattrFolders: folders });
  };

  return (
    <div className={classNames(styles.block, isDragging && styles.dragging)} ref={setNodeRef} style={style}>
      <div className={styles.header}>
        <div className={classNames(styles.title, `side-name-${id}`)} id={`side_name_${id}`}>
          <Input
            name={`side_${id}`}
            className={styles.nameInput}
            value={name}
            onChange={e => onChange({ id, name: e.target.value, tattrFolders: folders })}
          />
          <Button
            viewStyle="text"
            size="extraSmall"
            className={classNames(styles.toggler, isShow && styles.open)}
            onClick={() => setIsShow(!isShow)}
            icon={<ReactSVG className={styles.togglerIcon} src="/icons/dropup.svg" wrapper="span" />}
            iconClassName={styles.togglerIcon}
          />
        </div>
        <Button
          className={styles.delete}
          viewStyle="text"
          title={t('delete')}
          onClick={() => setShowModalRemove(true)}
          icon={<ReactSVG className={styles.deleteIcon} src="/icons/trash.svg" wrapper="span" />}
        />
      </div>
      {isShow &&
        <>
          <div className={styles.content}>
            <div className={styles.add}>
              <Button
                className={styles.addButton}
                viewStyle="secondary"
                onClick={() => onClickAddVariations(id)}
                icon={<ReactSVG src="/icons/plus.svg" />}
                size="extraSmall"
              >
                {t('add_varitions_block')}
              </Button>
              <Button
                className={styles.addButton}
                viewStyle="secondary"
                onClick={() => setShowModalSelect(true)}
                icon={<ReactSVG src="/icons/plus.svg" />}
                size="extraSmall"
              >
                {t('add_variable')}
              </Button>
            </div>
          </div>
          <div className={styles.vars}>
            {folders?.length ? (
              <SideBlockGroups
                sideId={id}
                sideName={name}
                folders={folders}
                onMove={handleMoveFolders}
                onChange={handleChangeFolders}
                onClickVariable={onClickVariable}
                onClickAddVariable={(folderId) => {
                  setFolderToAdd(folderId);
                  setShowModalSelect(true);
                }}
              />
            ) : (
              !!availableVariables && (
                <div>
                  <div className={styles.varsTitle}>{t('for_example')}:</div>
                  <VariablesPlaceholder
                    items={Object.values(availableVariables).slice(0, 3)}
                  />
                </div>
              )
            )}
          </div>
        </>
      }
      <div className={styles.handler} {...attributes} {...listeners}>
        <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
      </div>
      <RemoveModal
        open={isShowModalRemove}
        onClose={() => setShowModalRemove(false)}
        title={t('do_you_want_delete_side')}
        onRemove={() => setTimeout(() => onRemove(id), 100)}
      />
      <VariableSelectModal
        open={isShowModalSelect}
        selectedVariables={folders.reduce((acc, current) => [ ...acc, ...Object.values(current.tattrs).map(({ id }) => id) ], [])}
        onClose={() => {
          setShowModalSelect(false);
          setFolderToAdd(null);
        }}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        onToggleVar={handleToggleVar}
      />
    </div>
  );
};

export default SideBlock;
