import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  login: null,
  token: localStorage.getItem('token'),
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.token = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLogin: (state, action) => {
      state.login = action.payload;
    },
  }
});

export default authSlice.reducer;

export const { logoutUser, setToken, setLogin } = authSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectLogin = (state) => state.auth.login;
