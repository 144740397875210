import { currencies } from 'data/mock';
import { format } from 'date-fns';
import { formatPrice } from 'helpers/formatValues';
import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-payment.module.css';

const WorkspacePayment = ({ payment, promocodes }) => (
  <WorkspaceBlock title="Оплата">
    <div className={styles.root}>
      <div className={styles.col}>
        <div className={styles.row}>
          <div className={styles.label}>Тип оплаты:</div>
          <div className={styles.value}>
            <b>{payment?.paymentType ? (payment.paymentType === 'invoice' ? 'Оплата по счету' : 'Банковская карта') : '-'}</b>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Дата последней оплаты:</div>
          <div className={styles.value}>
            <b>{payment?.lastTransactionDate ? format(new Date(payment.lastTransactionDate), 'dd.MM.yyyy') : '-'}</b>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Сумма последней оплаты:</div>
          <div className={styles.value}>
            <b>{payment?.lastTransactionAmount ? formatPrice(payment.lastTransactionAmount, currencies.ru) : '-'}</b>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Общая сумма оплаты:</div>
          <div className={styles.value}>
            <b>{(payment?.transactionsSum || payment?.transactionsSum === 0) ? formatPrice(payment.transactionsSum, currencies.ru) : '-'}</b>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>Применённые промокоды</div>
        <div className={styles.codes}>
          {promocodes?.map(({ id }) => (
            <div className={styles.code} key={id}>{id}</div>
          ))}
        </div>
      </div>
    </div>
  </WorkspaceBlock>
);

export default WorkspacePayment;
