import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import styles from './drop-item.module.css';

const DropItem = ({ id, name, type, onDrop, className, children }) => {
  const [{ isHover, canDrop }, dropRef] = useDrop(() => ({
    accept: 'BOX',
    item: { id, type },
    canDrop: (item) => (type === 'folder' && item.parentId !== id),
    collect: (monitor) => ({
      isHover: !!monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop(),
    }),
    drop: (item) => {
      return (onDrop && item.id !== id && item.parentId !== id) ?
        onDrop({ source: item.id, target: id, name: item.name, type: item.type }) : null;
    }
  }), []);

  return (
    <div className={classNames(className, (isHover && canDrop) && styles.active)} ref={dropRef}>
      {children}
    </div>
  );
};

export default DropItem;
