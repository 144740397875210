import { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DndContext, useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import FileDropzone from 'components/fields/FileDropzone';
import { Modal, Button, RemoveModal } from '@forma/forma-ui-kit';
import LoadingIndicator from 'components/LoadingIndicator';

import styles from './template-previews.module.css';

const TemplatePreviewsItem = ({ id, name, url, templateid, index, onClick, onRemove }) => {
  const { t } = useTranslation();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className={styles.preview} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className={styles.previewInner} onClick={() => onClick(index)}>
        <img src={url} className={styles.previewImage} alt="" />

        <RemoveModal
          control={
            <Button viewStyle="text" className={styles.remove} iconClassName={styles.removeIcon} icon={<ReactSVG src="/icons/trash.svg" />} />
          }
          title={t('delete_preview')}
          onRemove={() => onRemove({ id: templateid, previewid: id })}
          itemName={name}
        />
      </div>
    </div>
  );
};

const TemplatePreviews = ({ items, onAdd, onRemove, onSort, isLoading }) => {
  const swiperRef = useRef();
  const { t } = useTranslation();
  const [ files, setFiles ] = useState(null);
  const [ isOpenPreview, setOpenPreview ] = useState(false);

  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 3,
    },
  }));

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex(({ id }) => id === active.id);
      const newIndex = items.findIndex(({ id }) => id === over.id);
      const next = arrayMove(items, oldIndex, newIndex);
      onSort(next.map(({ id }, index) => ({ id, position: index })));
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('template_previews')}</div>
      <div className={styles.previews}>
        {isLoading && <div className={styles.loader}><LoadingIndicator /></div>}
        <Modal
          title={t('upload_previews')}
          control={
            <Button
              viewStyle="secondary"
              className={styles.add}
              iconClassName={styles.addIcon}
              icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
              title={t('add')}
              fullWidth
            />
          }
          buttons={[
            {
              children: t('upload'),
              disabled: !files?.length,
              onClick: () => {
                onAdd(files);
                setFiles(null);
              }
            }
          ]}
        >
          <input type="hidden" autoFocus />
          <FileDropzone
            className={styles.dropzone}
            accept={{ 'image/jpeg': [], 'image/png': [], 'image/bmp': [], 'image/gif': [], 'image/tiff': [], 'image/webp': [] }}
            maxFiles={10}
            onChange={setFiles}
          />
        </Modal>

        {items ? (
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <SortableContext items={items}>
              {items?.map((item, index) => (
                <TemplatePreviewsItem
                  {...item}
                  index={index}
                  onClick={(index) => { setOpenPreview(true); setTimeout(() => swiperRef.current.swiper.slideTo(index), 200); } }
                  onRemove={onRemove}
                  key={item.id}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          Array.from({ length: 3 }, (_, idx) => (
            <div className={classNames(styles.preview, 'skeleton-loader')} key={idx} />
          ))
        )}
      </div>

      <Modal
        open={isOpenPreview}
        onClose={() => setOpenPreview(false)}
        closeOnDocumentClick={false}
      >
        <Swiper
          modules={[ Navigation, Pagination ]}
          pagination={{ clickable: true }}
          navigation
          spaceBetween={20}
          slidesPerView={1}
          ref={swiperRef}
        >
          {items?.map(({ id, url, name, templateid }) => (
            <SwiperSlide key={id}>
              <div className={styles.slidePreview}>
                <img src={url} className={styles.slidePreviewImage} alt="" />

                <RemoveModal
                  control={
                    <Button viewStyle="text" className={styles.remove} iconClassName={styles.removeIcon} icon={<ReactSVG src="/icons/trash.svg" />} />
                  }
                  title={t('delete_preview')}
                  onRemove={() => onRemove({ id: templateid, previewid: id })}
                  itemName={name}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </div>
  );
};

export default TemplatePreviews;