import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';

import styles from './sections-header.module.css';

const SectionsHeader = ({ onClickAdd, onClickAddGroup }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Button
        viewStyle="secondary"
        shadow={true}
        onClick={onClickAdd}
      >
        {t('add_section')}
      </Button>
      <Button
        viewStyle="secondary"
        shadow={true}
        onClick={onClickAddGroup}
      >
        {t('add_group')}
      </Button>
    </div>
  );
};

export default SectionsHeader;