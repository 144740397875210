export const adminMenu = [
  {
    id: 'dashboard',
    href: '/dashboard',
    lang: 'dashboard',
    icon: '/icons/menu/dashboard.svg',
  },
  {
    id: 'users',
    lang: 'users',
    icon: '/icons/menu/users.svg',
    items: [
      {
        id: 'workspaces',
        href: '/workspaces',
        lang: 'workspaces',
        icon: '/icons/menu/users.svg',
      },
      {
        id: 'demo_requests',
        href: '/demo-requests',
        lang: 'demo_requests',
        icon: '/icons/menu/calendar.svg',
      },
      {
        id: 'tcrequests',
        lang: 'tcrequests',
        icon: '/icons/menu/contragents.svg',
        href: '/requests'
      },
    ]
  },
  {
    id: 'resources',
    lang: 'resources',
    icon: '/icons/menu/templates.svg',
    items: [
      {
        id: 'translations',
        href: '/resources',
        lang: 'translations',
        icon: '/icons/menu/templates.svg',
      },
      {
        id: 'editor_styles',
        href: '/editor-styles',
        lang: 'editor_styles',
        icon: '/icons/menu/templates.svg',
      }
    ]
  },
  {
    id: 'templates',
    lang: 'templates',
    icon: '/icons/menu/forma_templates.svg',
    items: [
      {
        id: 'templates',
        href: '/templates',
        lang: 'templates',
        icon: '/icons/menu/forma_templates.svg',
        routes: ['/templates/:id', '/editor/:id']
      },
      {
        id: 'variables',
        href: '/variables',
        lang: 'variables',
        icon: '/icons/menu/documents.svg',
      },
      {
        id: 'variables_groups',
        href: '/groups-variables',
        lang: 'variables_groups',
        icon: '/icons/menu/templates_pack.svg',
      },
      {
        id: 'variables_sections',
        href: '/sections-variables',
        lang: 'variables_sections',
        icon: '/icons/menu/templates_pack.svg',
      }
    ]
  },
  {
    id: 'tariffs',
    lang: 'tariffs',
    icon: '/icons/menu/card.svg',
    items: [
      {
        id: 'tariffs',
        lang: 'tariffs',
        icon: '/icons/menu/card.svg',
        href: '/tariffs'
      },
      {
        id: 'promocodes',
        lang: 'promocodes',
        icon: '/icons/menu/promocodes.svg',
        href: '/promocodes'
      },
    ]
  },
  {
    id: 'settings',
    lang: 'settings',
    icon: '/icons/menu/settings.svg',
    items: [
      {
        id: 'profile',
        href: '/profile',
        lang: 'my_profile',
        icon: '/icons/menu/user.svg',
      },
      // {
      //   id: 'admins',
      //   href: '/admins',
      //   lang: 'administrators',
      //   icon: '/icons/menu/users.svg',
      // },
    ]
  },
];

export const testMenu = [
  {
    id: 'templates',
    href: '/templates',
    lang: 'templates',
    icon: '/icons/menu/forma_templates.svg',
    routes: ['/templates/:id', '/editor/:id']
  },
];
