import { memo, useState, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from '@forma/forma-ui-kit';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Logo from 'components/Logo';
import { useClickOutside } from '@forma/forma-ui-kit';
import { logoutUser } from 'store/auth/authSlice';
import { selectAdminData, setUserData } from 'store/user/userSlice';
import { baseApi } from 'store/store';
import { selectUserData, selectUserPermissions } from 'store/user/userSlice';
import { setMenuOpen, selectMenuOpen } from 'store/common/commonSlice';
import { adminMenu, testMenu } from 'data/menu';

import Submenu from './Submenu';
import MenuButton from './MenuButton';

import styles from './side-menu.module.css';

export const checkIsActive = (current, href, routes) => {
  if (current === href) return true;
  if (href && !!matchPath(href, current)) return true;
  if (routes && routes.findIndex(route => !!matchPath(route, current)) !== -1) return true;
  return false;
};

// const AddUserButton = memo(({ isMenuOpen, onClick }) => {
//   const { t } = useTranslation();

//   return (
//     <Popup
//       on={!isMenuOpen ? [ 'hover', 'focus' ] : []}
//       className={classNames(styles.tooltip, 'tooltip')}
//       trigger={
//         <Link className={classNames(styles.link)} to="/invite" onClick={onClick}>
//           <ReactSVG src="/icons/menu/user_add.svg" className={styles.icon} wrapper="span" />
//           {isMenuOpen && t('invite_user')}
//         </Link>
//       }
//       position={['right center']}
//       offsetX={14}
//       offsetY={0}
//       arrow={false}
//     >
//       {t('invite_user')}
//     </Popup>
//   );
// });

const LogOutButton = ({ user, isMenuOpen, onClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ isHover, setHover ] = useState(false);
  const [ isFocus, setFocus ] = useState(false);

  const handleClickLogout = () => {
    dispatch(logoutUser());
    dispatch(setUserData(null));
    dispatch(baseApi.util.resetApiState());
    navigate('/login');
    onClick();
  };

  return (
    <Popup
      on={!isMenuOpen ? [ 'hover', 'focus' ] : []}
      className={classNames(styles.tooltip, 'tooltip')}
      trigger={
        <button
          className={classNames(styles.link, styles.logout)}
          onClick={handleClickLogout}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        >
          <ReactSVG src="/icons/menu/sign_out.svg" className={styles.icon} wrapper="span" />
          {isMenuOpen &&
            (isHover || isFocus) ? (
              t('sign_out')
            ) : (
              <span>
                {(user && user.name) && <span className={styles.logoutName}>{user.name}</span>}
                {(user && user.login) && <span className={styles.logoutEmail}>{user.login}</span>}
              </span>
            )
          }
        </button>
      }
      position={['right center']}
      offsetX={14}
      offsetY={0}
      arrow={false}
    >
      {t('sign_out')}
    </Popup>
  );
};

const MenuItem = ({ isMenuOpen, userPermissions, href, lang, icon, items, disabled, currentPathname, routes, onClick }) => {
  const { t } = useTranslation();

  const isActive = checkIsActive(currentPathname, href, routes);
  const isSubmenuActive = items ? items.findIndex(({ href, routes }) => checkIsActive(currentPathname, href, routes)) !== -1 : false;
  const [ isShowChilds, setShowShilds ] = useState(isSubmenuActive);
  const Component = href ? Link : 'button';

  const handleClickItem = () => {
    if (items) setShowShilds(!isShowChilds);
    else onClick();
  };

  return (
    <li className={classNames(styles.item, isShowChilds && styles.open)}>
      <Popup
        on={!isMenuOpen ? [ 'hover', 'focus' ] : []}
        className={classNames(styles.tooltip, 'tooltip')}
        trigger={
          <Component
            className={
              classNames(styles.link, isShowChilds && styles.open, (isActive || isSubmenuActive) && styles.active, (disabled && !items) && styles.disabled)
            }
            onClick={handleClickItem}
            to={href}
          >
            <ReactSVG src={icon} className={styles.icon} wrapper="span" />
            {isMenuOpen && t(lang)}
            {items && <ReactSVG src="/icons/dropdown.svg" className={classNames(styles.arrow, !isMenuOpen && styles.absolute)} wrapper="span" />}
          </Component>
        }
        position={['right center']}
        offsetX={14}
        offsetY={0}
        arrow={false}
      >
        {t(lang)}
      </Popup>

      {items &&
        <Submenu
          isMenuOpen={isMenuOpen}
          items={items}
          isShowChilds={isShowChilds}
          userPermissions={userPermissions}
          currentPathname={currentPathname}
          onClick={onClick}
        />
      }
    </li>
  );
};

const SideMenu = memo(() => {
  const dispatch = useDispatch();
  // let viewport = useRef(null);
  const containerRef = useRef(null);
  const { pathname, hash } = useLocation();

  const { viewport } = useContext(ThemeContext);
  const isMenuOpen = useSelector(selectMenuOpen);
  const userPermissions = useSelector(selectUserPermissions);
  const userData = useSelector(selectUserData);
  const adminData = useSelector(selectAdminData);

  const menu = adminData?.role === 'COMMON_ADMIN' ? adminMenu : testMenu;

  const handleClickMenuButton = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  const handleCloseMenu = () => {
    if (['phone', 'tablet', 'tabletS'].includes(viewport)) dispatch(setMenuOpen(false));
  };

  useClickOutside(() => handleCloseMenu(), containerRef, [viewport]);

  return (
    <div ref={containerRef}>
      <div className={styles.menuButton}>
        <MenuButton isOpen={isMenuOpen} onClick={handleClickMenuButton} />
      </div>

      <button className={classNames(styles.toggle, isMenuOpen && styles.open)} onClick={handleClickMenuButton}>
        <ReactSVG className={styles.toggleIcon} src="/icons/arrow-right-bold.svg" wrapper="span" />
      </button>

      <div className={classNames(styles.root, isMenuOpen && styles.open)}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} showText={true} textClassName={styles.logoText} />
        </div>

        <ul className={styles.list}>
          {menu.map(item => (
            <MenuItem
              {...item}
              userPermissions={userPermissions}
              isMenuOpen={isMenuOpen}
              currentPathname={pathname + hash}
              onClick={handleCloseMenu}
              key={item.id}
            />
          ))}
        </ul>

        <div className={styles.bottom}>
          {/* <AddUserButton isMenuOpen={isMenuOpen} onClick={handleCloseMenu} /> */}
          <LogOutButton user={userData} isMenuOpen={isMenuOpen} onClick={handleCloseMenu} />
        </div>
      </div>
    </div>
  );
});

export default SideMenu;
