import { useState } from 'react';
import classNames from 'classnames';
import UserIcon from '../UserIcon';

import styles from './user-select.module.css';

const UserSelect = ({ items, onSelect }) => {
  const [ selected, setSelected ] = useState(null);

  const handleClickItem = (id, login) => {
    if (selected === id) {
      onSelect(null);
      setSelected(null);
    } else {
      onSelect(id, login);
      setSelected(id);
    }
  };

  return (
    <div className={styles.root}>
      {items?.map(({ id, name, login, color }, index) => (
        <div
          className={classNames(styles.item, selected === id && styles.active)}
          style={{ zIndex: items.length-index }}
          onClick={() => handleClickItem(id, login)}
          key={id}
        >
          <UserIcon
            name={name || login}
            color={color}
            className={styles.icon}
          />
        </div>
      ))}
    </div>
  );
};

export default UserSelect;
