import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-search.module.css';

const WorkspaceSearch = ({ id, items }) => {
  return (
    <WorkspaceBlock title="Запросы в поиске Формы">
      <div className={styles.items}>
        {items?.map(({ createdAt, data }) => (
          <div className={styles.row} key={createdAt}>
            <div className={styles.date}>{format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}</div>
            <div className={styles.content}>{data}</div>
          </div>
        ))}
      </div>
      {!!items?.length && (
        <div className={styles.buttons}>
          <Link className={styles.link} to={`/workspaces/${id}/search-requests`}>Смотреть все</Link>
        </div>
      )}
    </WorkspaceBlock>
  );
};

export default WorkspaceSearch;
