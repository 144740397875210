import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
// import SearchInput from 'components/SearchInput';

import styles from './groups-header.module.css';

const GroupsHeader = ({ onChangeSearch, onClickAddGroup, onClickAddVariable }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {/* <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('name')}
      /> */}
      <Button
        viewStyle="secondary"
        shadow={true}
        onClick={onClickAddGroup}
      >
        {t('add_group')}
      </Button>
      <Button
        viewStyle="secondary"
        shadow={true}
        onClick={onClickAddVariable}
      >
        {t('add_variable')}
      </Button>
    </div>
  );
};

export default GroupsHeader;