import { useState } from 'react';
import { differenceInDays, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow } from 'components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import Pagination from 'components/Pagination';
import { pointColors } from 'data/mock';
import DashboardRegistersHeader from './DashboardRegistersHeader';
import RegistersChart from './RegistersChart';

import styles from './dashboard-registers.module.css';

const DashboardRegisters = ({ isLoading, items, pagination }) => {
  const { t } = useTranslation();
  const [ view, setView ] = useState('list');
  const [ filter, setFilter ] = useState({});
  const type = 'workspaces';

  const filteredItems = items?.[type].filter(({ date }) => (
    (!filter.dtFrom || differenceInDays(new Date(filter.dtFrom), new Date(date)) <= 0)
    && (!filter.dtTo || 0 <= differenceInDays(new Date(filter.dtTo), new Date(date)))
  ));

  const months = filteredItems?.reduce((acc, current) => {
    const key = format(new Date(current.date), 'yyyy-MM-01');
    return { ...acc, [key]: current.count + (acc[key] ?? 0) };
  }, {});

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <DashboardRegistersHeader
          view={view}
          onChangeView={setView}
          onChangeFilter={value => setFilter(prev => ({ ...prev, ...value }))}
          filter={filter}
        />
      </div>
      <div className={styles.tableWrap}>
        {view === 'list' ? (
          <Table className={styles.table} columns={[
            { children: 'Дата' },
            { children: 'Количество регистраций' },
            { children: 'Средний балл' },
            { children: 'Рост', className: styles.lastDayDiffHead }
          ]}>
            {isLoading ? (
              <tr>
                <td colSpan={8}>
                  <LoadingIndicator />
                </td>
              </tr>
            ) : (
              filteredItems?.map(({ lastDayDiff, date, averageScore, count }) => (
                <TableRow key={date}>
                  <TableCell className={styles.date} title="Дата">{format(new Date(date), 'dd.MM.yyyy')}</TableCell>
                  <TableCell className={styles.count} title="Количество регистраций">{t('count_registers', { count })}</TableCell>
                  <TableCell className={styles.averageScore} title="Средний балл">
                    <div className={styles.score} style={{ background: pointColors?.find((item) => item.value >= averageScore).color }}>{averageScore.toFixed()}</div>
                  </TableCell>
                  <TableCell className={styles.lastDayDiff} title="Рост">
                    <div>
                      {lastDayDiff === 0 && <>Так же как вчера <img src="/icons/chevron-double-middle.svg" width={24} height={24} alt="" /></>}
                      {lastDayDiff > 0 && <>+{lastDayDiff} по сравнению со вчера <img src="/icons/chevron-double-up.svg" width={24} height={24} alt="" /></>}
                      {lastDayDiff < 0 && <>{lastDayDiff} по сравнению со вчера <img src="/icons/chevron-double-down.svg" width={24} height={24} alt="" /></>}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </Table>
        ) : (
          <RegistersChart
            data={months && Object.keys(months).reverse().map((date, index, arr) => (
              {
                name: `${format(new Date(date + 'T00:01'), 'LLLL yyyy', { locale: ru })}`,
                value: months[date] ?? 0,
                diff: index > 0 ? months[date] - months[arr[index-1]] : months[date]
              }
            ))}
            total={items?.[type].reduce((acc, current) => (acc+(type === 'workspaces' ? current.count : current.count)), 0)}
          />
        )}
      </div>
      <Pagination {...pagination} />
    </div>
  );
};

export default DashboardRegisters;
