import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import VariationEdit from '../VariationEdit';

import styles from './variations-edit-modal.module.css';

const VariationsEditModal = ({
  open, onClose, sideId, data: variation, onSave
}) => {
  const { t } = useTranslation();
  const [ data, setData ] = useState(variation);

  useEffect(() => {
    setData(variation);
  }, [variation]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      size="large"
      open={open}
      title={t('edit_variations_block')}
      onClose={handleClose}
      buttonsClassName={styles.modalButtons}
      buttons={[
        { children: t('cancel'), viewStyle: 'tertiary' },
        { children: t('save'), viewStyle: 'primary', disabled: !data?.variants.length, onClick: () => (sideId && data) && onSave(sideId, data) }
      ]}
    >
      <div className={styles.modalSelect}>
        {data && <VariationEdit {...data} onChange={setData} />}
      </div>
    </Modal>
  );
};

export default VariationsEditModal;
