import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const defaultLocale = 'ru';
export const locales = ['ru', 'en'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLocale,
    defaultLocale: defaultLocale,
    // locales: locales,
    supportedLngs: locales,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['main', 'uikit'],
    defaultNS: 'main',
    returnNull: false,
    react: {
      transWrapTextNodes: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span']
    }
  });

export default i18n;
