import styles from './workspace-block.module.css';

const WorkspaceBlock = ({ title, children }) => (
  <div className={styles.root}>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.content}>{children}</div>
  </div>
);

export default WorkspaceBlock;
