import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Modal, Input, RemoveModal } from '@forma/forma-ui-kit';
import ContextMenu from 'components/ContextMenu';
import FileDropzone from 'components/fields/FileDropzone';

import { selectAdminData } from 'store/user/userSlice';

import styles from './explorer-context-menu.module.css';

const ExplorerContextMenu = ({
  open, item, items, parent, onToggleMenu, onHoverSubmenuItem, editing, actions, position
}) => {
  const { t } = useTranslation();
  const adminData = useSelector(selectAdminData);

  const [ isShowDelete, setShowDelete ] = useState(false);
  const [ isShowRename, setShowRename ] = useState(false);
  const [ isShowCreateFolder, setShowCreateFolder ] = useState(false);
  const [ isShowCreateTemplate, setShowCreateTemplate ] = useState(false);
  const [ templateName, setTemplateName ] = useState('');
  const [ templateFile, setTemplateFile ] = useState(null);
  const [ folderName, setFolderName ] = useState('');
  const [ newName, setName ] = useState(item.name);
  const isFolder = item.type === 'folder';

  const handleClickRemove = (id) => {
    if (editing?.onRemove) editing.onRemove({ id, type: isFolder ? 'folder' : 'template' });
    setShowDelete(false);
    document.body.style.overflow = 'auto';
  };

  const handleClickRename = (id) => {
    if (editing?.onRename)
      editing.onRename({
        id,
        type: isFolder ? 'folder' : 'template',
        name: newName,
        parentid: parent?.id
      });
    setShowRename(false);
  };

  // const handleChangeGroups = (id, groups) => {
  //   if (editing?.onChangeAccess)
  //     editing.onChangeAccess({
  //       id,
  //       type: isFolder ? 'folder' : 'template',
  //       name: item.name,
  //       parentid: parent?.id,
  //       groups: groups
  //     });
  //   setShowRename(false);
  // };

  // const handleClickGroup = (groupId, checked) => {
  //   if (checked) handleChangeGroups(item.id, [ ...item.groups, groupId ]);
  //   else handleChangeGroups(item.id, item.groups.filter((group) => groupId !== group));
  // };

  const options = [];

  if (isFolder) {
    if (adminData?.role === 'COMMON_ADMIN') {
      options.push({
        onClick: () => setShowCreateFolder(true),
        text: t('create_folder')
      });
    }
    options.push({
      onClick: () => setShowCreateTemplate(true),
      text: t('create_template')
    });
    options.push({
      link: `/folders/${item.id}`,
      text: t('edit_description')
    });
  } else {
    options.push({
      link: `/editor/${item.id}`,
      text: t('edit_template')
    });
    options.push({
      link: `/templates/${item.id}`,
      text: t('edit_description')
    });
    options.push({
      link: `/fill-template/${item.id}`,
      text: t('fill_template')
    });
  }

  // if (actions?.onFavouritesToggle) {
  //   options.push({
  //     onClick: () => {
  //       if (actions.onFavouritesToggle) actions.onFavouritesToggle({ id: item.id, type: isFolder ? 'folder' : 'template', checked: !item.favorite });
  //     },
  //     text: item.favorite ? t('remove_from_favourites') : t('to_favourites')
  //   });
  // }

  options.push({
    onClick: () => setShowRename(true),
    text: t('rename')
  });

  if (!isFolder && items?.length && editing?.onMove) {
    options.push({
      text: t('move'),
      items: items.map(({ id: targetId, name, ...item }) => (
        {
          text: name,
          onClick: () => {
            if (editing.onMove) editing.onMove({ id: item.id, targetId, name: name, ...item });
            onHoverSubmenuItem(null);
          },
          onMouseEnter: () => onHoverSubmenuItem(targetId),
          onMouseLeave: () => onHoverSubmenuItem(null),
          disabled: targetId === item.id
        }
      ))
    });
  }

  if (!isFolder && items?.length && editing?.onCopy) {
    options.push({
      text: t('copy'),
      items: items.map(({ id: targetId, name }) => (
        {
          text: name,
          onClick: () => {
            if (editing.onCopy) editing.onCopy({ id: item.id, targetId, type: isFolder ? 'folder' : 'template' });
            onHoverSubmenuItem(null);
          },
          onMouseEnter: () => onHoverSubmenuItem(targetId),
          onMouseLeave: () => onHoverSubmenuItem(null),
          disabled: targetId === item.id
        }
      ))
    });
  }

  if (parent?.id && (editing?.onMove || editing?.onCopy)) {
    if (editing.onMove) {
      options.push({
        text: t('move_to_level_up'),
        onClick: () => {
          if (editing.onMove) editing.onMove({ id: item.id, targetId: parent.parentId, name: item.name, type: isFolder ? 'folder' : 'template' });
          onHoverSubmenuItem(null);
        },
        onMouseEnter: () => onHoverSubmenuItem('back'),
        onMouseLeave: () => onHoverSubmenuItem(null),
      });
    }
    if (editing.onCopy) {
      options.push({
        text: t('copy_to_level_up'),
        onClick: () => {
          if (editing.onCopy) editing.onCopy({ id: item.id, targetId: parent.parentId, name: item.name, type: isFolder ? 'folder' : 'template' });
          onHoverSubmenuItem(null);
        },
        onMouseEnter: () => onHoverSubmenuItem('back'),
        onMouseLeave: () => onHoverSubmenuItem(null),
      });
      if (!isFolder) {
        options.push({
          text: t('copy_here'),
          onClick: () => {
            if (editing.onCopy) editing.onCopy({ id: item.id, targetId: item.folder.id, name: item.name, type: isFolder ? 'folder' : 'template' });
            onHoverSubmenuItem(null);
          },
          onMouseEnter: () => onHoverSubmenuItem('back'),
          onMouseLeave: () => onHoverSubmenuItem(null),
        });
      }
    }
  }

  if (!isFolder || adminData?.role === 'COMMON_ADMIN') {
    options.push({
      onClick: () => setShowDelete(true),
      text: isFolder ? t('remove_folder') : t('remove_file')
    });
  }

  // if (actions?.onCopyLink) {
  //   options.push({
  //     onClick: () => {
  //       if (actions.onCopyLink) {
  //         actions.onCopyLink({ translatedName: item.translatedName, parentSlug: parent?.translatedName, type: isFolder ? 'folder' : 'template' });
  //       }
  //     },
  //     text: isFolder ? t('copy_link_to_folder') : t('copy_link_to_file')
  //   });
  // }

  return (
    <>
      <ContextMenu
        open={open}
        items={options}
        position={position}
        control={open => (
          <button className={classNames(styles.button, open && styles.active)}>
            <ReactSVG src="/icons/more.svg" />
          </button>
        )}
        offsetY={12}
        offsetX={0}
        onOpen={() => onToggleMenu(item.id)}
        onClose={() => onToggleMenu(null)}
      />

      <Modal
        title={t('create_folder')}
        open={isShowCreateFolder}
        onClose={() => setShowCreateFolder(false)}
        buttons={[
          {
            onClick: () => { editing.onCreate({ name: folderName, type: 'folder', parentid: item.id }); setFolderName(''); },
            viewStyle: 'primary',
            disabled: folderName.length < 3,
            children: t('save'),
          },
          {
            viewStyle: 'tertiary',
            children: t('cancel')
          }
        ]}
      >
        <div className={styles.modalContent}>
          <Input
            name="template_name"
            className={styles.modalInput}
            onChange={e => setFolderName(e.target.value)}
            value={folderName}
            placeholder={t('enter_folder_name')}
            maxLength={255}
          />
        </div>
      </Modal>

      <Modal
        title={t('create_template')}
        open={isShowCreateTemplate}
        onClose={() => {
          setShowCreateTemplate(false);
          setTemplateName('');
          setTemplateFile(null);
        }}
        buttons={[
          {
            onClick: () => { editing.onCreate({ name: templateName, type: 'template', parentid: item.id, file: templateFile }); },
            viewStyle: 'primary',
            disabled: templateName.length < 3,
            children: t('create'),
          },
          {
            viewStyle: 'tertiary',
            children: t('cancel')
          }
        ]}
      >
        <div className={styles.modalContent}>
          <Input
            name="template_name"
            className={styles.modalInput}
            onChange={e => setTemplateName(e.target.value)}
            value={templateName}
            placeholder={t('enter_template_name')}
            maxLength={255}
          />
          <br />
          <FileDropzone
            onChange={files => setTemplateFile(files[0])}
            className={styles.dropzone}
            accept={{
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/msword': ['.doc'],
              'application/rtf': ['.rtf'],
              'application/vnd.apple.pages': ['.pages'],
            }}
            maxFiles={1}
          />
        </div>
      </Modal>

      <Modal
        open={isShowRename}
        onClose={() => setShowRename(false)}
        title={isFolder ? t('rename_folder') : t('rename_template')}
        // on={[ 'hover', 'focus' ]}
        buttons={[
          {
            children: t('save'),
            onClick: () => handleClickRename(item.id)
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            name="name"
            placeholder={isFolder ? t('enter_folder_name') : t('enter_template_name')}
            onChange={e => setName(e.target.value)}
            showClearButton
            value={newName}
            maxLength={255}
          />
        </div>
      </Modal>

      <RemoveModal
        open={isShowDelete}
        onClose={() => setShowDelete(false)}
        title={isFolder ? t('deleting_folder') : t('deleting_template')}
        onRemove={() => handleClickRemove(item.id)}
        itemName={item.name}
      />
    </>
  );
};

export default ExplorerContextMenu;
