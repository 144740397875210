import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, mock } from '@forma/forma-ui-kit';

import styles from './file-dropzone.module.css';

const FileDropzone = ({
  title, placeholder, accept, showAccept, maxFiles = 1, showName = true, onChange, className
}) => {
  const { t } = useTranslation();
  const [ isLoad, setIsLoad ] = useState(false);
  const [ files, setFiles ] = useState([]);
  const acceptFormat = accept && Object.values(accept).flat().join(',');
  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles,
    noClick: true,
    accept: accept,
    onDrop: acceptedFiles => {
      if (maxFiles === 1) setFiles(acceptedFiles);
      else {
        setFiles(prev => ([ ...prev, ...acceptedFiles.filter(file => prev.findIndex(oldFile => oldFile.name === file.name) === -1) ]));
      }
    }
  });

  useEffect(() => {
    if (isLoad) onChange(files);
    else setIsLoad(true);
    // eslint-disable-next-line
  }, [files]);

  const handleRemoveFile = (index) => {
    setFiles(prev => {
      const next = [...prev];
      next.splice(index, 1);
      return next;
    });
  };

  return (
    <div className={classNames(styles.root, files?.length && styles.filled, className)} {...getRootProps()}>
      <input {...getInputProps()} />
      {files?.length ? (
        <>
          <div className={classNames(styles.files, maxFiles > 1 && styles.multiple, 'styled-scrollbar')}>
            {files.map(({ name, path }, index) => {
              const nameParts = name.split('.');
              const ext = nameParts[nameParts.length - 1];
              return (
                <div className={styles.file} key={path}>
                  <img src={mock.filesIcons[ext] ? mock.filesIcons[ext] : mock.filesIcons.other} className={styles.fileIcon} alt={name} />
                  {showName && <div className={styles.fileName}>{name}</div>}
                  <Button
                    className={styles.remove}
                    viewStyle="text"
                    iconClassName={styles.removeIcon}
                    icon={<ReactSVG src="/icons/close.svg" wrapper="span" />}
                    onClick={() => handleRemoveFile(index)}
                    title={t('delete')}
                  />
                </div>
              );
            })}
            {maxFiles > 1 && (
              <div className={styles.moreButtons}>
                <Button
                  className={styles.button}
                  viewStyle="secondary"
                  size="small"
                  onClick={open}
                >
                  {t('add_more_files')}
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.content}>
            {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
            <div className={styles.placeholder}>
              {placeholder ? placeholder : t('drag_file_or_select', { count: maxFiles })}
              {(acceptFormat && showAccept) && <p>{t('accept_format_message') + acceptFormat}</p> }
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              viewStyle="secondary"
              size="small"
              onClick={open}
            >
              {t('select_file')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FileDropzone;
