import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, ButtonsContainer, FillingItem } from '@forma/forma-ui-kit';

import { tariffsSchema } from 'data/schemas';
import { formDataToValues, formValuesToData } from 'helpers/formValuesToData';

import styles from './tariff-edit.module.css';

const TariffEdit = ({ data, onCancel, onSave, onAdd, isAdd }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: data && formDataToValues(tariffsSchema, data)
    // resolver: user && yupResolver(getValidationSchema(fields))
  });

  const onSubmit = (values) => {
    if (isAdd) onAdd(formValuesToData(tariffsSchema, values));
    else onSave(formValuesToData(tariffsSchema, values));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        {Object.keys(tariffsSchema).map(key => {
            const attrs = { ...tariffsSchema[key] };
            if (key === 'advantages' && data?.advantages) {
              attrs.options = data.advantages.reduce((acc, current) => [ ...acc, { label: current, value: current } ], []);
            }
            return (
              <FillingItem
                {...attrs}
                required={attrs.required ?? false}
                id={key}
                name={t(`vars.${key}`)}
                key={key}
                readOnly={data && key === 'id'}
                errorMessage={t('errors.regexp')}
                register={register}
                control={control}
                error={errors[key] && errors[key].message}
              />
            )
          }
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          type="button"
          viewStyle="tertiary"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          viewStyle="primary"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default TariffEdit;
